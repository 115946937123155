import { Card, Table, Typography } from 'antd'
import React, { useMemo } from 'react'

import { FormsPrice } from 'apiClient/api/interface'
import { FormsTaxRate } from 'apiClient/services/billing/interface'
import CurrencyValue from 'components/currency/Value'

interface CostItem {
  name: string
  cost_price: string
}

export default function BillCosts({
  category_unit_costs,
  tax_rate,
  withTaxRate
}: {
  category_unit_costs: { [key: string]: FormsPrice }
  tax_rate?: FormsTaxRate
  withTaxRate?: boolean
}) {
  const columns = useMemo(
    () => [
      {
        title: 'Category Name',
        dataIndex: 'name'
      },
      {
        title: 'Cost Price',
        dataIndex: 'cost_price',
        render: (text: any, record: CostItem) => {
          return <CurrencyValue isCent={false} value={record.cost_price} />
        }
      }
    ],
    []
  )

  const dataSource = category_unit_costs
    ? Object.keys(category_unit_costs).map((key) => ({
        name: key,
        cost_price: category_unit_costs[key].cost_price
      }))
    : []

  return (
    <Card>
      {withTaxRate && (
        <p>
          <b>Tax rate: </b>
          <Typography.Text type={tax_rate ? 'success' : 'warning'}>
            {tax_rate?.rate !== undefined && tax_rate?.rate !== null
              ? tax_rate.display_name + '(' + tax_rate.rate + '%)'
              : 'Not Set'}
          </Typography.Text>
        </p>
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        rowKey="name"
        size="small"
      />
    </Card>
  )
}
