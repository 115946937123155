/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ListTenantsRegionItem = typeof ListTenantsRegionItem[keyof typeof ListTenantsRegionItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListTenantsRegionItem = {
  APAC: 'APAC',
  EMEA: 'EMEA',
  JAPAN: 'JAPAN',
  NA: 'NA',
  UNKNOWN: 'UNKNOWN',
} as const;
