import { Button, Descriptions } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { Base64 } from 'js-base64'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  cancelhMaintenanceExecution,
  closeMaintenance,
  finishMaintenanceExecution,
  getAuditLogs,
  getAuditMaintenanceLogs,
  getClusterMaintenance,
  startMaintenanceExecution
} from 'apiClient/api'
import {
  FormsGetAuditLogResp,
  FormsGetAuditLogRespAuditLogsItem,
  FormsMaintenance,
  FormsMaintenanceExecution
} from 'apiClient/api/interface'

export const MaintenanceDetail = () => {
  const { id } = useParams<{
    id: string
  }>()
  const [maintenanceDetail, setMaintenanceDetail] = useState<FormsMaintenance>()
  const [auditLog, setAuditLog] =
    useState<FormsGetAuditLogRespAuditLogsItem[]>()
  const [reload, setReload] = useState(false)

  async function fetch() {
    try {
      const data = (
        await getClusterMaintenance(parseInt(id), { with_executions: true })
      ).data
      const audit = (
        await getAuditMaintenanceLogs({ maintenance_id: parseInt(id) })
      ).data
      setMaintenanceDetail(data.maintenance)
      setAuditLog(audit.audit_logs)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (id) {
      fetch()
    }
  }, [reload])

  async function start(maintenance_id: number, execution_id: number) {
    await startMaintenanceExecution(maintenance_id, execution_id)
  }

  const handleStart = (maintenance_id: number, execution_id: number) => {
    return async () => {
      if (window.confirm('sure to start this execution?')) {
        try {
          await start(maintenance_id, execution_id)
          window.alert('start the execution successfully')
          setReload((pre) => !pre)
        } catch (e) {
          window.alert('fail to start the execution')
        }
      }
    }
  }

  async function finish(maintenance_id: number, execution_id: number) {
    await finishMaintenanceExecution(maintenance_id, execution_id)
  }

  const handleFinish = (maintenance_id: number, execution_id: number) => {
    return async () => {
      if (window.confirm('sure to finish this execution?')) {
        try {
          await finish(maintenance_id, execution_id)
          window.alert('finish the execution successfully')
          setReload((origin) => {
            return !origin
          })
        } catch (e) {
          window.alert('fail to finish the execution')
        }
      }
    }
  }

  async function cancel(maintenance_id: number, execution_id: number) {
    await cancelhMaintenanceExecution(maintenance_id, execution_id)
  }

  const handleCancel = (maintenance_id: number, execution_id: number) => {
    return async () => {
      if (window.confirm('sure to cancel this execution?')) {
        try {
          await cancel(maintenance_id, execution_id)
          window.alert('cancel the execution successfully')
          setReload((origin) => {
            return !origin
          })
        } catch (e) {
          window.alert('fail to cancel the execution')
        }
      }
    }
  }

  async function close(maintenance_id: number) {
    await closeMaintenance(maintenance_id)
  }

  const handleClose = (maintenance_id: number) => {
    return async () => {
      if (window.confirm('sure to close this maintenance?')) {
        try {
          await close(maintenance_id)
          window.alert('close the maintenance successfully')
          setReload((origin) => {
            return !origin
          })
        } catch (e) {
          window.alert('fail to close the maintenance')
        }
      }
    }
  }

  const columnsAudit: ColumnsType<FormsGetAuditLogRespAuditLogsItem> = [
    {
      title: 'ID',
      render: (_: any, record) => {
        return record.id
      }
    },
    {
      title: 'Operator',
      render: (_: any, record) => {
        return record.operator
      }
    },
    {
      title: 'Operation',
      render: (_: any, record) => {
        return record.operation
      }
    },
    {
      title: 'Operate At',
      render: (_: any, record) => {
        return toLocal(record.operate_at)
      }
    },
    {
      title: 'Detail',
      render: (_: any, record) => {
        if (record.details) {
          return Base64.decode(record.details)
        }
      }
    }
  ]

  const columns: ColumnsType<FormsMaintenanceExecution> = [
    {
      title: 'Cluster ID',
      render: (_: any, record) => {
        return record.cluster_id
      }
    },
    {
      title: 'Status',
      render: (_: any, record) => {
        return record.status
      }
    },
    {
      title: 'Reason',
      render: (_: any, record) => {
        return record.cancel_reason
      }
    },
    {
      title: 'Action',
      render: (_: any, record) => {
        if (record.id) {
          return (
            <div>
              <Button
                type="primary"
                disabled={
                  maintenanceDetail?.task?.status === 'Running' &&
                  record.status === 'Pending'
                    ? false
                    : true
                }
                onClick={handleStart(parseInt(id), record.id)}
              >
                Start
              </Button>
              <Button
                type="primary"
                disabled={!(record.status === 'Running')}
                onClick={handleFinish(parseInt(id), record.id)}
              >
                Finish
              </Button>
            </div>
          )
        }
      }
    },
    {
      title: 'Cancel',
      render: (_: any, record) => {
        if (record.id) {
          return (
            <div>
              <Button
                type="primary"
                disabled={
                  !(record.status === 'Running' || record.status === 'Pending')
                }
                onClick={handleCancel(parseInt(id), record.id)}
              >
                Cancel
              </Button>
            </div>
          )
        }
      }
    }
  ]

  return (
    <div>
      <Descriptions title="Maintenance Detail">
        <Descriptions.Item label="Project ID">
          {maintenanceDetail?.task?.project_id}
        </Descriptions.Item>
        <Descriptions.Item label="Schedule Time">
          {toLocal(maintenanceDetail?.task?.schedule_time)}
        </Descriptions.Item>
        <Descriptions.Item label="Deadline">
          {toLocal(maintenanceDetail?.task?.deadline)}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          {maintenanceDetail?.task?.status}
        </Descriptions.Item>
      </Descriptions>

      <b>Executions</b>

      <Table<FormsMaintenance>
        columns={columns}
        rowKey="id"
        dataSource={maintenanceDetail?.task?.executions}
        size="small"
      ></Table>
      <br />
      <Button
        type="primary"
        onClick={handleClose(parseInt(id))}
        disabled={maintenanceDetail?.task?.status == 'Closed'}
      >
        Close the Maintenance
      </Button>
      <br />
      <br />
      <b>Audit Logs</b>

      <Table<FormsGetAuditLogRespAuditLogsItem>
        columns={columnsAudit}
        rowKey="id"
        dataSource={auditLog}
        size="small"
      ></Table>
    </div>
  )
}

export const toLocal = (time: number | string | undefined) => {
  if (time) {
    var form
    if (typeof time === 'string') {
      form = new Date(time)
    } else {
      form = new Date(time * 1000)
    }
    const options = {
      hour12: false
    }
    const local = form.toLocaleString(undefined, options)
    return local
  }
  return undefined
}

export const toLocalDate = (time: number | string | undefined) => {
  if (time) {
    var form
    if (typeof time === 'string') {
      form = new Date(time)
    } else {
      form = new Date(time * 1000)
    }
    const local = form.toLocaleDateString()
    return local
  }
  return undefined
}

export default MaintenanceDetail
