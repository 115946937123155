/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type EntityApprovalStatus = typeof EntityApprovalStatus[keyof typeof EntityApprovalStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityApprovalStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
} as const;
