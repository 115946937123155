import Table, { ColumnsType } from 'antd/lib/table'
import { AxiosResponse } from 'axios'
import { Base64 } from 'js-base64'
import { useEffect, useState } from 'react'

import { auditControllerListAuditLog } from 'apiClient/services/devops'
import {
  AuditAuditLog,
  AuditControllerListAuditLogType
} from 'apiClient/services/devops/interface'
import { toLocal } from 'pages/Devops/Maintenance/Detail'

interface AuditLogResp {
  audit_logs?: AuditAuditLog[]
  total?: number
}

export type GetAuditController = (
  params: unknown,
  options?: unknown
) => Promise<AxiosResponse<AuditLogResp>>

export default function AuditTable({
  type,
  id
}: {
  type: AuditControllerListAuditLogType
  id: unknown
}) {
  const [audits, setAudits] = useState<AuditAuditLog[]>()

  useEffect(() => {
    async function fetch() {
      try {
        const data = (
          await auditControllerListAuditLog({
            type: type,
            instance_id: String(id)
          })
        ).data.audit_logs
        setAudits(data)
      } catch (e) {
        console.error(e)
      }
    }
    fetch()
  }, [])

  const columnsAudit: ColumnsType<AuditAuditLog> = [
    {
      title: 'ID',
      render: (_: any, record) => {
        return record.id
      }
    },
    {
      title: 'Operator',
      render: (_: any, record) => {
        return record.operator
      }
    },
    {
      title: 'Operation',
      render: (_: any, record) => {
        return record.operation
      }
    },
    {
      title: 'Operate At',
      render: (_: any, record) => {
        return toLocal(record.operate_at)
      }
    },
    {
      title: 'Detail',
      render: (_: any, record) => {
        if (record.details) {
          const str = String(record.details)
          return Base64.decode(str)
        }
      }
    }
  ]

  return (
    <div>
      <Table<AuditAuditLog>
        columns={columnsAudit}
        rowKey="id"
        dataSource={audits}
        size="small"
        pagination={false}
      ></Table>
    </div>
  )
}
