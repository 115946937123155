import React from 'react'
import { Spin } from 'antd'

export const PageLoader = ({ tip = 'Loading...' }: { tip?: string }) => {
  return (
    <div className="app-loader flex-box hv-center">
      <Spin tip={tip} />
    </div>
  )
}
