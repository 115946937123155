import React from 'react'
import { Layout } from 'antd'

import Breadcrumb from './Breadcrumb'
import Header from './Header'
import Sidebar from './Sidebar'

import './index.less'

const { Content } = Layout

const AppLayout: React.FC = ({ children }) => {
  return (
    <Layout className="app-layout">
      <Sidebar />
      <Layout>
        <Header />
        <Content className="app-content">
          <Breadcrumb />
          <div className="container">{children}</div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default AppLayout
