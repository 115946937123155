/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ListTenantsStatusItem = typeof ListTenantsStatusItem[keyof typeof ListTenantsStatusItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListTenantsStatusItem = {
  pending: 'pending',
  active: 'active',
  inactive: 'inactive',
  deleted: 'deleted',
  cleared: 'cleared',
} as const;
