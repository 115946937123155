import {
  Button,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  TreeSelect
} from 'antd'
import config from 'config'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useState, cloneElement } from 'react'

import {
  useGetUnitFilterQuickSelection,
  useGetUnitTypeTree
} from 'apiClient/api'
import { FormsUnitFilterSlim } from 'apiClient/api/interface'
import {
  listTenants,
  createCustomer,
  useGetTenantByID
} from 'apiClient/services/account'
import { FormsPartnerCustomer } from 'apiClient/services/account/interface'
import {
  createCPPOBillingConfig,
  createTenantDiscount,
  getBillingConfigsByManagedTenant
} from 'apiClient/services/billing'
import DatePicker from 'components/DatePicker'
import DebounceSelect from 'components/DebounceSelect/DebounceSelect'
import ModalForm from 'components/ModalForm'
import { getTreeData } from 'pages/Tenants/TenantDetail/BusinessInfo/Discount'
import { precision, toFixed } from 'utils/number'
import { format } from 'utils/time'

const fmt = 'YYYY-MM'

const timeFormat = (value: Dayjs) => {
  return format(value.format(fmt), undefined, 'YYYY-MM', 0)
}

const serverFmt = 'YYYY-MM-DDTHH:mm:ss[Z]'

const serverTimeFormat = (value: Dayjs) => {
  return format(value.format(serverFmt), undefined, 'YYYY-MM-DD HH:mm:ss Z', 0)
}

export const CreateCPPOCustomer: React.FC<{
  partner_org_id?: string
  customer_org_id?: string
  trigger?: React.ReactElement
  title?: string
  onCreate?: () => void
}> = ({
  partner_org_id,
  customer_org_id,
  trigger,
  title = 'Add a CPPO Customer',
  onCreate
}) => {
  const [visible, setVisible] = useState(false)

  const onSubmit = async (data: {
    partner_org_id: string | { value: string; label: string }
    customer_org_id: string | { value: string; label: string }
    discount: string
    unit_filters: string[]
    payment_code: string
    effected_at: string
    expired_at: string
  }) => {
    const effected_at =
      dayjs(data.effected_at).startOf('month').format('YYYY-MM-DD') +
      'T00:00:00Z'
    const expired_at =
      dayjs(data.expired_at).endOf('month').format('YYYY-MM-DD') + 'T23:59:59Z'
    const customer: FormsPartnerCustomer = {
      partner_type: 'CPPO',
      effected_at,
      partner_org_id,
      customer_org_id
    }

    if (
      typeof data.partner_org_id !== 'string' &&
      data.partner_org_id &&
      'value' in data.partner_org_id
    ) {
      customer.partner_org_id = data.partner_org_id.value
    }
    if (
      typeof data.customer_org_id !== 'string' &&
      data.customer_org_id &&
      'value' in data.customer_org_id
    ) {
      customer.customer_org_id = data.customer_org_id.value
    }

    // create customer and bind billing config
    await createCustomer(customer)
    await createCPPOBillingConfig(customer.customer_org_id!, {
      tenant_id: customer.customer_org_id!,
      reseller_id: customer.partner_org_id!,
      payment_code: data.payment_code,
      effective_date: effected_at
    })

    const { data: cppoConfig } = await getBillingConfigsByManagedTenant(
      customer.customer_org_id!
    )

    // create discount
    await createTenantDiscount(
      customer.partner_org_id!,
      cppoConfig?.billing_config_id!,
      {
        usage: 'cppo',
        discount: toFixed(
          precision(100 - Number.parseFloat(data.discount || '0')) / 100,
          4,
          true
        ),
        unit_filters: data.unit_filters
          ? (data.unit_filters.map((it) => {
              return {
                filter_id: Number(it)
              }
            }) as FormsUnitFilterSlim[])
          : undefined,
        started_at: effected_at,
        expired_at
      }
    )

    onCreate?.()
    onClose()
  }

  const onClose = () => {
    setVisible(false)
  }

  const { data: partner } = useGetTenantByID(partner_org_id || '', {
    query: {
      enabled: !!partner_org_id && visible,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  })

  const { data: org } = useGetTenantByID(customer_org_id || '', {
    query: {
      enabled: !!customer_org_id && visible,
      refetchOnWindowFocus: false,
      refetchInterval: false
    }
  })

  const Trigger = trigger ? (
    cloneElement(trigger, {
      onClick: () => {
        setVisible(true)
      }
    })
  ) : (
    <Button type="primary" onClick={() => setVisible(true)}>
      Add a CPPO Customer
    </Button>
  )

  const { data } = useGetUnitTypeTree({
    query: {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false
    }
  })

  const { data: defaultDiscount } = useGetUnitFilterQuickSelection(
    'default-discount',
    {
      query: {
        refetchInterval: false,
        refetchOnWindowFocus: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false
      }
    }
  )

  const [startDate, setStartDate] = useState<Dayjs>()
  const onDateChange = (c: any[]) => {
    const change = c[0]
    const name = change.name[0]
    const value = change.value
    if (!change) {
      return
    }

    if (name === 'effected_at') {
      setStartDate(value)
    }
  }

  const renderFields = useCallback(
    (form: FormInstance<any>) => {
      return (
        <>
          <Form.Item
            name="partner_org_id"
            label="Linked Reseller"
            required
            rules={[{ required: true, message: 'Required' }]}
          >
            <Select disabled>
              <Select.Option value={partner?.data.id || ''}>
                {partner?.data.name}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="customer_org_id"
            label="TiDB Cloud Org"
            required
            rules={[{ required: true, message: 'Required' }]}
          >
            {!!org ? (
              <Select disabled>
                <Select.Option value={org.data.id || ''}>
                  {org.data.name}
                </Select.Option>
              </Select>
            ) : (
              <DebounceSelect
                showSearch
                placeholder="Search by ID/Name/Email"
                fetchOptions={async (search: string) => {
                  const data = await listTenants({
                    org_tag: '',
                    status: ['active'],
                    search
                  })
                  return (data.data.items || []).map((item) => {
                    return {
                      label: `${item.name} (Org ID: ${item.id})` || '',
                      value: item.id || ''
                    }
                  })
                }}
              />
            )}
          </Form.Item>
          <Form.Item
            name="payment_code"
            label="AWS Payment Code"
            required
            rules={[{ required: true, message: 'required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="effected_at"
            label="Effective Date"
            required
            rules={[{ required: true, message: 'required' }]}
          >
            <DatePicker
              picker="month"
              format={timeFormat}
              disabledDate={(date) => {
                if (config.SKIP_PARTNER_ACTIVE_CHECK) {
                  return false
                }
                const end = dayjs().endOf('month')
                return date.isBefore(end)
              }}
            />
          </Form.Item>

          <Divider>Discount Info</Divider>

          <Form.Item
            name="unit_filters"
            label="Discount Type"
            required
            rules={[{ required: true, message: 'Required' }]}
          >
            <TreeSelect
              showCheckedStrategy="SHOW_PARENT"
              treeCheckable
              treeDefaultExpandAll
              treeData={getTreeData(
                data?.data ? data.data?.children || [] : []
              )}
            />
          </Form.Item>
          <Form.Item
            label="Discount Percentage"
            required
            rules={[{ required: true, message: 'Required' }]}
          >
            <Form.Item name="discount" noStyle>
              <InputNumber<string> stringMode min="0" max="100" precision={2} />
            </Form.Item>
            <span className="ant-form-text">% Off</span>
          </Form.Item>
          <Form.Item
            label="Discount Start Date"
            required
            rules={[{ required: true, message: 'required' }]}
          >
            {!!startDate && serverTimeFormat(startDate.startOf('month'))} (CPPO
            Effective Date)
          </Form.Item>
          <Form.Item
            name="expired_at"
            label="Discount End Date"
            required
            rules={[{ required: true, message: 'required' }]}
          >
            <DatePicker
              picker="month"
              format={(v) => serverTimeFormat(v.endOf('month'))}
              disabledDate={(date) => {
                const end = dayjs().endOf('month')
                return date.isBefore(end)
              }}
            />
          </Form.Item>
          <Form.Item label="Discount Type" required>
            CPPO Discount
          </Form.Item>
        </>
      )
    },
    [org, partner, startDate]
  )

  return (
    <div>
      {Trigger}
      <ModalForm
        initValues={{
          partner_org_id: partner?.data.id || '',
          customer_org_id: org?.data.id || '',
          effected_at: '',
          payment_code: '',
          // @ts-ignore
          unit_filters: (defaultDiscount?.data || []).map(
            (it) => it.filter_id || ''
          )
        }}
        width={700}
        name="create-cppo-customer-form"
        title={title}
        visible={visible}
        onSubmit={onSubmit}
        onCancel={onClose}
        getFields={renderFields}
        onFieldsChange={onDateChange}
        destoryOnClose
      ></ModalForm>
    </div>
  )
}
