/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type GetMonthlySummaryOfDedicatedSLAStatusItem = typeof GetMonthlySummaryOfDedicatedSLAStatusItem[keyof typeof GetMonthlySummaryOfDedicatedSLAStatusItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetMonthlySummaryOfDedicatedSLAStatusItem = {
  perfect: 'perfect',
  warning: 'warning',
  breach: 'breach',
} as const;
