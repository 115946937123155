/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type EntitySecondaryConfirmStatus = typeof EntitySecondaryConfirmStatus[keyof typeof EntitySecondaryConfirmStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntitySecondaryConfirmStatus = {
  generated: 'generated',
  outdated: 'outdated',
  applying: 'applying',
  applied: 'applied',
} as const;
