import { Card, Modal, Descriptions, Table, Divider } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { cloudConfigControllerListDefaultConfigurationChangeItems } from 'apiClient/services/devops'
import {
  CloudconfigVersionRelease,
  CloudconfigDefaultConfigurationChangeItemType,
  CloudconfigDefaultConfigurationChangePhase
} from 'apiClient/services/devops/interface'

interface InstanceType {
  cloud_provider: string
  instance_type: string
}

function ParseInstanceTypeStr(instance_type_str: string): InstanceType {
  const [cloud_provider, instance_type] = instance_type_str.split(':')
  return {
    cloud_provider,
    instance_type
  }
}

function VersionReleaseInfoShow({
  reload,
  setReload,
  phase
}: {
  reload: boolean
  setReload: React.Dispatch<React.SetStateAction<boolean>>
  phase?: CloudconfigDefaultConfigurationChangePhase
}) {
  const { change_id } = useParams<{ change_id: string }>()
  const [verionRelease, setVerionRelease] =
    useState<CloudconfigVersionRelease>()

  useEffect(() => {
    fetch()
  }, [reload])

  async function fetch() {
    try {
      const chg = (
        await cloudConfigControllerListDefaultConfigurationChangeItems(
          change_id
        )
      ).data
        .items!.filter((v) => {
          return (
            v.type ===
            CloudconfigDefaultConfigurationChangeItemType.version_release
          )
        })
        .map((v) => v.data!.version_release!)
      setVerionRelease(chg[0])
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const columnInstanceType: ColumnsType<InstanceType> = [
    {
      title: 'Cloud Provider',
      render: (_: any, record) => {
        return record.cloud_provider
      }
    },
    {
      title: 'Instance Type',
      render: (_: any, record) => {
        return record.instance_type
      }
    }
  ]

  return (
    <div>
      <Card title={'Version Information To Be Released'} bordered={false}>
        <Descriptions>
          <Descriptions.Item label="Cluster Type" span={1}>
            {verionRelease?.cluster_type}
          </Descriptions.Item>
          <Descriptions.Item label="Source Version" span={1}>
            {verionRelease?.source_version}
          </Descriptions.Item>
          <Descriptions.Item label="Target Version" span={1}>
            {verionRelease?.target_version}
          </Descriptions.Item>
        </Descriptions>

        <Divider></Divider>

        <Title level={5}>Covered Instance Types</Title>
        <Table<InstanceType>
          columns={columnInstanceType}
          dataSource={verionRelease?.covering_instance_types
            ?.map(ParseInstanceTypeStr)
            .sort((a, b) => {
              if (a.cloud_provider != b.cloud_provider) {
                return a.cloud_provider.localeCompare(b.cloud_provider)
              }
              return a.instance_type.localeCompare(b.instance_type)
            })}
          size="small"
        />
      </Card>
    </div>
  )
}

export default VersionReleaseInfoShow
