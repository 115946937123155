/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudConfigControllerGetTenantInitConfigurationClusterType = typeof CloudConfigControllerGetTenantInitConfigurationClusterType[keyof typeof CloudConfigControllerGetTenantInitConfigurationClusterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudConfigControllerGetTenantInitConfigurationClusterType = {
  DEDICATED_TIDB: 'DEDICATED_TIDB',
  TICDC: 'TICDC',
  TIPROXY: 'TIPROXY',
} as const;
