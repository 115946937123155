/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ListTenantsPlanItem = typeof ListTenantsPlanItem[keyof typeof ListTenantsPlanItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListTenantsPlanItem = {
  new_user: 'new_user',
  free_trial: 'free_trial',
  poc: 'poc',
  on_demand: 'on_demand',
} as const;
