import { useMantineColorScheme } from '@tidbcloud/uikit'
import { useEffect } from 'react'
// Override the light theme css styles

import 'metrics-chart/dist/theme/light_theme.css'

const LIGHT_TOKEN = 'metrics-chart-light'
const DARK_TOKEN = 'metrics-chart-dark'

export const DynamicEchTheme: React.FC = ({ children }) => {
  const { colorScheme } = useMantineColorScheme()

  useEffect(() => {
    if (colorScheme === 'light') {
      window.document.body.classList.remove(DARK_TOKEN)
      window.document.body.classList.add(LIGHT_TOKEN)
    } else {
      window.document.body.classList.remove(LIGHT_TOKEN)
      window.document.body.classList.add(DARK_TOKEN)
    }
  }, [colorScheme])

  return <>{children}</>
}
