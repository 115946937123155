/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type UserbehaviorUBEventTp = typeof UserbehaviorUBEventTp[keyof typeof UserbehaviorUBEventTp];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserbehaviorUBEventTp = {
  tenant: 'tenant',
  cluster: 'cluster',
  import: 'import',
  backup: 'backup',
  restore: 'restore',
  cdc: 'cdc',
  dmjob: 'dmjob',
} as const;
