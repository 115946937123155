import { Button, Input, Modal, Select, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { cloudConfigControllerListTemplates } from 'apiClient/services/devops'
import {
  CloudConfigControllerListTemplatesClusterType,
  CloudconfigTemplate
} from 'apiClient/services/devops/interface'
import { ListToolBar, useTable } from 'components/Table'

function TemplateLists() {
  const [idName, setIDName] = useState<string>()

  const [listTemplateParam, setListTemplateParam] = useState<{
    id_in: string[] | undefined
    name_in: string[] | undefined
    cluster_type: CloudConfigControllerListTemplatesClusterType
  }>({
    id_in: undefined,
    name_in: undefined,
    cluster_type: CloudConfigControllerListTemplatesClusterType.DEDICATED_TIDB
  })
  const getData = (params?: Partial<PageInfo>) =>
    cloudConfigControllerListTemplates(listTemplateParam)
  const { dataSource, loading, reload, paginationProps, onTableChange } =
    useTable<ListData<CloudconfigTemplate>>(getData, [], {
      pagination: true
    })
  const columns: ColumnsType<CloudconfigTemplate> = [
    {
      title: 'ID',
      render: (_: any, record) => {
        return record.id
      }
    },
    {
      title: 'Name',
      render: (_: any, record) => {
        return record.name
      }
    },
    {
      title: 'Config Type',
      render: (_: any, record) => {
        return record.config_type
      }
    },
    {
      title: 'Cluster Type',
      render: (_: any, record) => {
        return record.cluster_type
      }
    },
    {
      title: 'Action',
      render: (_: any, record) => {
        return (
          <div>
            <Button type="primary">
              <Link to={`/template/${record.id}`}>Detail</Link>
            </Button>
          </div>
        )
      }
    }
  ]

  const actions = useMemo(
    () => [
      <Select
        options={[
          { value: 'ID', label: 'ID' },
          { value: 'Name', label: 'Name' }
        ]}
        onChange={(e) => {
          console.log('ee', e)
          setIDName(String(e))
        }}
        placeholder="ID/Name"
      ></Select>,
      <Input
        onChange={(e) => {
          console.log('idname', idName)
          if (idName === 'ID') {
            setListTemplateParam((pre) => ({
              ...pre,
              id_in: [e.target.value],
              name_in: undefined
            }))
          } else if (idName === 'Name') {
            setListTemplateParam((pre) => ({
              ...pre,
              name_in: [e.target.value],
              id_in: undefined
            }))
          } else {
            setListTemplateParam((pre) => ({
              ...pre,
              name_in: undefined,
              id_in: undefined
            }))
          }
        }}
      ></Input>,
      <Button
        type="primary"
        onClick={() => {
          reload()
        }}
      >
        Search
      </Button>
    ],
    [listTemplateParam, idName]
  )
  return (
    <div>
      <ListToolBar
        actions={actions}
        reload={() => {
          return reload()
        }}
      ></ListToolBar>
      <br></br>
      <Table<CloudconfigTemplate>
        columns={columns}
        rowKey="id"
        dataSource={dataSource}
        loading={loading}
        pagination={paginationProps}
        onChange={onTableChange}
        size="small"
      />
    </div>
  )
}

export default TemplateLists
