import React from 'react'
import { Alert } from 'antd'

export interface MessageBarProps {
  messages: string[]
  type?: MessageType
}

const barTitle = {
  success: 'Success Tips',
  info: 'Informational Notes',
  warning: 'Warning',
  error: 'Error'
}

export default function MessageBar({
  messages,
  type = 'info'
}: MessageBarProps) {
  if (messages.length === 0) {
    return null
  } else {
    return (
      <Alert
        type={type}
        showIcon
        message={barTitle[type]}
        description={
          messages.length === 1 ? (
            messages[0]
          ) : (
            <ul>
              {messages.map((msg, idx) => (
                <li key={idx}>{msg}</li>
              ))}
            </ul>
          )
        }
      />
    )
  }
}
