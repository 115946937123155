import React from 'react'
import { Menu, Button, Space, Typography } from 'antd'
import {
  ExportOutlined,
  UserOutlined,
  MailOutlined,
  TeamOutlined
} from '@ant-design/icons'

import useProfileContext from 'useProfileContext'
import auth from 'apiClient/auth'

const Profile = () => {
  const {
    profile: { email, name, roles = [] }
  } = useProfileContext()

  return (
    <Space direction="vertical">
      <Space>
        <UserOutlined />
        <Typography>{name}</Typography>
      </Space>
      <Space>
        <MailOutlined />
        <Typography>{email}</Typography>
      </Space>
      <Space>
        <TeamOutlined />
        <Typography>{roles.join(', ')}</Typography>
      </Space>
    </Space>
  )
}

export default () => {
  const onLogout = () => {
    auth.logout()
  }

  return (
    <Menu>
      <Menu.Item>
        <Profile />
      </Menu.Item>
      <Menu.Item>
        <Button type="text" icon={<ExportOutlined />} onClick={onLogout}>
          Logout
        </Button>
      </Menu.Item>
    </Menu>
  )
}
