// useTable Hook for Table state logic abstract
import { useState, useRef, useEffect, DependencyList } from 'react'
import ReactDOM from 'react-dom'
import { TablePaginationConfig } from 'antd/lib/table'

import { useDebounceFn, usePrevious } from 'utils/hooks'
import { AxiosResponse } from 'axios'

interface UseTableState<T extends ListData<any>> {
  dataSource: T['items']
  loading: boolean
  current: number // current page index
  pageSize: number
  total: number
  cancel: () => void
  reload: () => Promise<void>
  reset: () => void
  resetPageIndex: () => void
  setPageInfo: (pageInfo: PageInfo) => void
  paginationProps: TablePaginationConfig // table pagination props
  onTableChange: (
    changePagination: TablePaginationConfig,
    filters: {
      [string: string]: React.ReactText[] | null
    }
  ) => void // table onChange props: function(pagination, filters, sorter, extra: { currentDataSource: [], action: paginate | sort | filter })
}

const useTable = <T extends ListData<any>>(
  getData: (params?: Partial<PageInfo>) => Promise<AxiosResponse<any>>,
  defaultData?: Partial<T['items']>,
  options?: {
    current?: number // current page
    pageSize?: number // pageSize per page
    defaultCurrent?: number // default current page
    defaultPageSize?: number // default page size
    pagination?: boolean // enable pagination
    deps?: DependencyList
    onRequestError?: (e: Error) => void // error handler
  }
): UseTableState<T> => {
  const mountRef = useRef(true)

  const { pagination, onRequestError, deps = [] } = options || {}

  const [list, setList] = useState<T['items']>(defaultData as any)
  const [loading, setLoading] = useState<boolean>(false)
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    page: options?.current || options?.defaultCurrent || 1,
    total: 0,
    pageSize: options?.pageSize || options?.defaultPageSize || 10
  })

  // Batching update to optimize re-renders
  // https://github.com/facebook/react/issues/14259
  const setDataAndLoading = (newData: T[], dataTotal: number) => {
    ReactDOM.unstable_batchedUpdates(() => {
      setList(newData)
      setLoading(false)
      setPageInfo({
        ...pageInfo,
        total: dataTotal
      })
    })
  }

  // fetch list data
  const fetchList = async () => {
    if (loading || !mountRef.current) {
      return
    }
    setLoading(true)

    const { pageSize, page } = pageInfo
    try {
      const { items, total: dataTotal = 0 } = (
        await getData(
          pagination !== false
            ? {
                page,
                pageSize
              }
            : undefined
        )
      ).data
      // Do nothing when component unmounted before getData resolved
      if (!mountRef.current) {
        return
      }

      setDataAndLoading(items, dataTotal)
    } catch (e) {
      setLoading(false)
      if (onRequestError === undefined) {
        // throw new Error(e)
        console.error(e)
      } else {
        onRequestError(e)
      }
    }
  }

  const fetchListDebounce = useDebounceFn(fetchList, [], 10)

  // pre state
  const prePage = usePrevious(pageInfo.page)
  const prePageSize = usePrevious(pageInfo.pageSize)

  /**
   * pageIndex effects
   */
  useEffect(() => {
    const { page, pageSize } = pageInfo

    if (
      (!prePage || prePage === page) &&
      (!prePageSize || prePageSize === pageSize)
    ) {
      return () => undefined
    }

    if (page !== undefined && list.length <= pageSize) {
      fetchListDebounce.run()
      return () => fetchListDebounce.cancel()
    }

    return () => undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.page])

  //pageSize effects
  useEffect(() => {
    if (!prePageSize) {
      return () => undefined
    }

    // reset list
    setList([])
    setPageInfo({ ...pageInfo, page: 1 })
    fetchListDebounce.run()
    return () => fetchListDebounce.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo.pageSize])

  /**
   * reset pageIndex to 1
   */
  const resetPageIndex = () => {
    setPageInfo({ ...pageInfo, page: 1 })
  }

  useEffect(() => {
    mountRef.current = true
    fetchListDebounce.run()

    return () => {
      fetchListDebounce.cancel()
      mountRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  // TODO: performance optimization
  return {
    dataSource: list,
    loading,
    current: pageInfo.page,
    pageSize: pageInfo.pageSize,
    total: pageInfo.total,
    cancel: fetchListDebounce.cancel,
    reload: async () => fetchListDebounce.run(),
    reset: () => {
      setPageInfo({
        page: options?.defaultCurrent || 1,
        total: 0,
        pageSize: options?.defaultPageSize || 10
      })
    },
    resetPageIndex,
    setPageInfo: (info) =>
      setPageInfo({
        ...pageInfo,
        ...info
      }),
    paginationProps: {
      current: pageInfo.page,
      pageSize: pageInfo.pageSize,
      total: pageInfo.total
    },
    onTableChange: (changePagination) =>
      setPageInfo({
        page: changePagination.current || 1,
        pageSize: changePagination.pageSize || 10,
        total: changePagination.total || 0
      })
  }
}

export default useTable
