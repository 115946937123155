import React from 'react'

import {
  createGlobalDiscount,
  reclaimGlobalDiscount,
  useListGlobalDiscount
} from 'apiClient/services/billing'
import { FormsDiscount } from 'apiClient/services/billing/interface'
import { ListToolBar } from 'components/Table'

import {
  AddDiscountForm,
  DiscountTable
} from '../Tenants/TenantDetail/BusinessInfo/Discount'

export const GlobalDiscount = () => {
  const { data, refetch, isFetching } = useListGlobalDiscount({
    query: {
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false
    }
  })
  const onAdd = async (data: FormsDiscount) => {
    await createGlobalDiscount(data, { timeout: 15 * 1000 })
    refetch()
  }

  const onReclaim = async (record: FormsDiscount) => {
    await reclaimGlobalDiscount(record.discount_sid || '')
    refetch()
  }

  return (
    <div>
      <ListToolBar
        actions={[<AddDiscountForm onSubmit={onAdd} />]}
        reload={async () => {
          await refetch()
        }}
      ></ListToolBar>
      <DiscountTable
        loading={isFetching}
        data={data?.data || []}
        onReclaim={onReclaim}
      />
    </div>
  )
}
