import { Button } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

// import { cloudConfigControllerListTemplateChanges } from 'apiClient/services/devops'
// import { CloudconfigTemplateChange } from 'apiClient/services/devops/interface'
import {
  cloudConfigControllerListAvailableClusterVersions,
  cloudConfigControllerListDefaultConfigurationChanges
} from 'apiClient/services/devops'
import {
  CloudconfigDefaultConfigurationChange,
  CloudconfigAvailableClusterVersion
} from 'apiClient/services/devops/interface'
import { useTable } from 'components/Table'
import { toLocal } from 'pages/Devops/Maintenance/Detail'

function ReleaseTable() {
  const getData = (params?: Partial<PageInfo>) =>
    cloudConfigControllerListAvailableClusterVersions('DEDICATED_TIDB')
  const { dataSource, loading, reload, paginationProps, onTableChange } =
    useTable<ListData<CloudconfigAvailableClusterVersion>>(getData, [], {
      pagination: true,
      pageSize: 10
    })
  const columns: ColumnsType<CloudconfigAvailableClusterVersion> = [
    {
      title: 'Version',
      render: (_: any, record) => {
        return record.version
      }
    },
    {
      title: 'Is Default',
      render: (_: any, record) => {
        return String(record.is_default)
      }
    }
  ]
  return (
    <Table<CloudconfigAvailableClusterVersion>
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={paginationProps}
      onChange={onTableChange}
      size="small"
    />
  )
}

export default ReleaseTable
