import { Button, Card, Divider, Input, Modal, Select } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import {
  cloudConfigControllerChangeTemplateDefaultCaseBindings,
  cloudConfigControllerListAvailableClusterVersions,
  cloudConfigControllerListConfigTypes,
  cloudConfigControllerListInstanceTypes,
  cloudConfigControllerListTemplateDefaultCaseBindings,
  cloudConfigControllerListTenantInitConfigurationChanges
} from 'apiClient/services/devops'
import {
  CloudconfigTemplate,
  CloudconfigTemplateDefaultCaseBinding,
  ListK8SInformationCloudProvider,
  CloudconfigCloudInstanceType,
  CloudConfigControllerListInstanceTypesParams,
  CloudConfigControllerListTemplateDefaultCaseBindingsClusterType,
  CloudConfigControllerListDefaultConfigurationChangesContainItemType
} from 'apiClient/services/devops/interface'
import ModalForm from 'components/ModalForm'
import { ListToolBar, useTable } from 'components/Table'

import ChangesTable from './ChangesTable'
import ModifyForm, { ModifyType, key2binding } from './Form/ModifyForm'

export type IndexedCloudconfigTemplateDefaultCaseBinding =
  CloudconfigTemplateDefaultCaseBinding & { key: string }

export const columnsTemplate: ColumnsType<IndexedCloudconfigTemplateDefaultCaseBinding> =
  [
    {
      title: 'Version',
      render: (_: any, record) => {
        return record.default_case?.version
      }
    },
    {
      title: 'Config Type',
      render: (_: any, record) => {
        return record.default_case?.config_type
      }
    },
    {
      title: 'Cloud Provider',
      render: (_: any, record) => {
        return record.default_case?.cloud_provider || 'Any'
      }
    },
    {
      title: 'Instance Type',
      render: (_: any, record) => {
        return record.default_case?.instance_type || 'Any'
      }
    },
    {
      title: 'Default Template',
      render: (_: any, record) => {
        return (
          <div>
            <Button type="link">
              <Link target="_blank" to={`/template/${record.template_id}`}>
                {record.template_id}
              </Link>
            </Button>
          </div>
        )
      }
    }
  ]

function DefaultTemplate() {
  const [versionOpts, setVersionOpts] = useState<string[]>()
  const [cloudProviderOpts, setCloudProviderOpts] = useState<string[]>()
  const [instanceTypeOpts, setInstanceTypeOpts] = useState<string[]>()
  const [configTypeOpts, setConfigTypeOpts] = useState<string[]>()
  const [uniqueInstanceOpts, setUniqueInstanceOpts] =
    useState<CloudconfigCloudInstanceType[]>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [modalChange, setModalChange] = useState(false)

  const history = useHistory()

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    let pre = selectedRowKeys
    pre = pre.filter((v) => {
      let arr = dataSource.map((v) => {
        let arr = []
        arr.push(v.template_id)
        arr.push(v.default_case?.version)
        arr.push(v.default_case?.cloud_provider)
        arr.push(v.default_case?.instance_type)
        arr.push(v.default_case?.config_type)
        arr.push(v.cluster_type)
        return arr.join(', ')
      })
      if (arr.includes(String(v))) {
        return false
      } else {
        return true
      }
    })
    pre = pre.concat(newSelectedRowKeys)
    pre = Array.from(new Set(pre))
    setSelectedRowKeys(pre)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange
  }

  useEffect(() => {
    fetch()
  }, [])

  async function fetch() {
    try {
      const versions = (
        await cloudConfigControllerListAvailableClusterVersions(
          'DEDICATED_TIDB'
        )
      ).data.items
      setVersionOpts(versions?.map((v) => v.version!))
      const configTypes = (
        await cloudConfigControllerListConfigTypes('DEDICATED_TIDB')
      ).data.config_types
      setConfigTypeOpts(configTypes)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const [listTemplateParam, setListTemplateParam] = useState<{
    cluster_type: CloudConfigControllerListTemplateDefaultCaseBindingsClusterType
    default_for_cloud_provider: string | undefined
    default_for_version: string | undefined
    default_for_instance_type: string | undefined
    config_type_is: string | undefined
  }>({
    cluster_type:
      CloudConfigControllerListTemplateDefaultCaseBindingsClusterType.DEDICATED_TIDB,
    default_for_cloud_provider: undefined,
    default_for_version: undefined,
    default_for_instance_type: undefined,
    config_type_is: undefined
  })
  const getData = (params?: Partial<PageInfo>) =>
    cloudConfigControllerListTemplateDefaultCaseBindings({
      ...listTemplateParam,
      page: params?.page,
      per_page: params?.pageSize
    })
  const { dataSource, loading, reload, paginationProps, onTableChange } =
    useTable<ListData<CloudconfigTemplateDefaultCaseBinding>>(getData, [], {
      pagination: true,
      pageSize: 10
    })

  const handleCreateTemplateChange = async (payload: ModifyType) => {
    try {
      const bindings: CloudconfigTemplateDefaultCaseBinding[] =
        selectedRowKeys.map((v) => {
          return key2binding(v)
        })

      const resp = (
        await cloudConfigControllerChangeTemplateDefaultCaseBindings({
          default_case_bindings: bindings,
          reason: payload.reason
        })
      ).data
      Modal.success({
        title: 'Info',
        content: (
          <div>
            <p>
              The modification of selected default cases is created.
              <br></br>
              <br></br>
              To edit the modification, please refer to the{' '}
              <a href={`/defaultconfig/change/${resp.change_id}`}>
                Detail
              </a>{' '}
              page.
            </p>
          </div>
        ),
        onOk() {
          history.push(`/version/modify/${resp.change_id}`)
        }
      })
      setModalChange(false)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  async function fetchUniqueInstance() {
    try {
      var params: CloudConfigControllerListInstanceTypesParams = {
        version: listTemplateParam.default_for_version,
        config_type: listTemplateParam.config_type_is
      }
      const tuples = (
        await cloudConfigControllerListInstanceTypes('DEDICATED_TIDB', params)
      ).data
      const instanceTypes = tuples.instance_types?.map((v) => {
        return v.instance_type!
      })
      const cloudProviders = Array.from(
        new Set(
          tuples.instance_types?.map((v) => {
            return v.cloud_provider!
          })
        )
      )
      setInstanceTypeOpts(instanceTypes)
      setCloudProviderOpts(cloudProviders)
      setUniqueInstanceOpts(tuples.instance_types)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  useEffect(() => {
    fetchUniqueInstance()
  }, [listTemplateParam.default_for_version, listTemplateParam.config_type_is])

  const actions = useMemo(
    () => [
      <div>version: </div>,
      <Select
        value={listTemplateParam.default_for_version}
        dropdownMatchSelectWidth={false}
        options={[
          { label: 'Any', value: '' },
          ...(versionOpts?.map((v) => {
            return { label: v, value: v }
          }) || [])
        ]}
        defaultValue={''}
        onChange={(event) =>
          setListTemplateParam((pre) => ({
            ...pre,
            default_for_version: String(event)
          }))
        }
      ></Select>,
      <div>cloud provider: </div>,
      <Select
        value={listTemplateParam.default_for_cloud_provider}
        dropdownMatchSelectWidth={false}
        options={[
          { label: 'Any', value: '' },
          ...(cloudProviderOpts?.map((v) => {
            return { label: v, value: v }
          }) || [])
        ]}
        defaultValue={''}
        onChange={(event) =>
          setListTemplateParam((pre) => ({
            ...pre,
            default_for_cloud_provider: String(event)
          }))
        }
        placeholder="cloud provider"
      ></Select>,
      <div>instance type:</div>,
      <Select
        value={listTemplateParam.default_for_instance_type}
        dropdownMatchSelectWidth={false}
        options={[
          { label: 'Any', value: '' },
          ...(instanceTypeOpts?.map((v) => {
            return { label: v, value: v }
          }) || [])
        ]}
        defaultValue={''}
        onChange={(event) =>
          setListTemplateParam((pre) => ({
            ...pre,
            default_for_instance_type: String(event)
          }))
        }
      ></Select>,
      <div>config type: </div>,
      <Select
        value={listTemplateParam.config_type_is}
        dropdownMatchSelectWidth={false}
        options={[
          { label: 'Any', value: '' },
          ...(configTypeOpts?.map((v) => {
            return { label: v, value: v }
          }) || [])
        ]}
        defaultValue={''}
        onChange={(event) =>
          setListTemplateParam((pre) => ({
            ...pre,
            config_type_is: String(event)
          }))
        }
      ></Select>,
      <Button
        type="primary"
        onClick={() => {
          reload()
          setSelectedRowKeys([])
        }}
      >
        Search
      </Button>
    ],
    [
      listTemplateParam,
      versionOpts,
      cloudProviderOpts,
      instanceTypeOpts,
      configTypeOpts
    ]
  )

  const handleCancelChange = () => {
    setModalChange(false)
  }

  const renderFormFields = useCallback(
    (form) => {
      return <ModifyForm keys={selectedRowKeys} />
    },
    [modalChange]
  )

  const [activeTabKey, setActiveTabKey] = useState<string>('history')
  const onTabChange = (key: string) => {
    setActiveTabKey(key)
  }
  const tabList = [
    {
      key: 'history',
      tab: 'Modification Histories'
    }
  ]
  const contentList: Record<string, React.ReactNode> = {
    history: (
      <ChangesTable
        type={
          CloudConfigControllerListDefaultConfigurationChangesContainItemType.template_default_case_binding
        }
      ></ChangesTable>
    )
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <div>version: </div>
          <Select
            showSearch
            filterOption={(input, option) =>
              (String(option?.label).toLowerCase() ?? '').includes(
                input.toLowerCase()
              )
            }
            optionFilterProp="label"
            value={listTemplateParam.default_for_version}
            style={{ width: 100 }}
            options={[
              { label: 'Any', value: '' },
              ...(versionOpts?.map((v) => {
                return { label: v, value: v }
              }) || [])
            ]}
            defaultValue={''}
            onChange={(event) =>
              setListTemplateParam((pre) => ({
                ...pre,
                default_for_version: String(event)
              }))
            }
          ></Select>
          <div>config type: </div>
          <Select
            value={listTemplateParam.config_type_is}
            dropdownMatchSelectWidth={false}
            options={[
              { label: 'Any', value: '' },
              ...(configTypeOpts?.map((v) => {
                return { label: v, value: v }
              }) || [])
            ]}
            defaultValue={''}
            onChange={(event) =>
              setListTemplateParam((pre) => ({
                ...pre,
                config_type_is: String(event)
              }))
            }
          ></Select>
          <div>instance type:</div>
          <Select
            showSearch
            filterOption={(input, option) =>
              (String(option?.label).toLowerCase() ?? '').includes(
                input.toLowerCase()
              )
            }
            optionFilterProp="label"
            value={listTemplateParam.default_for_instance_type}
            style={{ width: 200 }}
            options={[
              { label: 'Any', value: '' },
              ...(uniqueInstanceOpts
                ?.filter((v) => {
                  if (
                    listTemplateParam.default_for_cloud_provider == undefined ||
                    listTemplateParam.default_for_cloud_provider ==
                      v.cloud_provider ||
                    listTemplateParam.default_for_cloud_provider == ''
                  ) {
                    return true
                  }
                })
                .map((v) => {
                  return {
                    label: '[' + v.cloud_provider + '] ' + v.instance_type,
                    value: v.instance_type || ''
                  }
                }) || [])
            ]}
            defaultValue={''}
            onChange={(event) =>
              setListTemplateParam((pre) => ({
                ...pre,
                default_for_instance_type: String(event)
              }))
            }
          ></Select>
          <Button
            type="primary"
            onClick={() => {
              reload()
              setSelectedRowKeys([])
            }}
          >
            Search
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setModalChange(true)
            }}
          >
            Modify
          </Button>
        </div>
      </div>

      <br></br>
      <span style={{ marginLeft: 8 }}>
        {`Selected ${selectedRowKeys.length} items, total ${paginationProps.total}`}
      </span>
      <Table<IndexedCloudconfigTemplateDefaultCaseBinding>
        rowSelection={rowSelection}
        columns={columnsTemplate}
        dataSource={dataSource.map((v) => {
          let arr = []
          arr.push(v.template_id)
          arr.push(v.default_case?.version)
          arr.push(v.default_case?.cloud_provider)
          arr.push(v.default_case?.instance_type)
          arr.push(v.default_case?.config_type)
          arr.push(v.cluster_type)
          return { ...v, key: arr.join(', ') }
        })}
        loading={loading}
        pagination={paginationProps}
        onChange={onTableChange}
        size="small"
      />
      {/* <div
        className="flex-container"
        style={{ justifyContent: 'flex-end', display: 'flex' }}
      >

      </div> */}
      <Divider></Divider>
      <Card
        tabList={tabList}
        tabProps={{ size: 'middle' }}
        bordered={false}
        activeTabKey={activeTabKey}
        onTabChange={(key) => {
          onTabChange(key)
        }}
      >
        {contentList[activeTabKey]}
      </Card>
      <ModalForm<ModifyType>
        visible={!!modalChange}
        name="modify"
        title={'Modify Default Templates'}
        getFields={renderFormFields}
        onSubmit={handleCreateTemplateChange}
        onCancel={handleCancelChange}
        okText="Confirm"
        width={1000}
      ></ModalForm>
    </div>
  )
}

export default DefaultTemplate
