import { message } from 'antd'
import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import auth from 'apiClient/auth'
import { PageLoader } from 'components/Loader'

export const AuthRedirect = () => {
  const history = useHistory()
  useEffect(() => {
    const path =
      history.location.pathname.slice(-1) === `/`
        ? history.location.pathname.slice(0, -1)
        : history.location.pathname
    const params = history.location.search
    auth.login(path + params)
  }, [])

  return <PageLoader tip="Redirecting you to the login page..." />
}

export const AuthCallback = () => {
  const history = useHistory()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  var prev = searchParams.get('prev')
  for (const [key, value] of searchParams) {
    if (key !== 'prev') {
      prev += `&${key}=${value}`
    }
  }

  useEffect(() => {
    auth.handleAuthentication(
      async () => {
        // successful callback
        await message.success('Login successfully', 1)
        // redirect to the original entry page after 1s
        if (prev) {
          history.push(prev)
        } else {
          history.push(`/`)
        }
      },
      async (err) => {
        // handle error
        await message.error(`Login failed: ${err}`)
        // redirect to login page after 3s (message default duration)
        auth.login('/')
      }
    )
  }, [history])

  return <PageLoader tip="Please wait..." />
}
