import { Table, Input, Button, notification, Typography, Space } from 'antd'
import { ColumnsType, TableProps } from 'antd/lib/table'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useCreateMspAPIKey, useListTenants } from 'apiClient/services/account'
import { FormsTenant } from 'apiClient/services/account/interface'
import { ListToolBar } from 'components/Table'
import { getErrorMessage } from 'utils/error'

import { CreateMSP } from './Create'

const ResendAPIKey: React.FC<{ data: FormsTenant }> = ({ data }) => {
  const { mutateAsync, isLoading } = useCreateMspAPIKey()

  return (
    <Button
      type="link"
      onClick={async () => {
        try {
          await mutateAsync({
            orgId: data.id || '',
            data: { org_id: data.id || '' }
          })
          notification.success({ message: 'Sent.' })
        } catch (e) {
          notification.error({ message: getErrorMessage(e) })
        }
      }}
      loading={isLoading}
    >
      Resend API Key
    </Button>
  )
}

export const MSP = () => {
  const columns: ColumnsType<FormsTenant> = [
    {
      title: 'MSP',
      dataIndex: 'name',
      render(value, record, index) {
        return (
          <Space direction="vertical">
            {record.name}
            <Typography.Text type="secondary">{record.id}</Typography.Text>
          </Space>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status'
    },
    {
      title: 'Country',
      dataIndex: 'country'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Actions',
      fixed: 'right',
      render: (value, record) => {
        return (
          <div>
            <Link to={`/msp/${record.id}`}>Detail</Link>
            <ResendAPIKey data={record} key={record.id || ''} />
          </div>
        )
      }
    }
  ]
  const [pageConfig, setPageConfig] = useState<{
    page: number
    pageSize?: number
    search?: string
  }>({
    page: 1,
    pageSize: 10,
    search: ''
  })
  const { data, isLoading } = useListTenants({
    org_tag: 'msp',
    page: pageConfig.page,
    per_page: pageConfig.pageSize,
    search: pageConfig.search
  })

  const onPaginationChange = (page: number, pageSize?: number) => {
    setPageConfig({ page, pageSize: pageSize || 10 })
  }

  const handleTableChange: TableProps<any>['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    // return reload()
  }

  return (
    <div>
      <ListToolBar
        reload={async () => {}}
        actions={[
          <CreateMSP
            action="add"
            onComplete={() => {
              setPageConfig({ page: 1, search: '' })
            }}
          />,
          <Input.Group compact className="list-search-bar-container">
            <Input.Search
              placeholder="Search by ID/Name/Email"
              style={{ width: '18rem' }}
              onSearch={(value: string) => {
                setPageConfig({
                  page: 1,
                  pageSize: 10,
                  search: value
                })
              }}
              allowClear
            />
          </Input.Group>
        ]}
      ></ListToolBar>
      <Table
        columns={columns}
        rowKey="id"
        dataSource={data?.data.items || []}
        loading={isLoading}
        size="small"
        // scroll={{ x: 1400 }}
        pagination={{
          current: pageConfig.page,
          pageSize: pageConfig.pageSize || 10,
          total: data?.data.total || 0,
          onChange: onPaginationChange
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
