/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ListK8sUpgradeTasksCloudProviderItem = typeof ListK8sUpgradeTasksCloudProviderItem[keyof typeof ListK8sUpgradeTasksCloudProviderItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListK8sUpgradeTasksCloudProviderItem = {
  aws: 'aws',
  gcp: 'gcp',
} as const;
