/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ListK8SInformationCloudProvider = typeof ListK8SInformationCloudProvider[keyof typeof ListK8SInformationCloudProvider];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListK8SInformationCloudProvider = {
  aws: 'aws',
  gcp: 'gcp',
} as const;
