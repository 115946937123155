import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { UIWrapper } from 'uikit/contexts'

import App from './App'
import * as serviceWorker from './serviceWorker'

const queryClient = new QueryClient()

// Temporarily disable React.StrictMode in dev and prod.
// https://github.com/ant-design/ant-design/issues/22493
ReactDOM.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <UIWrapper>
      <App />
    </UIWrapper>
  </QueryClientProvider>,
  // </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
