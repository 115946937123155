/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type EntityRemediationTicketPhase = typeof EntityRemediationTicketPhase[keyof typeof EntityRemediationTicketPhase];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityRemediationTicketPhase = {
  created: 'created',
  region_tse_leader: 'region_tse_leader',
  rdg_sla_owner: 'rdg_sla_owner',
  rdg_final_decision_maker: 'rdg_final_decision_maker',
  global_deal_desk: 'global_deal_desk',
  regional_gm: 'regional_gm',
  commercial_operation: 'commercial_operation',
  succeed: 'succeed',
  closed: 'closed',
} as const;
