import { Button, Card, Descriptions, Divider, Modal, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import Title from 'antd/lib/typography/Title'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'

import {
  cloudConfigControllerApplyConfigurationChange,
  cloudConfigControllerCancelConfigurationChange,
  cloudConfigControllerGetConfigChange
} from 'apiClient/services/devops'
import {
  AuditControllerListAuditLogType,
  CloudconfigConfigurationChange,
  CloudconfigConfigurationChangeTargetType,
  CloudconfigConfigurationComponentChange,
  CloudconfigConfigurationFileChange
} from 'apiClient/services/devops/interface'
import ApprovalTable from 'components/ApprovalTable'
import AuditTable from 'components/Audit'
import { toLocal } from 'pages/Devops/Maintenance/Detail'

import { convertToString } from '../Forms/PreviewForm'

import ExpandTable from './ExpandTable'

const ClusterConfigHistoryDetail = () => {
  const [change, setChange] = useState<CloudconfigConfigurationChange>()
  const { history_id } = useParams<{ history_id: string }>()
  const [reload, setReload] = useState(true)
  const [activeChangeTabKey, setActiveChangeTabKey] =
    useState<string>('version')

  const handleApply = (id: number | undefined) => {
    return async () => {
      Modal.info({
        title: 'Info',
        closable: true,
        content: (
          <div>
            <p>
              Are you sure to <b>APPLY</b>?
            </p>
          </div>
        ),
        okText: 'Confirm',
        async onOk() {
          try {
            await cloudConfigControllerApplyConfigurationChange(String(id), {})
            Modal.success({
              title: 'info',
              content: (
                <div>
                  <p>Successfully Apply</p>
                </div>
              ),
              onOk() {}
            })
            setReload((pre) => !pre)
          } catch (error) {
            Modal.error({
              title: error.response.data.errcode,
              content: error.response.data.errmsg
            })
            setReload((pre) => !pre)
          }
        }
      })
    }
  }

  const columns: ColumnsType<ConfigSnapshot> = [
    {
      title: 'Name',
      render: (_: any, record) => {
        return record.name
      }
    },
    {
      title: 'Filename',
      render: (_: any, record) => {
        return record.filename
      },
      filters: change?.scope?.files?.map((v) => {
        return { text: v.filename!, value: v.filename! }
      })
    },
    {
      title: 'Value',
      render: (_: any, record) => {
        return convertToString(record?.value)
      }
    }
  ]
  const handleCancel = (id: number | undefined) => {
    return async () => {
      Modal.info({
        title: 'Info',
        closable: true,
        content: (
          <div>
            <p>
              Are you sure to <b>CANCEL</b>?
            </p>
          </div>
        ),
        okText: 'Confirm',
        async onOk() {
          try {
            await cloudConfigControllerCancelConfigurationChange(String(id))
            Modal.success({
              title: 'info',
              content: (
                <div>
                  <p>Successfully Cancel</p>
                </div>
              ),
              onOk() {}
            })
            setReload((pre) => !pre)
          } catch (error) {
            Modal.error({
              title: error.response.data.errcode,
              content: error.response.data.errmsg
            })
            setReload((pre) => !pre)
          }
        }
      })
    }
  }

  const columnsComponents: ColumnsType<CloudconfigConfigurationComponentChange> =
    [
      {
        title: 'Component',
        render: (_: any, record) => {
          return record.component
        }
      },
      {
        title: 'Modified Image Tag',
        render: (_: any, record) => {
          return `${String(record.modified_image_tag?.from)} -> ${String(
            record.modified_image_tag?.to
          )}`
        }
      }
    ]

  const [activeTabKey, setActiveTabKey] = useState<string>('approval')
  const onTabChange = (key: string) => {
    setActiveTabKey(key)
  }

  const tabList = [
    {
      key: 'approval',
      tab: 'Approval Info'
    },
    {
      key: 'audit',
      tab: 'Audit Logs'
    }
  ]
  const contentList: Record<string, React.ReactNode> = {
    approval:
      change?.approval_id !== undefined ? (
        <ApprovalTable id={change?.approval_id}></ApprovalTable>
      ) : (
        <></>
      ),
    audit:
      change?.id !== undefined ? (
        <AuditTable
          type={AuditControllerListAuditLogType.cloud_config_change}
          id={change.id}
        ></AuditTable>
      ) : (
        <></>
      )
  }

  const onChangeTabChange = (key: string) => {
    setActiveChangeTabKey(key)
  }
  const changeTabList = [
    {
      condition: change?.scope?.version !== null,
      item: {
        key: 'version',
        tab: 'Version'
      }
    },
    {
      condition: change?.scope?.components !== null,
      item: {
        key: 'components',
        tab: 'Components'
      }
    },
    {
      condition: change?.scope?.files !== null,
      item: {
        key: 'files',
        tab: 'Configuration Files'
      }
    }
  ]
    .filter((entry) => entry.condition)
    .map((entry) => entry.item)
  const changeContentList: Record<string, React.ReactNode> = {
    version:
      change?.scope?.version !== null ? (
        <div>
          <Title level={5}>Version Modification Information</Title>
          <Descriptions>
            <Descriptions.Item label="From Version">
              <b>{change?.scope?.version!.from}</b>
            </Descriptions.Item>
            <Descriptions.Item label="To Version">
              <b>{change?.scope?.version!.to}</b>
            </Descriptions.Item>
          </Descriptions>
          <Divider></Divider>
        </div>
      ) : (
        <></>
      ),
    components:
      change?.scope?.components !== null ? (
        <div>
          <Title level={5}>Component Modification Information</Title>
          <Table
            dataSource={change?.scope?.components?.filter((v) => {
              return v.modified_image_tag !== null
            })}
            columns={columnsComponents}
            pagination={{ pageSize: 10 }}
          ></Table>
          <Divider></Divider>
        </div>
      ) : (
        <></>
      ),
    files: (
      <div>
        <Title level={5}>Configuration Files Modification Information</Title>
        <ExpandTable change={change}></ExpandTable>
        <br></br>
        <Title level={5}>Configuration Snapshot</Title>
        <Table
          dataSource={mapToList(change?.scope?.files || [])}
          columns={columns}
          pagination={{ pageSize: 10 }}
        />
        <Divider></Divider>
      </div>
    )
  }

  useEffect(() => {
    fetch()
  }, [reload])

  async function fetch() {
    try {
      const data = (await cloudConfigControllerGetConfigChange(history_id)).data
        .change
      setActiveChangeTabKey(
        data?.scope?.version !== null
          ? 'version'
          : data.scope.components !== null
          ? 'components'
          : 'files'
      )
      setChange(data)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <div>
      <Card
        title={
          <Title level={4}>{`${
            change?.target_type ===
            CloudconfigConfigurationChangeTargetType.tenant_init
              ? 'Tenant Init'
              : 'Cluster'
          } Config Modification`}</Title>
        }
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={handleApply(parseInt(history_id))}
              disabled={!(change?.phase === 'wait_for_apply')}
            >
              Apply
            </Button>
            &nbsp;
            <Button
              type="primary"
              onClick={handleCancel(parseInt(history_id))}
              disabled={
                change?.phase === 'cancelled' ||
                change?.phase === 'withdrawn' ||
                change?.phase === 'applied'
              }
            >
              Cancel
            </Button>
          </Space>
        }
      >
        <Descriptions>
          <Descriptions.Item label=" ID">{history_id}</Descriptions.Item>
          <Descriptions.Item label="Target Type">
            {change?.target_type}
          </Descriptions.Item>
          {change?.target_type === 'tenant_init' ? (
            <Descriptions.Item label="Tenant ID">
              {change?.target_tenant?.tenant_id}
            </Descriptions.Item>
          ) : (
            <Descriptions.Item label="Cluster ID">
              {change?.target_cluster?.cluster_id}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Applicant">
            {change?.applicant}
          </Descriptions.Item>
          <Descriptions.Item label="Apply Policy">
            {change?.apply_policy}
          </Descriptions.Item>
          <Descriptions.Item label="phase">{change?.phase}</Descriptions.Item>
          <Descriptions.Item label="Created At">
            {toLocal(change?.created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Close At">
            {toLocal(change?.closed_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Reason">{change?.reason}</Descriptions.Item>
        </Descriptions>
        <Divider></Divider>
        <Card
          tabList={changeTabList}
          tabProps={{ size: 'middle' }}
          bordered={false}
          activeTabKey={activeChangeTabKey}
          onTabChange={(key) => {
            onChangeTabChange(key)
          }}
        >
          {changeContentList[activeChangeTabKey]}
        </Card>
      </Card>

      <Card
        tabList={tabList}
        tabProps={{ size: 'middle' }}
        bordered={false}
        activeTabKey={activeTabKey}
        onTabChange={(key) => {
          onTabChange(key)
        }}
      >
        {contentList[activeTabKey]}
      </Card>
    </div>
  )
}

type ConfigSnapshot = {
  name?: string
  filename?: string
  value?: unknown
}

const mapToList = (f: CloudconfigConfigurationFileChange[]) => {
  let res: ConfigSnapshot[] = []
  f.forEach((v) => {
    let fn = v.filename
    v.all_parameters?.forEach((v2) => {
      res.push({ name: v2.name, filename: fn, value: v2.value })
    })
  })
  return res
}

export default ClusterConfigHistoryDetail
