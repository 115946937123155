import { Form, Select, TreeSelect } from 'antd'

export const CloudProviders = {
  All: 'all',
  AWS: 'AWS',
  GCP: 'GCP',
  AZURE: 'AZURE'
} as const

export const CloudProviderSelect: React.FC<{ name?: string }> = ({
  name = 'provider'
}) => {
  const options = [
    {
      label: 'All',
      value: CloudProviders.All
    },
    {
      label: 'AWS',
      value: CloudProviders.AWS
    },
    {
      label: 'GCP',
      value: CloudProviders.GCP
    },
    {
      label: 'Azure',
      value: CloudProviders.AZURE
    }
  ]

  return (
    <Form.Item name={name} label="Cloud Provider">
      <Select placeholder="Please select" options={options} />
    </Form.Item>
  )
}
