/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type EntityRemediationTicketStatus = typeof EntityRemediationTicketStatus[keyof typeof EntityRemediationTicketStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityRemediationTicketStatus = {
  pending: 'pending',
  approving: 'approving',
  approved: 'approved',
  rejected: 'rejected',
  closed: 'closed',
} as const;
