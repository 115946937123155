/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type GetMonthlySummaryOfDedicatedSLATidbInstanceMode = typeof GetMonthlySummaryOfDedicatedSLATidbInstanceMode[keyof typeof GetMonthlySummaryOfDedicatedSLATidbInstanceMode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetMonthlySummaryOfDedicatedSLATidbInstanceMode = {
  single: 'single',
  multi: 'multi',
} as const;
