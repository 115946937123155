/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudConfigControllerListDefaultConfigurationChangesContainItemType = typeof CloudConfigControllerListDefaultConfigurationChangesContainItemType[keyof typeof CloudConfigControllerListDefaultConfigurationChangesContainItemType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudConfigControllerListDefaultConfigurationChangesContainItemType = {
  version_release: 'version_release',
  template_default_case_binding: 'template_default_case_binding',
  default_component_config: 'default_component_config',
} as const;
