import { Form, Input, Table } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

import { CloudconfigClusterType } from 'apiClient/services/devops/interface'

import {
  IndexedCloudconfigTemplateDefaultCaseBinding,
  columnsTemplate
} from '..'

export const key2binding = (v: React.Key) => {
  const strs = String(v).split(', ')
  const template_id = strs[0]
  const version = strs[1]
  const cloud_provider = strs[2]
  const instance_type = strs[3]
  const config_type = strs[4]
  const cluster_type = strs[5]
  return {
    key: String(v),
    cluster_type: cluster_type as CloudconfigClusterType,
    template_id: parseInt(template_id),
    default_case: {
      version: version,
      cloud_provider: cloud_provider,
      instance_type: instance_type,
      config_type: config_type
    }
  }
}

export type ModifyType = {
  reason: string
}

function ModifyForm({ keys }: { keys: React.Key[] }) {
  let dataSource: IndexedCloudconfigTemplateDefaultCaseBinding[] = keys.map(
    (v) => {
      return key2binding(v)
    }
  )

  return (
    <div>
      <h3>
        Confirm to create a modication to modify the following default
        templates?
      </h3>
      <Table<IndexedCloudconfigTemplateDefaultCaseBinding>
        columns={columnsTemplate}
        dataSource={dataSource}
        size="small"
      ></Table>
      <Form.Item
        rules={[{ required: true }]}
        name={'reason'}
        label={'Reason'}
        style={{ marginLeft: 0 }}
        labelCol={{ span: 0 }}
      >
        <TextArea />
      </Form.Item>
    </div>
  )
}

export default ModifyForm
