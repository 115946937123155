import auth0, { Auth0DecodedHash } from 'auth0-js'

const CALLBACK_URI: string = `${window.location.origin}/auth_redirect`
const AUTH0_DOMAIN =
  process.env.REACT_APP_AUTH0_DOMAIN || window.location.origin
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || 'client_id'

class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH0_DOMAIN,
    clientID: AUTH0_CLIENT_ID,
    redirectUri: CALLBACK_URI,
    responseType: 'token id_token',
    scope: 'openid email'
  })

  login(path: string) {
    const params = new URLSearchParams()
    params.append('prev', path)
    window &&
      this.auth0.authorize({ redirectUri: CALLBACK_URI + `?prev=${path}` })
  }

  logout = () => {
    this.removeAuthSession()
    // logout and navigate to the home route
    this.auth0.logout({
      returnTo: `${window.location.origin}`
    })
  }

  handleAuthentication = (
    onSuccess: () => void,
    onError?: (err: string) => void
  ) => {
    this.auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
      // console.log('Auth Info ', { err, authResult })
      if (authResult && authResult.accessToken && authResult.idToken) {
        // save auth0 tokens
        this.setAuthSession(authResult)
        onSuccess()
      } else {
        // process error
        if (onError) {
          onError(err?.errorDescription || 'authentication failure')
        } else {
          console.error(err || 'authentication failure')
          this.login(`/`)
        }
      }
    })
  }

  setAuthSession = (authResult: Auth0DecodedHash) => {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify(
      (authResult.expiresIn || 0) * 1000 + new Date().getTime()
    )

    localStorage.setItem('access_token', authResult.accessToken || '')
    localStorage.setItem('id_token', authResult.idToken || '')
    localStorage.setItem('expires_at', expiresAt || '')
    localStorage.setItem(
      'sign_in_email',
      authResult.idTokenPayload?.email || ''
    )
  }

  removeAuthSession() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('sign_in_email')
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // Access Token's expiry time
    try {
      const expiresAt = localStorage.getItem('expires_at') || ''

      return new Date().getTime() < +expiresAt
    } catch (e) {
      console.error(e)
      return false
    }
  }

  getAuthToken = () => {
    try {
      return localStorage.getItem('id_token')
    } catch (e) {
      console.error(e)
      return null
    }
  }
}

const auth = new Auth()

export default auth
