import {
  type MantineProviderProps,
  MantineProvider,
  ColorSchemeProvider
} from '@tidbcloud/uikit'
import React from 'react'
import { Theme, themeColors } from 'uikit/theme'

export const UIKitThemeProvider: React.FC<MantineProviderProps> = ({
  theme = Theme,
  children
}) => {
  return (
    <ColorSchemeProvider colorScheme="light" toggleColorScheme={() => {}}>
      <MantineProvider
        theme={{
          ...theme,
          colors: themeColors
        }}
      >
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  )
}
