/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type JumpDestinationType = typeof JumpDestinationType[keyof typeof JumpDestinationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const JumpDestinationType = {
  unspecified: 'unspecified',
  controlplane: 'controlplane',
  dedicated: 'dedicated',
  serverless: 'serverless',
} as const;
