import { Form, FormInstance, Input } from 'antd'

import { CloudconfigDefaultComponentChange } from 'apiClient/services/devops/interface'

export type EditImageFormType = {
  [key: string]: string
}

function EditImageForm({
  form,
  change
}: {
  form: FormInstance
  change?: CloudconfigDefaultComponentChange[]
}) {
  return (
    <>
      {change?.map((v) => {
        return (
          <Form.Item
            name={v.origin?.default_case?.component}
            label={v.origin?.default_case?.component}
            initialValue={v.modified?.image_tag}
          >
            <Input type="text"></Input>
          </Form.Item>
        )
      })}
    </>
  )
}

export default EditImageForm
