import React from 'react'
import { Button } from 'antd'

import auth from 'apiClient/auth'

export default function AuthenticationErrorContent() {
  const email = localStorage.getItem('sign_in_email')

  const handleSwitch = () => {
    auth.logout()
  }

  return (
    <>
      Your current account {email && <strong>{email}</strong>} doesn't exist in
      TiDB Cloud Management Portal, please
      <Button type="link" size="small" onClick={handleSwitch}>
        switch
      </Button>
      to another account and try again.
    </>
  )
}
