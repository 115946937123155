/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudConfigControllerListTemplatesClusterType = typeof CloudConfigControllerListTemplatesClusterType[keyof typeof CloudConfigControllerListTemplatesClusterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudConfigControllerListTemplatesClusterType = {
  DEDICATED_TIDB: 'DEDICATED_TIDB',
  TICDC: 'TICDC',
  TIPROXY: 'TIPROXY',
} as const;
