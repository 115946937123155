import React from 'react'
import { Typography } from 'antd'

export default function NetworkErrorContent() {
  return (
    <>
      Seems that your network is not configured correctly. You can read our{' '}
      <Typography.Link
        href="https://docs.google.com/document/d/13uhiVZEfQiwuhRkfrq4_vsR7erb3RNAgIv3p_jibmFc/edit"
        target="_blank"
      >
        User Manual
      </Typography.Link>{' '}
      for more details.
    </>
  )
}
