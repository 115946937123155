import { CloudConfigControllerListDefaultConfigurationChangesContainItemType } from 'apiClient/services/devops/interface'

import ChangesTable from '../Template/DefaultTemplate/ChangesTable'

function ReleaseHistories() {
  return (
    <div>
      <h3>History</h3>
      <ChangesTable
        type={
          CloudConfigControllerListDefaultConfigurationChangesContainItemType.version_release
        }
      ></ChangesTable>
    </div>
  )
}

export default ReleaseHistories
