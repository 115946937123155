import { Button, Card, Divider, Form, Input, Modal, Space, Table } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { ColumnsType } from 'antd/lib/table'
import Title from 'antd/lib/typography/Title'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  cloudConfigControllerCreateDefaultConfigurationChangeRelease,
  cloudConfigControllerListDefaultConfigurationChangeReleases,
  cloudConfigControllerListUnreleasedDefaultConfigurationChangeItems,
  cloudConfigControllerReleaseVersion
} from 'apiClient/services/devops'
import {
  CloudConfigControllerListUnreleasedDefaultConfigurationChangeItemsTypeIs,
  CloudconfigDefaultComponentChange,
  CloudconfigDefaultConfigurationChangeItem,
  CloudconfigDefaultConfigurationChangeItemType,
  CloudconfigDefaultConfigurationChangePhase,
  CloudconfigDefaultConfigurationChangeRelease
} from 'apiClient/services/devops/interface'
import { toLocal } from 'pages/Devops/Maintenance/Detail'

import { columnsImageModify } from './DefaultImageModify'
import TemplateModifyTable from './Table/TemplateModifyTable'

function EnvironmentRelease({
  environment,
  phase,
  change_items
}: {
  environment: string
  phase?: CloudconfigDefaultConfigurationChangePhase
  change_items?: CloudconfigDefaultConfigurationChangeItemType[]
}) {
  const { change_id } = useParams<{ change_id: string }>()
  const [templateItems, setTemplateItems] = useState<
    CloudconfigDefaultConfigurationChangeItem[]
  >([])
  const [imageItems, setImageItems] = useState<
    CloudconfigDefaultConfigurationChangeItem[]
  >([])
  const [releaseInfoItems, setReleaseInfoItems] = useState<
    CloudconfigDefaultConfigurationChangeRelease[]
  >([])
  const [reload, setReload] = useState(false)
  const descriptionRef = useRef('')

  useEffect(() => {
    fetch()
  }, [reload, environment, phase])

  async function fetch() {
    try {
      const template_items_resp = (
        await cloudConfigControllerListUnreleasedDefaultConfigurationChangeItems(
          change_id,
          {
            environment_is: environment,
            type_is:
              CloudConfigControllerListUnreleasedDefaultConfigurationChangeItemsTypeIs.template_default_case_binding
          }
        )
      ).data.unreleased_items
      var image_items_resp:
        | CloudconfigDefaultConfigurationChangeItem[]
        | undefined
      if (
        change_items?.includes(
          CloudconfigDefaultConfigurationChangeItemType.default_component_config
        )
      ) {
        image_items_resp = (
          await cloudConfigControllerListUnreleasedDefaultConfigurationChangeItems(
            change_id,
            {
              environment_is: environment,
              type_is:
                CloudConfigControllerListUnreleasedDefaultConfigurationChangeItemsTypeIs.default_component_config
            }
          )
        ).data.unreleased_items
      }
      const release_info_resp = (
        await cloudConfigControllerListDefaultConfigurationChangeReleases(
          change_id,
          { environment_is: environment }
        )
      ).data.items
      setTemplateItems(template_items_resp || [])
      setImageItems(image_items_resp || [])
      setReleaseInfoItems(release_info_resp || [])
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const columnsReleaseInfo: ColumnsType<CloudconfigDefaultConfigurationChangeRelease> =
    [
      {
        title: 'Description',
        render: (_: any, record) => {
          return record.description
        }
      },
      {
        title: 'Phase',
        render: (_: any, record) => {
          return record.phase
        }
      },
      {
        title: 'Release Number',
        render: (_: any, record) => {
          return record.release_number
        }
      },
      {
        title: 'Release At',
        render: (_: any, record) => {
          return toLocal(record.released_at)
        }
      },
      {
        title: 'Action',
        render: (_: any, record) => {
          return (
            <div>
              <Button type="link">
                <Link
                  target="_blank"
                  to={`/defaultconfig/change/${change_id}/release/${record.id}`}
                >
                  Detail
                </Link>
              </Button>
            </div>
          )
        }
      }
    ]

  const handleRelease = () => {
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>
            Confirm to <b>release</b> to {`${environment}`}?<br></br>
            Note: The release is not performed immediately until the approval is
            granted.
          </p>
          <Form.Item label="Description">
            <TextArea
              onChange={(e) => {
                descriptionRef.current = e.target.value
              }}
            ></TextArea>
          </Form.Item>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          await cloudConfigControllerCreateDefaultConfigurationChangeRelease(
            change_id,
            {
              environment: environment,
              description: descriptionRef.current
            }
          )
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>Successfully Create a {`${environment}`} Release</p>
              </div>
            ),
            onOk() {}
          })
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setReload((pre) => !pre)
        }
      }
    })
  }

  const [activeTabKey, setActiveTabKey] = useState<string>('history')
  const onTabChange = (key: string) => {
    setActiveTabKey(key)
  }

  const tabList = [
    {
      key: 'history',
      tab: 'Change History'
    }
  ]
  const contentList: Record<string, React.ReactNode> = {
    history: (
      <Table<CloudconfigDefaultConfigurationChangeRelease>
        columns={columnsReleaseInfo}
        dataSource={releaseInfoItems}
        size="small"
      ></Table>
    )
  }

  return (
    <div>
      <Card
        title={<Title level={4}>{`${environment} Release`}</Title>}
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={handleRelease}
              // disabled={(templateItems.length === 0) || imageItems.length === 0}
              disabled={
                phase === CloudconfigDefaultConfigurationChangePhase.success ||
                phase === CloudconfigDefaultConfigurationChangePhase.terminated
              }
            >
              Release to {`${environment}`}
            </Button>
          </Space>
        }
      >
        <h3>Unreleased Default Templates:</h3>
        <TemplateModifyTable
          scope={templateItems.map((v) => {
            return v.data!.template_default_case_binding!
          })}
        ></TemplateModifyTable>
        <Divider></Divider>
        {change_items?.includes(
          CloudconfigDefaultConfigurationChangeItemType.default_component_config
        ) ? (
          <>
            <h3>Unreleased Default Images:</h3>
            <Table<CloudconfigDefaultComponentChange>
              columns={columnsImageModify}
              dataSource={imageItems.map((v) => {
                return v.data!.default_component_config!
              })}
              size="small"
            />
          </>
        ) : (
          <></>
        )}
        <Divider></Divider>
        <Card
          tabList={tabList}
          tabProps={{ size: 'middle' }}
          bordered={false}
          activeTabKey={activeTabKey}
          onTabChange={(key) => {
            onTabChange(key)
          }}
        >
          {contentList[activeTabKey]}
        </Card>
      </Card>
    </div>
  )
}
export default EnvironmentRelease
