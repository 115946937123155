/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudconfigDefaultConfigurationChangePhase = typeof CloudconfigDefaultConfigurationChangePhase[keyof typeof CloudconfigDefaultConfigurationChangePhase];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudconfigDefaultConfigurationChangePhase = {
  in_progress: 'in_progress',
  success: 'success',
  terminated: 'terminated',
} as const;
