import { UserOutlined, FileTextOutlined } from '@ant-design/icons'
import { Avatar, Col, Dropdown, Layout, Row, Space, Typography } from 'antd'
import React from 'react'
import useProfileContext from 'useProfileContext'

import AccountMenu from '../AccountMenu'

const { Header } = Layout

export default React.memo(() => {
  const profileContext = useProfileContext()

  const handleVisibleChange = (visible: boolean) => {
    if (visible) {
      profileContext.fetch()
    }
  }

  return (
    <Header className="app-header">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <h1 className="title">Management Portal</h1>
        </Col>
        <Col span={16}>
          <div className="align-right">
            <Space size="middle">
              <Typography.Link
                href="https://pingcap.feishu.cn/wiki/AAeRwoPrWidj0yku4iWcVUsCnMf"
                target="_blank"
              >
                <FileTextOutlined /> User Manual
              </Typography.Link>
              <Dropdown
                overlay={AccountMenu}
                placement="bottomRight"
                onVisibleChange={handleVisibleChange}
              >
                <Avatar className="pointer" icon={<UserOutlined />} />
              </Dropdown>
            </Space>
          </div>
        </Col>
      </Row>
    </Header>
  )
})
