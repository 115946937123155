import { Form, Input, Select } from 'antd'

export type NewVersionFormType = {
  source_version: string
  target_version: string
}

function NewVersionForm({ versions }: { versions: string[] }) {
  return (
    <>
      <Form.Item label={'Source Version'} name={'source_version'}>
        <Select
          showSearch
          optionFilterProp="label"
          options={versions.map((v) => {
            return { label: v, value: v }
          })}
        ></Select>
      </Form.Item>
      <Form.Item label={'Target Version'} name={'target_version'}>
        <Input></Input>
      </Form.Item>
    </>
  )
}

export default NewVersionForm
