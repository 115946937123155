/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudconfigDefaultConfigurationChangeReleasePhase = typeof CloudconfigDefaultConfigurationChangeReleasePhase[keyof typeof CloudconfigDefaultConfigurationChangeReleasePhase];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudconfigDefaultConfigurationChangeReleasePhase = {
  approving: 'approving',
  released: 'released',
  rollbacked: 'rollbacked',
  cancelled: 'cancelled',
} as const;
