import { Button, Group, NumberInput, Stack, Typography } from '@tidbcloud/uikit'
import dayjs from 'dayjs'
import { useState } from 'react'

import {
  useGetApiV1DevopsBillingProxyApiV1BillingFxRates,
  usePostApiV1DevopsBillingProxyApiV1BillingFxRates
} from 'apiClient/services/billing'
import DatePicker from 'components/DatePicker'

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'

export const Rate = () => {
  const [rate, setRate] = useState(0)
  const [date, setDate] = useState(dayjs())
  const { isLoading } = useGetApiV1DevopsBillingProxyApiV1BillingFxRates(
    {
      base: 'usd',
      target: 'sgd'
    },
    {
      query: {
        refetchOnWindowFocus: false,
        onSuccess: (d) => {
          const _data = d.data
          setRate(parseFloat(_data?.value || '0'))
          setDate(dayjs(_data.date))
        }
      }
    }
  )
  const { mutateAsync: updateRate, isLoading: isUpdating } =
    usePostApiV1DevopsBillingProxyApiV1BillingFxRates()
  const disabled = isLoading || isUpdating

  return (
    <Stack>
      <Typography variant="title-lg">Set Currency Exchange Rate</Typography>
      <Group>
        <Typography variant="label-md">{'USD -> SGD'}</Typography>
        <NumberInput
          value={rate}
          onChange={(v) => setRate(v || 0)}
          disabled={disabled}
          precision={4}
          min={0.0001}
        />
        <DatePicker
          disabled={disabled}
          value={date}
          onChange={(val) => setDate(val!)}
          allowClear={false}
          disabledDate={(current) => {
            return current && current > dayjs().endOf('day')
          }}
        />
        <Button
          onClick={() =>
            updateRate({
              data: {
                base: 'usd',
                target: 'sgd',
                value: rate.toFixed(4),
                date: date.startOf('day').format(DATE_FORMAT)
              }
            })
          }
          disabled={disabled || !rate}
        >
          Save
        </Button>
      </Group>
    </Stack>
  )
}
