/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ReleaseChangeResult = typeof ReleaseChangeResult[keyof typeof ReleaseChangeResult];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReleaseChangeResult = {
  cancel: 'cancel',
  successful: 'successful',
  failed: 'failed',
  failed_partially: 'failed_partially',
  rollback: 'rollback',
} as const;
