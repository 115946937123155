import { Button, Card, FormInstance, Modal, Space, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  cloudConfigControllerBatchUpdateDefaultConfigurationChangeItems,
  cloudConfigControllerListDefaultConfigurationChangeItems
} from 'apiClient/services/devops'
import {
  CloudconfigBatchUpdateDefaultConfigurationChangeItemsReq,
  CloudconfigDefaultComponentChange,
  CloudconfigDefaultConfigurationChangeItemType,
  CloudconfigDefaultConfigurationChangePhase
} from 'apiClient/services/devops/interface'
import ModalForm from 'components/ModalForm'

import EditImageForm, { EditImageFormType } from './Form/EditImageForm'

export const columnsImageModify: ColumnsType<CloudconfigDefaultComponentChange> =
  [
    {
      title: 'Component',
      render: (_: any, record) => {
        return record.origin?.default_case?.component
      }
    },
    {
      title: 'Source Tag',
      render: (_: any, record) => {
        return record.origin?.image_tag
      }
    },
    {
      title: 'Current Tag',
      render: (_: any, record) => {
        return record.modified?.image_tag
      }
    }
  ]

function DefaultImageModify({
  reload,
  setReload,
  phase
}: {
  reload: boolean
  setReload: React.Dispatch<React.SetStateAction<boolean>>
  phase?: CloudconfigDefaultConfigurationChangePhase
}) {
  const { change_id } = useParams<{ change_id: string }>()
  const [change, setChange] = useState<CloudconfigDefaultComponentChange[]>()
  const [modalEdit, setModalEdit] = useState(false)
  useEffect(() => {
    fetch()
  }, [reload])
  async function fetch() {
    try {
      const chg = (
        await cloudConfigControllerListDefaultConfigurationChangeItems(
          change_id
        )
      ).data
        .items!.filter((v) => {
          return (
            v.type ===
            CloudconfigDefaultConfigurationChangeItemType.default_component_config
          )
        })
        .map((v) => v.data!.default_component_config!)
      setChange(chg)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const renderFormFields = useCallback(
    (form: FormInstance) => {
      change?.map((v) => {
        form.setFieldsValue({
          [v.origin!.default_case!.component!]: v.modified?.image_tag
        })
      })

      return <EditImageForm form={form} change={change} />
    },
    [modalEdit]
  )

  const handleClose = async (payload: EditImageFormType) => {
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>Are you sure to change these images?</p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          Object.keys(payload).map((key) => {
            change?.map((c) => {
              if (c.origin?.default_case?.component === key) {
                c.modified!.image_tag = payload[key]
              }
            })
          })
          const req: CloudconfigBatchUpdateDefaultConfigurationChangeItemsReq =
            {
              batch_update_component_configs: {
                components: change?.map((v) => {
                  return {
                    default_case: v.origin?.default_case,
                    image_tag: payload[v.origin!.default_case!.component!]
                  }
                })
              }
            }
          await cloudConfigControllerBatchUpdateDefaultConfigurationChangeItems(
            change_id,
            req
          )
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>Successfully Change the images</p>
              </div>
            ),
            onOk() {}
          })
          setModalEdit(false)
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setModalEdit(false)
          setReload((pre) => !pre)
        }
      }
    })
  }

  return (
    <div>
      <Card
        title={'Modify Images'}
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setModalEdit(true)
              }}
              disabled={
                phase === CloudconfigDefaultConfigurationChangePhase.success ||
                phase === CloudconfigDefaultConfigurationChangePhase.terminated
              }
            >
              Modify
            </Button>
          </Space>
        }
      >
        <Table<CloudconfigDefaultComponentChange>
          columns={columnsImageModify}
          dataSource={change}
          size="small"
          pagination={false}
        />
      </Card>

      <ModalForm<EditImageFormType>
        visible={!!modalEdit}
        name="close"
        title={'Close Template Modification'}
        getFields={renderFormFields}
        onSubmit={handleClose}
        okText="Commit"
        onCancel={() => {
          setModalEdit(false)
        }}
      />
    </div>
  )
}

export default DefaultImageModify
