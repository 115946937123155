/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type CloudconfigParameterSource = typeof CloudconfigParameterSource[keyof typeof CloudconfigParameterSource];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CloudconfigParameterSource = {
  custom: 'custom',
  template: 'template',
  kernel_default: 'kernel default',
} as const;
