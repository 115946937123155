import React, {
  FC,
  useEffect,
  useState,
  createContext,
  useContext
} from 'react'

import { currentUserProfile } from 'apiClient/services/devops'
import { UserUserProfileResp } from 'apiClient/services/devops/interface'
import { PageLoader } from 'components/Loader'

interface IState {
  profile: UserUserProfileResp
  fetch: () => void
}

const UserProfileContext = createContext<IState | undefined>({
  profile: {},
  fetch: () => {}
})

const useProfileContext = () => {
  const context = useContext(UserProfileContext)
  if (context === undefined) {
    throw new Error('useProfileContext must be used within a ListProvider')
  }
  return context
}

export const UserProfileContextProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [profile, setProfile] = useState<UserUserProfileResp>({})

  async function fetch() {
    const data = (
      await currentUserProfile({
        isShowMessage: true
      })
    ).data
    setProfile(data)
  }

  useEffect(() => {
    async function init() {
      await fetch()
      setLoading(false)
    }

    init()
  }, [])

  return (
    <UserProfileContext.Provider value={{ profile, fetch }}>
      {loading ? <PageLoader tip="Please wait..." /> : children}
    </UserProfileContext.Provider>
  )
}

export default useProfileContext
