function awsUrl(region: string, project: string): string {
  return `https://${region}.${project}.shared.aws.tidbcloud.com`
}

const _backend = localStorage.getItem('dbaasUrl')
const _dbaasUrl = awsUrl('us-west-2', 'nightly')
export const dbaasUrl: string =
  _backend || process.env.REACT_APP_SERVICE_URL || _dbaasUrl

type HostEnv = 'localhost' | 'dev' | 'prod'
export const hostEnv: HostEnv = window.location.host.match(/localhost/)
  ? 'localhost'
  : window.location.host.match(/dev/)
  ? 'dev'
  : 'prod'
