import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { CloudconfigNamedParameter } from 'apiClient/services/devops/interface'
import { convertToString } from 'pages/Tenants/ClusterDetail/Configs/Forms/PreviewForm'

function ExpandTemplateTable({
  params
}: {
  params: CloudconfigNamedParameter[]
}) {
  const columns: ColumnsType<CloudconfigNamedParameter> = [
    {
      title: 'Name',
      render: (_: any, record) => {
        return record.name
      }
    },
    {
      title: 'Value',
      render: (_: any, record) => {
        return convertToString(record.value)
      }
    }
  ]
  return <Table columns={columns} dataSource={params} size="small"></Table>
}

export default ExpandTemplateTable
