import {
  Card,
  Typography,
  Descriptions,
  Divider,
  Table,
  Button,
  Modal,
  Input,
  notification
} from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import {
  deleteCustomer,
  useGetMspByOrgId,
  useGetCustomersByPartnerId,
  useGetSignupUrl,
  useGetTenantByID
} from 'apiClient/services/account'
import { FormsPartnerCustomer } from 'apiClient/services/account/interface'
import ModalForm from 'components/ModalForm'
import { ListToolBar } from 'components/Table'
import { useClipboard } from 'utils/dom'
import { getErrorMessage } from 'utils/error'
import { formatTime } from 'utils/time'

import { CreateCPPOCustomer } from './Create'
import { CreateOrUpdateCPPO } from './Create/CPPO'

const { Title } = Typography

export const CPPOQuit: React.FC<{
  customerId: string
  mspName: string
  onDelete?: () => void
}> = ({ onDelete, customerId, mspName }) => {
  const [visible, setVisible] = useState(false)
  const onSubmit = async (data: FormsPartnerCustomer) => {
    await deleteCustomer({
      ...data,
      partner_type: 'CPPO',
      customer_org_id: customerId
    })
    setVisible(false)
    onDelete?.()
  }

  const renderFields = () => (
    <>
      <p>Do you confirm to quit MSP customer name from {mspName}?</p>
      <p>
        The MSP customer organization will become a direct organization of TiDB
        Cloud after quit.
      </p>
    </>
  )

  return (
    <div>
      <Button type="link" onClick={() => setVisible(true)}>
        Quit
      </Button>
      <ModalForm
        title="Quit MSP"
        visible={visible}
        name="delete-customer-form"
        onSubmit={onSubmit}
        onCancel={() => setVisible(false)}
        destoryOnClose={true}
        getFields={renderFields}
      ></ModalForm>
    </div>
  )
}

export const CPPODetails: React.FC<{}> = () => {
  const { org_id } = useParams<{ org_id: string }>()
  const { data } = useGetTenantByID(org_id, {
    query: { refetchOnWindowFocus: false, refetchInterval: false }
  })
  const [pageConfig, setPageConfig] = useState({
    page: 1,
    pageSize: 10
  })

  const {
    data: customers,
    refetch,
    isLoading
  } = useGetCustomersByPartnerId(
    org_id,
    {
      page: pageConfig.page,
      per_page: pageConfig.pageSize
    },
    {
      query: { refetchOnWindowFocus: false, refetchInterval: false }
    }
  )

  const columns: ColumnsType<FormsPartnerCustomer> = [
    {
      title: 'ID',
      dataIndex: 'customer_org_id',
      render(value) {
        return <Link to={`/orgs/${value}`}>{value}</Link>
      }
    },
    {
      title: 'CPPO Customer',
      dataIndex: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render(value) {
        return capitalize(value)
      }
    },
    {
      title: 'Effective At',
      dataIndex: 'effected_at',
      render(value) {
        return formatTime(value, undefined, 'YYYY-MM-DD HH:mm:ssZ', 0)
      }
    },
    {
      title: 'Expiration At',
      dataIndex: 'expired_at',
      render(value) {
        if (!value) {
          return '-'
        }
        return formatTime(value, undefined, 'YYYY-MM-DD HH:mm:ssZ', 0)
      }
    }
    // {
    //   title: 'Action',
    //   fixed: 'right',
    //   width: 100,
    //   dataIndex: 'action',
    //   render: (value, record) => {
    //     const effectedAt = dayjs(record.effected_at).unix()
    //     const now = dayjs()
    //     if (
    //       effectedAt > now.unix() &&
    //       effectedAt <= now.add(1, 'hour').unix()
    //     ) {
    //       return ''
    //     }
    //     return (
    //       <MSPQuit
    //         key={record.customer_org_id || ''}
    //         customerId={record.customer_org_id || ''}
    //         mspName={data?.data.name || ''}
    //         onDelete={() => {
    //           setPageConfig({ page: 1, pageSize: 10 })
    //           refetch()
    //         }}
    //       />
    //     )
    //   }
    // }
  ]

  const partner = data?.data || {}

  return (
    <div>
      <Card
        title={<Title level={4}>{partner.name}</Title>}
        bordered={false}
        // extra={
        //   <div style={{ display: 'flex', gap: 8 }}>
        //     <CreateOrUpdateCPPO
        //       onComplete={() => {
        //         refetchMSP()
        //       }}
        //       actionText="Update CPPO"
        //       title="Update CPPO"
        //       action="update"
        //       initValues={{
        //         ...msp
        //       }}
        //     />
        //   </div>
        // }
      >
        <Descriptions>
          <Descriptions.Item label="ID">
            <Link to={`/orgs/${partner.id}`}>{partner.id}</Link>
          </Descriptions.Item>
          <Descriptions.Item label="Email">{partner.email}</Descriptions.Item>
        </Descriptions>
      </Card>
      <Divider></Divider>
      <div>
        <Title level={5}>CPPO Customers</Title>
        <ListToolBar
          reload={async () => {
            await refetch()
          }}
          actions={[
            <CreateCPPOCustomer
              partner_org_id={org_id}
              onCreate={() => {
                refetch()
              }}
            />
          ]}
        />
      </div>
      <Table
        columns={columns}
        dataSource={customers?.data || []}
        loading={isLoading}
      ></Table>
    </div>
  )
}

export default CPPODetails
