/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type EntityApplyStrategy = typeof EntityApplyStrategy[keyof typeof EntityApplyStrategy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityApplyStrategy = {
  HotReload: 'HotReload',
  RollingRestart: 'RollingRestart',
  ChangeCMOnly: 'ChangeCMOnly',
  EnsureUpdateToTC: 'EnsureUpdateToTC',
} as const;
