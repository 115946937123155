import { Button, Form, Input, Steps, Space } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { useState } from 'react'

import { createCppoOrg } from 'apiClient/services/account'
import { FormsCppoOrgReq } from 'apiClient/services/account/interface'
import ModalForm from 'components/ModalForm'

const { Step } = Steps

const CREATE_STEPS = {
  INITIAL: 0,
  CREATE: 1,
  DONE: 2
} as const

const CPPOPreview: React.FC<{ data: FormsCppoOrgReq }> = ({ data }) => {
  const style: React.CSSProperties = {
    width: 200,
    display: 'inline-block',
    textAlign: 'right'
  }

  return (
    <Space direction="vertical">
      <Space>
        <span style={style}>Name: </span>
        <span>{data.name}</span>
      </Space>
      <Space>
        <span style={style}>Contact Email: </span>
        <span>{data.email}</span>
      </Space>
    </Space>
  )
}

export const CreateOrUpdateCPPO: React.FC<{
  onComplete?: () => void
  action: 'add' | 'update'
  initValues?: FormsCppoOrgReq
  title?: string
  actionText?: string
}> = ({
  onComplete,
  action = 'add',
  initValues,
  title = 'Create AWS CPPO Reseller',
  actionText = 'Create'
}) => {
  const [visible, setVisible] = useState(false)
  const [steps, setSteps] = useState<number>(CREATE_STEPS.INITIAL)
  const [value, setValue] = useState<FormsCppoOrgReq | null>(null)

  const onSubmit = async (data: FormsCppoOrgReq) => {
    if (steps === CREATE_STEPS.INITIAL) {
      setValue(data)
      setSteps(CREATE_STEPS.CREATE)
      return
    }

    if (steps === CREATE_STEPS.CREATE && value) {
      if (action === 'add') {
        const res = await createCppoOrg(value)
        setSteps(CREATE_STEPS.DONE)
        return
      } else {
        // await updateMspOrg({
        //   org_id: initValues?.org_id,
        //   logo_name: value.logo_name || undefined,
        //   website: value.website,
        //   disclaimers: value.disclaimers,
        //   advertising_arr: value.advertising_arr
        // })
      }
    }
    onComplete?.()
    onClose(true)
  }

  const onClose = (close = false) => {
    if (!close && steps === CREATE_STEPS.CREATE) {
      setSteps(CREATE_STEPS.INITIAL)
      return
    }
    setValue(null)
    setSteps(CREATE_STEPS.INITIAL)
    setVisible(false)
  }

  const readOnly = action === 'update'

  const renderFields = (form: FormInstance) => {
    return (
      <>
        <Steps current={steps} size="small" style={{ marginBottom: 16 }}>
          <Step title="Configure Details" />
          <Step title="Confirm" />
          {action === 'add' && <Step title="Done" />}
        </Steps>
        {steps === CREATE_STEPS.INITIAL && (
          <>
            <Form.Item
              name="name"
              label="Name"
              required
              rules={[{ required: true, message: 'Required' }]}
            >
              <Input disabled={readOnly} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Contact Email"
              required
              rules={[
                { required: true, type: 'email', message: 'Invalid Email' }
              ]}
            >
              <Input type="email" disabled={readOnly} />
            </Form.Item>
          </>
        )}
        {steps === CREATE_STEPS.CREATE && !!value && (
          <CPPOPreview data={{ ...value }}></CPPOPreview>
        )}
      </>
    )
  }

  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        {actionText}
      </Button>
      <ModalForm
        width={750}
        initValues={initValues}
        name="create-cppo-form"
        title={title}
        visible={visible}
        onSubmit={onSubmit}
        onCancel={() => onClose(false)}
        getFields={renderFields}
        cancelText={steps === CREATE_STEPS.CREATE ? 'Prev' : 'Cancel'}
        okText={steps === CREATE_STEPS.INITIAL ? 'Next' : 'Confirm'}
        destoryOnClose
      ></ModalForm>
    </div>
  )
}
