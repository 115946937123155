import { Button, Col, Row, Typography, notification } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'

import { generateInitCRTable } from 'apiClient/services/billing'
import { DatePicker } from 'components/DatePicker'
import { downloadCSV } from 'utils/dom'

export const ARTable = () => {
  const defaultMonth = dayjs().subtract(1, 'month')
  const [selectedMonth, setSelectedMonth] = useState({
    year: defaultMonth.get('year'),
    // start from 0
    month: defaultMonth.get('month') + 1
  })

  const [loading, setLoading] = useState(false)

  const onMonthChange = (date: Dayjs) => {
    if (!date) {
      return
    }
    const year = date.get('year')
    const month = date.get('month') + 1
    setSelectedMonth({
      year,
      month
    })
  }

  const onDownload = async () => {
    try {
      setLoading(true)
      const data = await generateInitCRTable({
        year: selectedMonth.year,
        month: selectedMonth.month
      })
      downloadCSV(
        data.data as any as string,
        `CRTable-${selectedMonth.year}-${selectedMonth.month}`
      )
    } catch (e) {
      notification.error({ message: e?.message || e?.response?.data?.message })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Typography.Title level={5}>Download Init AR Table</Typography.Title>
      <Row>
        <DatePicker
          disabled={loading}
          defaultValue={defaultMonth}
          picker="month"
          disabledDate={(date) => {
            return date > defaultMonth ? true : false
          }}
          onChange={onMonthChange}
        ></DatePicker>
        <Button type="link" loading={loading} onClick={onDownload}>
          Download
        </Button>
      </Row>
    </div>
  )
}
