/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Management Portal API
 * This is a management portal server
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  FormsListAccessLogResp,
  GetAccessLogsParams,
  FormsCalcNodeProfileResp,
  FormsCalcNodeProfilesReq,
  FormsCreateAPIKeyResp,
  FormsCreateAPIKey,
  FormsGetDashboardStatsResq,
  FormsCentralUserProfile,
  FormsListTenantResp,
  ListTenantsParams,
  FormsTenant,
  FormsEmptyResponse,
  FormsPatchTenantReq,
  GetApiV1AdminOrgsOrgIdAccessLogsParams,
  FormsListTenantClusterLifeCycleResp,
  GetApiV1AdminOrgsOrgIdClusterLifecyclesParams,
  FormsListTenantClustersResp,
  ListTenantClustersParams,
  FormsScaleComponentReq,
  FormsGetDashboardLinksResp,
  FormsTenantRemainingCredits,
  FormsAddTrailCredit,
  FormsListTenantUsersResp,
  FormsTenantUser,
  FormsListProvidersResp,
  FormsProvider,
  FormsListRegionResp,
  FormsProviderRegion,
  FormsProviderRegionBody,
  FormsListRolesResp,
  FormsRole,
  FormsSystemSettings,
  FormsListCentralUsersResp,
  ListCentralUsersParams,
  FormsCentralUser,
  FormsCostExplorerResult,
  FormsCostExplorerQuery,
  FormsCostExplorerDetailsResult,
  ApiHttpBody,
  FormsMonthlycostExplorerResult,
  FormsCreditRiskLevel,
  FormsCreditRiskLevelInputBody,
  FormsOKType,
  FormsUnitFilterNode,
  FormsUnitFilterSlim,
  FormsCostQuota,
  FormsCostQuotaBody,
  FormsTenantClosePolicy,
  FormsTenantClosePolicyInput,
  FormsCreditTypeNode,
  FormsCreditTypeSlim,
  FormsCreditTypeBody,
  FormsCreditType,
  FormsTenantCloseInfo,
  FormsCreditRiskLevelIDInput,
  FormsDiscount,
  FormsDiscountBody,
  FormsInvoice,
  FormsUnitCosts,
  FormsUnitCostsInputBody,
  FormsUnitInfo,
  FormsBillingCompany,
  FormsTenantBillingCompany,
  FormsTenantBillingCompanyInput,
  FormsDiscountInfo,
  FormsDiscountInfoBody,
  FormsTenantPlanInfo,
  FormsCtlResp,
  FormsCtlReq,
  FormsGetNetworkTopologyResp,
  FormsDiffConfigChangeResp,
  GetConfigChangeDiffParams,
  FormsListConfigChangeResp,
  ListConfigChangesParams,
  FormsApplyConfigChangeReq,
  FormsListMaintenancesResp,
  ListClusterMaintenancesParams,
  FormsCreateMaintenanceResp,
  FormsCreateMaintenanceReq,
  FormsGetMaintenanceResp,
  GetClusterMaintenanceParams,
  FormsListReleaseResp,
  ListReleasesParams,
  ListDeployments200,
  ListDeploymentsParams,
  FormsGetReleaseResp,
  GetReleaseParams,
  FormsInitChangeResp,
  InitChangeParams,
  FormsGetDeploymentResp,
  GetDeploymentParams,
  FormsUpdateDeploymentResp,
  FormsPostDeploymentReqBody,
  PostDeployment200,
  FormsDeployPrResp,
  FormsDeployPrReq,
  FormsIsOrderReadyResp,
  FormsVerifyDeployResp,
  FormsVerifyDeployReq,
  FormsCancelChangeResp,
  FormsSubmitChangeResp,
  FormsCloseChangeResp,
  FormsCloseChangeReq,
  FormsGetApplicationsResp,
  FormsGetAuditLogResp,
  GetAuditLogsParams,
  GetAuditMaintenanceLogsParams,
  FormsCertificate,
  ListCertificatesParams,
  FormsListIncidents,
  ApiBaseResp,
  IncidentCreateIncidentReq,
  FormsIncident,
  IncidentUpdateIncidentReq,
  FormsListIncidentLogItems,
  ApiEmptyResp,
  FormsIncidentLogItemBody,
  FormsIncidentLogItem,
  IncidentListIncidentPostMortemToDoActionResp,
  IncidentGetIncidentPostMortemToDoActionResp
} from './interface'
import { customInstance } from '../axios';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Serach Management Portal access logs
 */
export const getAccessLogs = (
    params?: GetAccessLogsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListAccessLogResp>(
      {url: `/api/v1/admin/access-logs`, method: 'get',
        params
    },
      options);
    }
  

export const getGetAccessLogsQueryKey = (params?: GetAccessLogsParams,) => [`/api/v1/admin/access-logs`, ...(params ? [params]: [])] as const;
  

    
export const getGetAccessLogsQueryOptions = <TData = Awaited<ReturnType<typeof getAccessLogs>>, TError = unknown>(params?: GetAccessLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccessLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getAccessLogs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAccessLogsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccessLogs>>> = () => getAccessLogs(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetAccessLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccessLogs>>>
export type GetAccessLogsQueryError = unknown

/**
 * @summary Serach Management Portal access logs
 */
export const useGetAccessLogs = <TData = Awaited<ReturnType<typeof getAccessLogs>>, TError = unknown>(
 params?: GetAccessLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccessLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAccessLogsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * CalcNodeProfiles
 * @summary CalcNodeProfiles
 */
export const calcNodeProfiles = (
    formsCalcNodeProfilesReq: FormsCalcNodeProfilesReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCalcNodeProfileResp>(
      {url: `/api/v1/admin/profile/query`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCalcNodeProfilesReq
    },
      options);
    }
  


export const getCalcNodeProfilesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof calcNodeProfiles>>, TError,{data: FormsCalcNodeProfilesReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof calcNodeProfiles>>, TError,{data: FormsCalcNodeProfilesReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof calcNodeProfiles>>, {data: FormsCalcNodeProfilesReq}> = (props) => {
          const {data} = props ?? {};

          return  calcNodeProfiles(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CalcNodeProfilesMutationResult = NonNullable<Awaited<ReturnType<typeof calcNodeProfiles>>>
    export type CalcNodeProfilesMutationBody = FormsCalcNodeProfilesReq
    export type CalcNodeProfilesMutationError = unknown

    /**
 * @summary CalcNodeProfiles
 */
export const useCalcNodeProfiles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof calcNodeProfiles>>, TError,{data: FormsCalcNodeProfilesReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCalcNodeProfilesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Add API Key
 */
export const postApiV1AdminApiKey = (
    formsCreateAPIKey: FormsCreateAPIKey,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreateAPIKeyResp>(
      {url: `/api/v1/admin/api_key`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreateAPIKey
    },
      options);
    }
  


export const getPostApiV1AdminApiKeyMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminApiKey>>, TError,{data: FormsCreateAPIKey}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminApiKey>>, TError,{data: FormsCreateAPIKey}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminApiKey>>, {data: FormsCreateAPIKey}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminApiKey(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminApiKeyMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminApiKey>>>
    export type PostApiV1AdminApiKeyMutationBody = FormsCreateAPIKey
    export type PostApiV1AdminApiKeyMutationError = unknown

    /**
 * @summary Add API Key
 */
export const usePostApiV1AdminApiKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminApiKey>>, TError,{data: FormsCreateAPIKey}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminApiKeyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetDashboardStats
 */
export const getDashboardStats = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetDashboardStatsResq>(
      {url: `/api/v1/admin/dashboard/stats`, method: 'get'
    },
      options);
    }
  

export const getGetDashboardStatsQueryKey = () => [`/api/v1/admin/dashboard/stats`] as const;
  

    
export const getGetDashboardStatsQueryOptions = <TData = Awaited<ReturnType<typeof getDashboardStats>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getDashboardStats>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDashboardStatsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboardStats>>> = () => getDashboardStats(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetDashboardStatsQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboardStats>>>
export type GetDashboardStatsQueryError = unknown

/**
 * @summary GetDashboardStats
 */
export const useGetDashboardStats = <TData = Awaited<ReturnType<typeof getDashboardStats>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDashboardStatsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary GetCentralUserProfile
 */
export const getCentralUserProfile = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCentralUserProfile>(
      {url: `/api/v1/admin/me`, method: 'get'
    },
      options);
    }
  

export const getGetCentralUserProfileQueryKey = () => [`/api/v1/admin/me`] as const;
  

    
export const getGetCentralUserProfileQueryOptions = <TData = Awaited<ReturnType<typeof getCentralUserProfile>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCentralUserProfile>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCentralUserProfile>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCentralUserProfileQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCentralUserProfile>>> = () => getCentralUserProfile(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetCentralUserProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getCentralUserProfile>>>
export type GetCentralUserProfileQueryError = unknown

/**
 * @summary GetCentralUserProfile
 */
export const useGetCentralUserProfile = <TData = Awaited<ReturnType<typeof getCentralUserProfile>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCentralUserProfile>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCentralUserProfileQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Tenant
 */
export const listTenants = (
    params?: ListTenantsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListTenantResp>(
      {url: `/api/v1/admin/orgs`, method: 'get',
        params
    },
      options);
    }
  

export const getListTenantsQueryKey = (params?: ListTenantsParams,) => [`/api/v1/admin/orgs`, ...(params ? [params]: [])] as const;
  

    
export const getListTenantsQueryOptions = <TData = Awaited<ReturnType<typeof listTenants>>, TError = unknown>(params?: ListTenantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenants>>> = () => listTenants(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenants>>>
export type ListTenantsQueryError = unknown

/**
 * @summary List Tenant
 */
export const useListTenants = <TData = Awaited<ReturnType<typeof listTenants>>, TError = unknown>(
 params?: ListTenantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Tenant By ID
 */
export const getTenantByID = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenant>(
      {url: `/api/v1/admin/orgs/${orgId}`, method: 'get'
    },
      options);
    }
  

export const getGetTenantByIDQueryKey = (orgId: string,) => [`/api/v1/admin/orgs/${orgId}`] as const;
  

    
export const getGetTenantByIDQueryOptions = <TData = Awaited<ReturnType<typeof getTenantByID>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantByID>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantByID>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantByIDQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantByID>>> = () => getTenantByID(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetTenantByIDQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantByID>>>
export type GetTenantByIDQueryError = unknown

/**
 * @summary Get Tenant By ID
 */
export const useGetTenantByID = <TData = Awaited<ReturnType<typeof getTenantByID>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantByID>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantByIDQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete Tenant By ID
 */
export const deleteTenantByID = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteTenantByIDMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTenantByID>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTenantByID>>, TError,{orgId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTenantByID>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  deleteTenantByID(orgId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteTenantByIDMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTenantByID>>>
    
    export type DeleteTenantByIDMutationError = unknown

    /**
 * @summary Delete Tenant By ID
 */
export const useDeleteTenantByID = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTenantByID>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteTenantByIDMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary PatchTenant
 */
export const patchTenant = (
    orgId: string,
    formsPatchTenantReq: FormsPatchTenantReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: formsPatchTenantReq
    },
      options);
    }
  


export const getPatchTenantMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchTenant>>, TError,{orgId: string;data: FormsPatchTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchTenant>>, TError,{orgId: string;data: FormsPatchTenantReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchTenant>>, {orgId: string;data: FormsPatchTenantReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  patchTenant(orgId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchTenantMutationResult = NonNullable<Awaited<ReturnType<typeof patchTenant>>>
    export type PatchTenantMutationBody = FormsPatchTenantReq
    export type PatchTenantMutationError = unknown

    /**
 * @summary PatchTenant
 */
export const usePatchTenant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchTenant>>, TError,{orgId: string;data: FormsPatchTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPatchTenantMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Serach Tenant access logs
 */
export const getApiV1AdminOrgsOrgIdAccessLogs = (
    orgId: string,
    params?: GetApiV1AdminOrgsOrgIdAccessLogsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListAccessLogResp>(
      {url: `/api/v1/admin/orgs/${orgId}/access-logs`, method: 'get',
        params
    },
      options);
    }
  

export const getGetApiV1AdminOrgsOrgIdAccessLogsQueryKey = (orgId: string,
    params?: GetApiV1AdminOrgsOrgIdAccessLogsParams,) => [`/api/v1/admin/orgs/${orgId}/access-logs`, ...(params ? [params]: [])] as const;
  

    
export const getGetApiV1AdminOrgsOrgIdAccessLogsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError = unknown>(orgId: string,
    params?: GetApiV1AdminOrgsOrgIdAccessLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminOrgsOrgIdAccessLogsQueryKey(orgId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>> = () => getApiV1AdminOrgsOrgIdAccessLogs(orgId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetApiV1AdminOrgsOrgIdAccessLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>>
export type GetApiV1AdminOrgsOrgIdAccessLogsQueryError = unknown

/**
 * @summary Serach Tenant access logs
 */
export const useGetApiV1AdminOrgsOrgIdAccessLogs = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError = unknown>(
 orgId: string,
    params?: GetApiV1AdminOrgsOrgIdAccessLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminOrgsOrgIdAccessLogsQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary ListTenantClusterLifeCycle
 */
export const getApiV1AdminOrgsOrgIdClusterLifecycles = (
    orgId: string,
    params: GetApiV1AdminOrgsOrgIdClusterLifecyclesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListTenantClusterLifeCycleResp>(
      {url: `/api/v1/admin/orgs/${orgId}/cluster-lifecycles`, method: 'get',
        params
    },
      options);
    }
  

export const getGetApiV1AdminOrgsOrgIdClusterLifecyclesQueryKey = (orgId: string,
    params: GetApiV1AdminOrgsOrgIdClusterLifecyclesParams,) => [`/api/v1/admin/orgs/${orgId}/cluster-lifecycles`, ...(params ? [params]: [])] as const;
  

    
export const getGetApiV1AdminOrgsOrgIdClusterLifecyclesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdClusterLifecycles>>, TError = unknown>(orgId: string,
    params: GetApiV1AdminOrgsOrgIdClusterLifecyclesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdClusterLifecycles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdClusterLifecycles>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminOrgsOrgIdClusterLifecyclesQueryKey(orgId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdClusterLifecycles>>> = () => getApiV1AdminOrgsOrgIdClusterLifecycles(orgId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetApiV1AdminOrgsOrgIdClusterLifecyclesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdClusterLifecycles>>>
export type GetApiV1AdminOrgsOrgIdClusterLifecyclesQueryError = unknown

/**
 * @summary ListTenantClusterLifeCycle
 */
export const useGetApiV1AdminOrgsOrgIdClusterLifecycles = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdClusterLifecycles>>, TError = unknown>(
 orgId: string,
    params: GetApiV1AdminOrgsOrgIdClusterLifecyclesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdClusterLifecycles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminOrgsOrgIdClusterLifecyclesQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Tenant Cluster
 */
export const listTenantClusters = (
    orgId: string,
    params?: ListTenantClustersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListTenantClustersResp>(
      {url: `/api/v1/admin/orgs/${orgId}/clusters`, method: 'get',
        params
    },
      options);
    }
  

export const getListTenantClustersQueryKey = (orgId: string,
    params?: ListTenantClustersParams,) => [`/api/v1/admin/orgs/${orgId}/clusters`, ...(params ? [params]: [])] as const;
  

    
export const getListTenantClustersQueryOptions = <TData = Awaited<ReturnType<typeof listTenantClusters>>, TError = unknown>(orgId: string,
    params?: ListTenantClustersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantClusters>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantClusters>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantClustersQueryKey(orgId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantClusters>>> = () => listTenantClusters(orgId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type ListTenantClustersQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantClusters>>>
export type ListTenantClustersQueryError = unknown

/**
 * @summary List Tenant Cluster
 */
export const useListTenantClusters = <TData = Awaited<ReturnType<typeof listTenantClusters>>, TError = unknown>(
 orgId: string,
    params?: ListTenantClustersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantClusters>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantClustersQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary DeleteTenantCluster
 */
export const deleteTenantCluster = (
    orgId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/clusters/${clusterId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteTenantClusterMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTenantCluster>>, {orgId: string;clusterId: string}> = (props) => {
          const {orgId,clusterId} = props ?? {};

          return  deleteTenantCluster(orgId,clusterId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteTenantClusterMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTenantCluster>>>
    
    export type DeleteTenantClusterMutationError = unknown

    /**
 * @summary DeleteTenantCluster
 */
export const useDeleteTenantCluster = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteTenantClusterMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Pause Tenant Cluster
 */
export const pauseTenantCluster = (
    orgId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/clusters/${clusterId}/pause`, method: 'post'
    },
      options);
    }
  


export const getPauseTenantClusterMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pauseTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof pauseTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pauseTenantCluster>>, {orgId: string;clusterId: string}> = (props) => {
          const {orgId,clusterId} = props ?? {};

          return  pauseTenantCluster(orgId,clusterId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PauseTenantClusterMutationResult = NonNullable<Awaited<ReturnType<typeof pauseTenantCluster>>>
    
    export type PauseTenantClusterMutationError = unknown

    /**
 * @summary Pause Tenant Cluster
 */
export const usePauseTenantCluster = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pauseTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPauseTenantClusterMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Resume Tenant Cluster
 */
export const resumeTenantCluster = (
    orgId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/clusters/${clusterId}/resume`, method: 'post'
    },
      options);
    }
  


export const getResumeTenantClusterMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resumeTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof resumeTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resumeTenantCluster>>, {orgId: string;clusterId: string}> = (props) => {
          const {orgId,clusterId} = props ?? {};

          return  resumeTenantCluster(orgId,clusterId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ResumeTenantClusterMutationResult = NonNullable<Awaited<ReturnType<typeof resumeTenantCluster>>>
    
    export type ResumeTenantClusterMutationError = unknown

    /**
 * @summary Resume Tenant Cluster
 */
export const useResumeTenantCluster = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resumeTenantCluster>>, TError,{orgId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getResumeTenantClusterMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Scale Tenant Cluster
 */
export const scaleTenantCluster = (
    orgId: string,
    clusterId: string,
    formsScaleComponentReq: FormsScaleComponentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/clusters/${clusterId}/components`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsScaleComponentReq
    },
      options);
    }
  


export const getScaleTenantClusterMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scaleTenantCluster>>, TError,{orgId: string;clusterId: string;data: FormsScaleComponentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof scaleTenantCluster>>, TError,{orgId: string;clusterId: string;data: FormsScaleComponentReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scaleTenantCluster>>, {orgId: string;clusterId: string;data: FormsScaleComponentReq}> = (props) => {
          const {orgId,clusterId,data} = props ?? {};

          return  scaleTenantCluster(orgId,clusterId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ScaleTenantClusterMutationResult = NonNullable<Awaited<ReturnType<typeof scaleTenantCluster>>>
    export type ScaleTenantClusterMutationBody = FormsScaleComponentReq
    export type ScaleTenantClusterMutationError = unknown

    /**
 * @summary Scale Tenant Cluster
 */
export const useScaleTenantCluster = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scaleTenantCluster>>, TError,{orgId: string;clusterId: string;data: FormsScaleComponentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getScaleTenantClusterMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * GetDashboardLinks
 * @summary GetDashboardLinks
 */
export const getDashboardLinks = (
    orgId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetDashboardLinksResp>(
      {url: `/api/v1/admin/orgs/${orgId}/clusters/${clusterId}/dashboard_links`, method: 'get'
    },
      options);
    }
  

export const getGetDashboardLinksQueryKey = (orgId: string,
    clusterId: string,) => [`/api/v1/admin/orgs/${orgId}/clusters/${clusterId}/dashboard_links`] as const;
  

    
export const getGetDashboardLinksQueryOptions = <TData = Awaited<ReturnType<typeof getDashboardLinks>>, TError = unknown>(orgId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardLinks>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getDashboardLinks>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDashboardLinksQueryKey(orgId,clusterId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboardLinks>>> = () => getDashboardLinks(orgId,clusterId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId && clusterId), ...queryOptions}}

export type GetDashboardLinksQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboardLinks>>>
export type GetDashboardLinksQueryError = unknown

/**
 * @summary GetDashboardLinks
 */
export const useGetDashboardLinks = <TData = Awaited<ReturnType<typeof getDashboardLinks>>, TError = unknown>(
 orgId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDashboardLinks>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDashboardLinksQueryOptions(orgId,clusterId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary AddImpersonation
 */
export const addImpersonation = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/impersonation`, method: 'post'
    },
      options);
    }
  


export const getAddImpersonationMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addImpersonation>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addImpersonation>>, TError,{orgId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addImpersonation>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  addImpersonation(orgId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddImpersonationMutationResult = NonNullable<Awaited<ReturnType<typeof addImpersonation>>>
    
    export type AddImpersonationMutationError = unknown

    /**
 * @summary AddImpersonation
 */
export const useAddImpersonation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addImpersonation>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddImpersonationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Impersonation
 */
export const deleteImpersonation = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/impersonation`, method: 'delete'
    },
      options);
    }
  


export const getDeleteImpersonationMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteImpersonation>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteImpersonation>>, TError,{orgId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteImpersonation>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  deleteImpersonation(orgId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteImpersonationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteImpersonation>>>
    
    export type DeleteImpersonationMutationError = unknown

    /**
 * @summary Delete Impersonation
 */
export const useDeleteImpersonation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteImpersonation>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteImpersonationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Tenant Project
 */
export const deleteApiV1AdminOrgsOrgIdProjectsProjectId = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/projects/${projectId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1AdminOrgsOrgIdProjectsProjectIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>, TError,{orgId: string;projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>, TError,{orgId: string;projectId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>, {orgId: string;projectId: string}> = (props) => {
          const {orgId,projectId} = props ?? {};

          return  deleteApiV1AdminOrgsOrgIdProjectsProjectId(orgId,projectId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1AdminOrgsOrgIdProjectsProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>>
    
    export type DeleteApiV1AdminOrgsOrgIdProjectsProjectIdMutationError = unknown

    /**
 * @summary Delete Tenant Project
 */
export const useDeleteApiV1AdminOrgsOrgIdProjectsProjectId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>, TError,{orgId: string;projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1AdminOrgsOrgIdProjectsProjectIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Sync Salesforce Leads
 */
export const postApiV1AdminOrgsOrgIdSalesforce = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/salesforce`, method: 'post'
    },
      options);
    }
  


export const getPostApiV1AdminOrgsOrgIdSalesforceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>, TError,{orgId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  postApiV1AdminOrgsOrgIdSalesforce(orgId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminOrgsOrgIdSalesforceMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>>
    
    export type PostApiV1AdminOrgsOrgIdSalesforceMutationError = unknown

    /**
 * @summary Sync Salesforce Leads
 */
export const usePostApiV1AdminOrgsOrgIdSalesforce = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminOrgsOrgIdSalesforceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get Tenant Billing Status
 * @summary Get Tenant Billing Status
 */
export const getTenantTrialCredits = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantRemainingCredits>(
      {url: `/api/v1/admin/orgs/${orgId}/trial_credit`, method: 'get'
    },
      options);
    }
  

export const getGetTenantTrialCreditsQueryKey = (orgId: string,) => [`/api/v1/admin/orgs/${orgId}/trial_credit`] as const;
  

    
export const getGetTenantTrialCreditsQueryOptions = <TData = Awaited<ReturnType<typeof getTenantTrialCredits>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantTrialCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantTrialCredits>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantTrialCreditsQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantTrialCredits>>> = () => getTenantTrialCredits(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetTenantTrialCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantTrialCredits>>>
export type GetTenantTrialCreditsQueryError = unknown

/**
 * @summary Get Tenant Billing Status
 */
export const useGetTenantTrialCredits = <TData = Awaited<ReturnType<typeof getTenantTrialCredits>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantTrialCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantTrialCreditsQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Add Trial Credit
 */
export const addTrialCredit = (
    orgId: string,
    formsAddTrailCredit: FormsAddTrailCredit,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/trial_credit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsAddTrailCredit
    },
      options);
    }
  


export const getAddTrialCreditMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTrialCredit>>, TError,{orgId: string;data: FormsAddTrailCredit}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addTrialCredit>>, TError,{orgId: string;data: FormsAddTrailCredit}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTrialCredit>>, {orgId: string;data: FormsAddTrailCredit}> = (props) => {
          const {orgId,data} = props ?? {};

          return  addTrialCredit(orgId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddTrialCreditMutationResult = NonNullable<Awaited<ReturnType<typeof addTrialCredit>>>
    export type AddTrialCreditMutationBody = FormsAddTrailCredit
    export type AddTrialCreditMutationError = unknown

    /**
 * @summary Add Trial Credit
 */
export const useAddTrialCredit = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTrialCredit>>, TError,{orgId: string;data: FormsAddTrailCredit}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddTrialCreditMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * List tenant user
 * @summary List Tenant Users
 */
export const getApiV1AdminOrgsOrgIdUsers = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListTenantUsersResp>(
      {url: `/api/v1/admin/orgs/${orgId}/users`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminOrgsOrgIdUsersQueryKey = (orgId: string,) => [`/api/v1/admin/orgs/${orgId}/users`] as const;
  

    
export const getGetApiV1AdminOrgsOrgIdUsersQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsers>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminOrgsOrgIdUsersQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsers>>> = () => getApiV1AdminOrgsOrgIdUsers(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetApiV1AdminOrgsOrgIdUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsers>>>
export type GetApiV1AdminOrgsOrgIdUsersQueryError = unknown

/**
 * @summary List Tenant Users
 */
export const useGetApiV1AdminOrgsOrgIdUsers = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsers>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminOrgsOrgIdUsersQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Add Tenant User
 */
export const postApiV1AdminOrgsOrgIdUsers = (
    orgId: string,
    formsTenantUser: FormsTenantUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantUser>(
      {url: `/api/v1/admin/orgs/${orgId}/users`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsTenantUser
    },
      options);
    }
  


export const getPostApiV1AdminOrgsOrgIdUsersMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdUsers>>, TError,{orgId: string;data: FormsTenantUser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdUsers>>, TError,{orgId: string;data: FormsTenantUser}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdUsers>>, {orgId: string;data: FormsTenantUser}> = (props) => {
          const {orgId,data} = props ?? {};

          return  postApiV1AdminOrgsOrgIdUsers(orgId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminOrgsOrgIdUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdUsers>>>
    export type PostApiV1AdminOrgsOrgIdUsersMutationBody = FormsTenantUser
    export type PostApiV1AdminOrgsOrgIdUsersMutationError = unknown

    /**
 * @summary Add Tenant User
 */
export const usePostApiV1AdminOrgsOrgIdUsers = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdUsers>>, TError,{orgId: string;data: FormsTenantUser}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminOrgsOrgIdUsersMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Tenant User
 */
export const getApiV1AdminOrgsOrgIdUsersUserId = (
    orgId: string,
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantUser>(
      {url: `/api/v1/admin/orgs/${orgId}/users/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminOrgsOrgIdUsersUserIdQueryKey = (orgId: string,
    userId: string,) => [`/api/v1/admin/orgs/${orgId}/users/${userId}`] as const;
  

    
export const getGetApiV1AdminOrgsOrgIdUsersUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError = unknown>(orgId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminOrgsOrgIdUsersUserIdQueryKey(orgId,userId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>> = () => getApiV1AdminOrgsOrgIdUsersUserId(orgId,userId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId && userId), ...queryOptions}}

export type GetApiV1AdminOrgsOrgIdUsersUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>>
export type GetApiV1AdminOrgsOrgIdUsersUserIdQueryError = unknown

/**
 * @summary Get Tenant User
 */
export const useGetApiV1AdminOrgsOrgIdUsersUserId = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError = unknown>(
 orgId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminOrgsOrgIdUsersUserIdQueryOptions(orgId,userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Tenant User
 */
export const putApiV1AdminOrgsOrgIdUsersUserId = (
    orgId: string,
    userId: string,
    formsTenantUser: FormsTenantUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantUser>(
      {url: `/api/v1/admin/orgs/${orgId}/users/${userId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsTenantUser
    },
      options);
    }
  


export const getPutApiV1AdminOrgsOrgIdUsersUserIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string;data: FormsTenantUser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string;data: FormsTenantUser}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>, {orgId: string;userId: string;data: FormsTenantUser}> = (props) => {
          const {orgId,userId,data} = props ?? {};

          return  putApiV1AdminOrgsOrgIdUsersUserId(orgId,userId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AdminOrgsOrgIdUsersUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>>
    export type PutApiV1AdminOrgsOrgIdUsersUserIdMutationBody = FormsTenantUser
    export type PutApiV1AdminOrgsOrgIdUsersUserIdMutationError = unknown

    /**
 * @summary Update Tenant User
 */
export const usePutApiV1AdminOrgsOrgIdUsersUserId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string;data: FormsTenantUser}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPutApiV1AdminOrgsOrgIdUsersUserIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Tenant User
 */
export const deleteApiV1AdminOrgsOrgIdUsersUserId = (
    orgId: string,
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/users/${userId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1AdminOrgsOrgIdUsersUserIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>, {orgId: string;userId: string}> = (props) => {
          const {orgId,userId} = props ?? {};

          return  deleteApiV1AdminOrgsOrgIdUsersUserId(orgId,userId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1AdminOrgsOrgIdUsersUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>>
    
    export type DeleteApiV1AdminOrgsOrgIdUsersUserIdMutationError = unknown

    /**
 * @summary Delete Tenant User
 */
export const useDeleteApiV1AdminOrgsOrgIdUsersUserId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1AdminOrgsOrgIdUsersUserIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * ListProviders
 * @summary ListProviders
 */
export const listProviders = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListProvidersResp>(
      {url: `/api/v1/admin/providers`, method: 'get'
    },
      options);
    }
  

export const getListProvidersQueryKey = () => [`/api/v1/admin/providers`] as const;
  

    
export const getListProvidersQueryOptions = <TData = Awaited<ReturnType<typeof listProviders>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listProviders>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listProviders>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListProvidersQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProviders>>> = () => listProviders(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListProvidersQueryResult = NonNullable<Awaited<ReturnType<typeof listProviders>>>
export type ListProvidersQueryError = unknown

/**
 * @summary ListProviders
 */
export const useListProviders = <TData = Awaited<ReturnType<typeof listProviders>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listProviders>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListProvidersQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * AddProvider
 * @summary AddProvider
 */
export const addProvider = (
    formsProvider: FormsProvider,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/providers`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsProvider
    },
      options);
    }
  


export const getAddProviderMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProvider>>, TError,{data: FormsProvider}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addProvider>>, TError,{data: FormsProvider}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addProvider>>, {data: FormsProvider}> = (props) => {
          const {data} = props ?? {};

          return  addProvider(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddProviderMutationResult = NonNullable<Awaited<ReturnType<typeof addProvider>>>
    export type AddProviderMutationBody = FormsProvider
    export type AddProviderMutationError = unknown

    /**
 * @summary AddProvider
 */
export const useAddProvider = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addProvider>>, TError,{data: FormsProvider}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddProviderMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * ListRegions
 * @summary ListRegions
 */
export const listRegions = (
    provider: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListRegionResp>(
      {url: `/api/v1/admin/providers/${provider}/regions`, method: 'get'
    },
      options);
    }
  

export const getListRegionsQueryKey = (provider: string,) => [`/api/v1/admin/providers/${provider}/regions`] as const;
  

    
export const getListRegionsQueryOptions = <TData = Awaited<ReturnType<typeof listRegions>>, TError = unknown>(provider: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listRegions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listRegions>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListRegionsQueryKey(provider);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listRegions>>> = () => listRegions(provider, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(provider), ...queryOptions}}

export type ListRegionsQueryResult = NonNullable<Awaited<ReturnType<typeof listRegions>>>
export type ListRegionsQueryError = unknown

/**
 * @summary ListRegions
 */
export const useListRegions = <TData = Awaited<ReturnType<typeof listRegions>>, TError = unknown>(
 provider: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listRegions>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListRegionsQueryOptions(provider,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * AddRegion
 * @summary AddRegion
 */
export const addRegion = (
    provider: string,
    formsProviderRegionBody: FormsProviderRegionBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsProviderRegion>(
      {url: `/api/v1/admin/providers/${provider}/regions`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsProviderRegionBody
    },
      options);
    }
  


export const getAddRegionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addRegion>>, TError,{provider: string;data: FormsProviderRegionBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addRegion>>, TError,{provider: string;data: FormsProviderRegionBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addRegion>>, {provider: string;data: FormsProviderRegionBody}> = (props) => {
          const {provider,data} = props ?? {};

          return  addRegion(provider,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddRegionMutationResult = NonNullable<Awaited<ReturnType<typeof addRegion>>>
    export type AddRegionMutationBody = FormsProviderRegionBody
    export type AddRegionMutationError = unknown

    /**
 * @summary AddRegion
 */
export const useAddRegion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addRegion>>, TError,{provider: string;data: FormsProviderRegionBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddRegionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * GetRegionByID
 * @summary GetRegionByID
 */
export const getRegionByID = (
    provider: string,
    regionId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsProviderRegion>(
      {url: `/api/v1/admin/providers/${provider}/regions/${regionId}`, method: 'get'
    },
      options);
    }
  

export const getGetRegionByIDQueryKey = (provider: string,
    regionId: string,) => [`/api/v1/admin/providers/${provider}/regions/${regionId}`] as const;
  

    
export const getGetRegionByIDQueryOptions = <TData = Awaited<ReturnType<typeof getRegionByID>>, TError = unknown>(provider: string,
    regionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegionByID>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getRegionByID>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRegionByIDQueryKey(provider,regionId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRegionByID>>> = () => getRegionByID(provider,regionId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(provider && regionId), ...queryOptions}}

export type GetRegionByIDQueryResult = NonNullable<Awaited<ReturnType<typeof getRegionByID>>>
export type GetRegionByIDQueryError = unknown

/**
 * @summary GetRegionByID
 */
export const useGetRegionByID = <TData = Awaited<ReturnType<typeof getRegionByID>>, TError = unknown>(
 provider: string,
    regionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRegionByID>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRegionByIDQueryOptions(provider,regionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * UpdateRegion
 * @summary UpdateRegion
 */
export const updateRegion = (
    provider: string,
    regionId: string,
    formsProviderRegionBody: FormsProviderRegionBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsProviderRegion>(
      {url: `/api/v1/admin/providers/${provider}/regions/${regionId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsProviderRegionBody
    },
      options);
    }
  


export const getUpdateRegionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRegion>>, TError,{provider: string;regionId: string;data: FormsProviderRegionBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateRegion>>, TError,{provider: string;regionId: string;data: FormsProviderRegionBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRegion>>, {provider: string;regionId: string;data: FormsProviderRegionBody}> = (props) => {
          const {provider,regionId,data} = props ?? {};

          return  updateRegion(provider,regionId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateRegionMutationResult = NonNullable<Awaited<ReturnType<typeof updateRegion>>>
    export type UpdateRegionMutationBody = FormsProviderRegionBody
    export type UpdateRegionMutationError = unknown

    /**
 * @summary UpdateRegion
 */
export const useUpdateRegion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRegion>>, TError,{provider: string;regionId: string;data: FormsProviderRegionBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateRegionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * DeleteRegion
 * @summary DeleteRegion
 */
export const deleteRegion = (
    provider: string,
    regionId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/providers/${provider}/regions/${regionId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteRegionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegion>>, TError,{provider: string;regionId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteRegion>>, TError,{provider: string;regionId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteRegion>>, {provider: string;regionId: string}> = (props) => {
          const {provider,regionId} = props ?? {};

          return  deleteRegion(provider,regionId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteRegionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteRegion>>>
    
    export type DeleteRegionMutationError = unknown

    /**
 * @summary DeleteRegion
 */
export const useDeleteRegion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteRegion>>, TError,{provider: string;regionId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteRegionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary ListCentralRoles
 */
export const listCentralRoles = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListRolesResp>(
      {url: `/api/v1/admin/roles`, method: 'get'
    },
      options);
    }
  

export const getListCentralRolesQueryKey = () => [`/api/v1/admin/roles`] as const;
  

    
export const getListCentralRolesQueryOptions = <TData = Awaited<ReturnType<typeof listCentralRoles>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCentralRoles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listCentralRoles>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCentralRolesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCentralRoles>>> = () => listCentralRoles(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListCentralRolesQueryResult = NonNullable<Awaited<ReturnType<typeof listCentralRoles>>>
export type ListCentralRolesQueryError = unknown

/**
 * @summary ListCentralRoles
 */
export const useListCentralRoles = <TData = Awaited<ReturnType<typeof listCentralRoles>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCentralRoles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCentralRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary AddCentralRole
 */
export const addCentralRole = (
    formsRole: FormsRole,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRole>(
      {url: `/api/v1/admin/roles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsRole
    },
      options);
    }
  


export const getAddCentralRoleMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCentralRole>>, TError,{data: FormsRole}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addCentralRole>>, TError,{data: FormsRole}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCentralRole>>, {data: FormsRole}> = (props) => {
          const {data} = props ?? {};

          return  addCentralRole(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddCentralRoleMutationResult = NonNullable<Awaited<ReturnType<typeof addCentralRole>>>
    export type AddCentralRoleMutationBody = FormsRole
    export type AddCentralRoleMutationError = unknown

    /**
 * @summary AddCentralRole
 */
export const useAddCentralRole = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCentralRole>>, TError,{data: FormsRole}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddCentralRoleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * GetCentralRole
 * @summary GetCentralRole
 */
export const getCentralRole = (
    roleId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRole>(
      {url: `/api/v1/admin/roles/${roleId}`, method: 'get'
    },
      options);
    }
  

export const getGetCentralRoleQueryKey = (roleId: string,) => [`/api/v1/admin/roles/${roleId}`] as const;
  

    
export const getGetCentralRoleQueryOptions = <TData = Awaited<ReturnType<typeof getCentralRole>>, TError = unknown>(roleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCentralRole>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCentralRole>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCentralRoleQueryKey(roleId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCentralRole>>> = () => getCentralRole(roleId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(roleId), ...queryOptions}}

export type GetCentralRoleQueryResult = NonNullable<Awaited<ReturnType<typeof getCentralRole>>>
export type GetCentralRoleQueryError = unknown

/**
 * @summary GetCentralRole
 */
export const useGetCentralRole = <TData = Awaited<ReturnType<typeof getCentralRole>>, TError = unknown>(
 roleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCentralRole>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCentralRoleQueryOptions(roleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * UpdateCentralRole
 * @summary UpdateCentralRole
 */
export const updateCentralRole = (
    roleId: string,
    formsRole: FormsRole,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRole>(
      {url: `/api/v1/admin/roles/${roleId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsRole
    },
      options);
    }
  


export const getUpdateCentralRoleMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCentralRole>>, TError,{roleId: string;data: FormsRole}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCentralRole>>, TError,{roleId: string;data: FormsRole}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCentralRole>>, {roleId: string;data: FormsRole}> = (props) => {
          const {roleId,data} = props ?? {};

          return  updateCentralRole(roleId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateCentralRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateCentralRole>>>
    export type UpdateCentralRoleMutationBody = FormsRole
    export type UpdateCentralRoleMutationError = unknown

    /**
 * @summary UpdateCentralRole
 */
export const useUpdateCentralRole = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCentralRole>>, TError,{roleId: string;data: FormsRole}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateCentralRoleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * DeleteCentralRole
 * @summary DeleteCentralRole
 */
export const deleteCentralRole = (
    roleId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/roles/${roleId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteCentralRoleMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCentralRole>>, TError,{roleId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCentralRole>>, TError,{roleId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCentralRole>>, {roleId: string}> = (props) => {
          const {roleId} = props ?? {};

          return  deleteCentralRole(roleId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteCentralRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCentralRole>>>
    
    export type DeleteCentralRoleMutationError = unknown

    /**
 * @summary DeleteCentralRole
 */
export const useDeleteCentralRole = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCentralRole>>, TError,{roleId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteCentralRoleMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetSystemSettings
 */
export const getSystemSettings = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSystemSettings>(
      {url: `/api/v1/admin/system-settings`, method: 'get'
    },
      options);
    }
  

export const getGetSystemSettingsQueryKey = () => [`/api/v1/admin/system-settings`] as const;
  

    
export const getGetSystemSettingsQueryOptions = <TData = Awaited<ReturnType<typeof getSystemSettings>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSystemSettings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getSystemSettings>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSystemSettingsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSystemSettings>>> = () => getSystemSettings(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetSystemSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getSystemSettings>>>
export type GetSystemSettingsQueryError = unknown

/**
 * @summary GetSystemSettings
 */
export const useGetSystemSettings = <TData = Awaited<ReturnType<typeof getSystemSettings>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSystemSettings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSystemSettingsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary UpdateSystemSettings
 */
export const updateSystemSettings = (
    formsSystemSettings: FormsSystemSettings,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSystemSettings>(
      {url: `/api/v1/admin/system-settings`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsSystemSettings
    },
      options);
    }
  


export const getUpdateSystemSettingsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSystemSettings>>, TError,{data: FormsSystemSettings}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateSystemSettings>>, TError,{data: FormsSystemSettings}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSystemSettings>>, {data: FormsSystemSettings}> = (props) => {
          const {data} = props ?? {};

          return  updateSystemSettings(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateSystemSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof updateSystemSettings>>>
    export type UpdateSystemSettingsMutationBody = FormsSystemSettings
    export type UpdateSystemSettingsMutationError = unknown

    /**
 * @summary UpdateSystemSettings
 */
export const useUpdateSystemSettings = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSystemSettings>>, TError,{data: FormsSystemSettings}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateSystemSettingsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary ListCentralUsers
 */
export const listCentralUsers = (
    params?: ListCentralUsersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListCentralUsersResp>(
      {url: `/api/v1/admin/users`, method: 'get',
        params
    },
      options);
    }
  

export const getListCentralUsersQueryKey = (params?: ListCentralUsersParams,) => [`/api/v1/admin/users`, ...(params ? [params]: [])] as const;
  

    
export const getListCentralUsersQueryOptions = <TData = Awaited<ReturnType<typeof listCentralUsers>>, TError = unknown>(params?: ListCentralUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCentralUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listCentralUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCentralUsersQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCentralUsers>>> = () => listCentralUsers(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListCentralUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listCentralUsers>>>
export type ListCentralUsersQueryError = unknown

/**
 * @summary ListCentralUsers
 */
export const useListCentralUsers = <TData = Awaited<ReturnType<typeof listCentralUsers>>, TError = unknown>(
 params?: ListCentralUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCentralUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCentralUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Add Management Portal User
 */
export const addManagementPortalUser = (
    formsCentralUser: FormsCentralUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCentralUser>(
      {url: `/api/v1/admin/users`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCentralUser
    },
      options);
    }
  


export const getAddManagementPortalUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addManagementPortalUser>>, TError,{data: FormsCentralUser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addManagementPortalUser>>, TError,{data: FormsCentralUser}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addManagementPortalUser>>, {data: FormsCentralUser}> = (props) => {
          const {data} = props ?? {};

          return  addManagementPortalUser(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type AddManagementPortalUserMutationResult = NonNullable<Awaited<ReturnType<typeof addManagementPortalUser>>>
    export type AddManagementPortalUserMutationBody = FormsCentralUser
    export type AddManagementPortalUserMutationError = unknown

    /**
 * @summary Add Management Portal User
 */
export const useAddManagementPortalUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addManagementPortalUser>>, TError,{data: FormsCentralUser}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getAddManagementPortalUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Management Portal User
 */
export const getManagementPortalUser = (
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCentralUser>(
      {url: `/api/v1/admin/users/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetManagementPortalUserQueryKey = (userId: string,) => [`/api/v1/admin/users/${userId}`] as const;
  

    
export const getGetManagementPortalUserQueryOptions = <TData = Awaited<ReturnType<typeof getManagementPortalUser>>, TError = unknown>(userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManagementPortalUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getManagementPortalUser>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetManagementPortalUserQueryKey(userId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getManagementPortalUser>>> = () => getManagementPortalUser(userId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(userId), ...queryOptions}}

export type GetManagementPortalUserQueryResult = NonNullable<Awaited<ReturnType<typeof getManagementPortalUser>>>
export type GetManagementPortalUserQueryError = unknown

/**
 * @summary Get Management Portal User
 */
export const useGetManagementPortalUser = <TData = Awaited<ReturnType<typeof getManagementPortalUser>>, TError = unknown>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getManagementPortalUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetManagementPortalUserQueryOptions(userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Management Portal User
 */
export const updateManagementPortalUser = (
    userId: string,
    formsCentralUser: FormsCentralUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCentralUser>(
      {url: `/api/v1/admin/users/${userId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsCentralUser
    },
      options);
    }
  


export const getUpdateManagementPortalUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateManagementPortalUser>>, TError,{userId: string;data: FormsCentralUser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateManagementPortalUser>>, TError,{userId: string;data: FormsCentralUser}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateManagementPortalUser>>, {userId: string;data: FormsCentralUser}> = (props) => {
          const {userId,data} = props ?? {};

          return  updateManagementPortalUser(userId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateManagementPortalUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateManagementPortalUser>>>
    export type UpdateManagementPortalUserMutationBody = FormsCentralUser
    export type UpdateManagementPortalUserMutationError = unknown

    /**
 * @summary Update Management Portal User
 */
export const useUpdateManagementPortalUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateManagementPortalUser>>, TError,{userId: string;data: FormsCentralUser}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateManagementPortalUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Management Portal User
 */
export const deleteManagementPortalUser = (
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/users/${userId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteManagementPortalUserMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteManagementPortalUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteManagementPortalUser>>, TError,{userId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteManagementPortalUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deleteManagementPortalUser(userId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteManagementPortalUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteManagementPortalUser>>>
    
    export type DeleteManagementPortalUserMutationError = unknown

    /**
 * @summary Delete Management Portal User
 */
export const useDeleteManagementPortalUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteManagementPortalUser>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteManagementPortalUserMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cost Explorer
 */
export const postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorer = (
    tenantId: string,
    formsCostExplorerQuery: FormsCostExplorerQuery,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostExplorerResult>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExplorerQuery
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorer>>, TError,{tenantId: string;data: FormsCostExplorerQuery}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorer>>, TError,{tenantId: string;data: FormsCostExplorerQuery}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorer>>, {tenantId: string;data: FormsCostExplorerQuery}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorer(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorer>>>
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerMutationBody = FormsCostExplorerQuery
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerMutationError = unknown

    /**
 * @summary Cost Explorer
 */
export const usePostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorer = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorer>>, TError,{tenantId: string;data: FormsCostExplorerQuery}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdCostexplorerMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
export const getBillsDetails = (
    tenantId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostExplorerDetailsResult>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/bills_details/year/${year}/month/${month}`, method: 'get'
    },
      options);
    }
  

export const getGetBillsDetailsQueryKey = (tenantId: string,
    year: string,
    month: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/bills_details/year/${year}/month/${month}`] as const;
  

    
export const getGetBillsDetailsQueryOptions = <TData = Awaited<ReturnType<typeof getBillsDetails>>, TError = unknown>(tenantId: string,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillsDetailsQueryKey(tenantId,year,month);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillsDetails>>> = () => getBillsDetails(tenantId,year,month, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && year && month), ...queryOptions}}

export type GetBillsDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getBillsDetails>>>
export type GetBillsDetailsQueryError = unknown

export const useGetBillsDetails = <TData = Awaited<ReturnType<typeof getBillsDetails>>, TError = unknown>(
 tenantId: string,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBillsDetailsQueryOptions(tenantId,year,month,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


export const getMonthlyBillsExport = (
    tenantId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiHttpBody>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_bills/year/${year}/month/${month}/export`, method: 'post'
    },
      options);
    }
  


export const getGetMonthlyBillsExportMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError,{tenantId: string;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError,{tenantId: string;year: string;month: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getMonthlyBillsExport>>, {tenantId: string;year: string;month: string}> = (props) => {
          const {tenantId,year,month} = props ?? {};

          return  getMonthlyBillsExport(tenantId,year,month,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GetMonthlyBillsExportMutationResult = NonNullable<Awaited<ReturnType<typeof getMonthlyBillsExport>>>
    
    export type GetMonthlyBillsExportMutationError = unknown

    export const useGetMonthlyBillsExport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError,{tenantId: string;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getGetMonthlyBillsExportMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cost Explorer Monthly
 */
export const getCostExplorerMonthly = (
    tenantId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsMonthlycostExplorerResult>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_cost/year/${year}/month/${month}`, method: 'get'
    },
      options);
    }
  

export const getGetCostExplorerMonthlyQueryKey = (tenantId: string,
    year: string,
    month: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_cost/year/${year}/month/${month}`] as const;
  

    
export const getGetCostExplorerMonthlyQueryOptions = <TData = Awaited<ReturnType<typeof getCostExplorerMonthly>>, TError = unknown>(tenantId: string,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCostExplorerMonthly>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCostExplorerMonthly>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCostExplorerMonthlyQueryKey(tenantId,year,month);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCostExplorerMonthly>>> = () => getCostExplorerMonthly(tenantId,year,month, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && year && month), ...queryOptions}}

export type GetCostExplorerMonthlyQueryResult = NonNullable<Awaited<ReturnType<typeof getCostExplorerMonthly>>>
export type GetCostExplorerMonthlyQueryError = unknown

/**
 * @summary Cost Explorer Monthly
 */
export const useGetCostExplorerMonthly = <TData = Awaited<ReturnType<typeof getCostExplorerMonthly>>, TError = unknown>(
 tenantId: string,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCostExplorerMonthly>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCostExplorerMonthlyQueryOptions(tenantId,year,month,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cost Explorer Monthly
 */
export const exportMonthBillCSV = (
    tenantId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiHttpBody>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_cost/year/${year}/month/${month}/export`, method: 'post'
    },
      options);
    }
  


export const getExportMonthBillCSVMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError,{tenantId: string;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError,{tenantId: string;year: string;month: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportMonthBillCSV>>, {tenantId: string;year: string;month: string}> = (props) => {
          const {tenantId,year,month} = props ?? {};

          return  exportMonthBillCSV(tenantId,year,month,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ExportMonthBillCSVMutationResult = NonNullable<Awaited<ReturnType<typeof exportMonthBillCSV>>>
    
    export type ExportMonthBillCSVMutationError = unknown

    /**
 * @summary Cost Explorer Monthly
 */
export const useExportMonthBillCSV = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError,{tenantId: string;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getExportMonthBillCSVMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List All CreditRisk Level
 */
export const listAllCreditRiskLevel = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditRiskLevel[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels`, method: 'get'
    },
      options);
    }
  

export const getListAllCreditRiskLevelQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels`] as const;
  

    
export const getListAllCreditRiskLevelQueryOptions = <TData = Awaited<ReturnType<typeof listAllCreditRiskLevel>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllCreditRiskLevel>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listAllCreditRiskLevel>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAllCreditRiskLevelQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAllCreditRiskLevel>>> = () => listAllCreditRiskLevel(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListAllCreditRiskLevelQueryResult = NonNullable<Awaited<ReturnType<typeof listAllCreditRiskLevel>>>
export type ListAllCreditRiskLevelQueryError = unknown

/**
 * @summary List All CreditRisk Level
 */
export const useListAllCreditRiskLevel = <TData = Awaited<ReturnType<typeof listAllCreditRiskLevel>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAllCreditRiskLevel>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAllCreditRiskLevelQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create CreditRisk Level
 */
export const createCreditRiskLevel = (
    formsCreditRiskLevelInputBody: FormsCreditRiskLevelInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditRiskLevel>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreditRiskLevelInputBody
    },
      options);
    }
  


export const getCreateCreditRiskLevelMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCreditRiskLevel>>, TError,{data: FormsCreditRiskLevelInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createCreditRiskLevel>>, TError,{data: FormsCreditRiskLevelInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCreditRiskLevel>>, {data: FormsCreditRiskLevelInputBody}> = (props) => {
          const {data} = props ?? {};

          return  createCreditRiskLevel(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateCreditRiskLevelMutationResult = NonNullable<Awaited<ReturnType<typeof createCreditRiskLevel>>>
    export type CreateCreditRiskLevelMutationBody = FormsCreditRiskLevelInputBody
    export type CreateCreditRiskLevelMutationError = unknown

    /**
 * @summary Create CreditRisk Level
 */
export const useCreateCreditRiskLevel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCreditRiskLevel>>, TError,{data: FormsCreditRiskLevelInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateCreditRiskLevelMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get CreditRisk Level
 */
export const getCreditRiskLevel = (
    creditRiskLevelId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditRiskLevel>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}`, method: 'get'
    },
      options);
    }
  

export const getGetCreditRiskLevelQueryKey = (creditRiskLevelId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}`] as const;
  

    
export const getGetCreditRiskLevelQueryOptions = <TData = Awaited<ReturnType<typeof getCreditRiskLevel>>, TError = unknown>(creditRiskLevelId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskLevel>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskLevel>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCreditRiskLevelQueryKey(creditRiskLevelId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditRiskLevel>>> = () => getCreditRiskLevel(creditRiskLevelId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(creditRiskLevelId), ...queryOptions}}

export type GetCreditRiskLevelQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditRiskLevel>>>
export type GetCreditRiskLevelQueryError = unknown

/**
 * @summary Get CreditRisk Level
 */
export const useGetCreditRiskLevel = <TData = Awaited<ReturnType<typeof getCreditRiskLevel>>, TError = unknown>(
 creditRiskLevelId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskLevel>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCreditRiskLevelQueryOptions(creditRiskLevelId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update CreditRisk Level
 */
export const updateCreditRiskLevel = (
    creditRiskLevelId: string,
    formsCreditRiskLevelInputBody: FormsCreditRiskLevelInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsCreditRiskLevelInputBody
    },
      options);
    }
  


export const getUpdateCreditRiskLevelMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCreditRiskLevel>>, TError,{creditRiskLevelId: string;data: FormsCreditRiskLevelInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCreditRiskLevel>>, TError,{creditRiskLevelId: string;data: FormsCreditRiskLevelInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCreditRiskLevel>>, {creditRiskLevelId: string;data: FormsCreditRiskLevelInputBody}> = (props) => {
          const {creditRiskLevelId,data} = props ?? {};

          return  updateCreditRiskLevel(creditRiskLevelId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateCreditRiskLevelMutationResult = NonNullable<Awaited<ReturnType<typeof updateCreditRiskLevel>>>
    export type UpdateCreditRiskLevelMutationBody = FormsCreditRiskLevelInputBody
    export type UpdateCreditRiskLevelMutationError = unknown

    /**
 * @summary Update CreditRisk Level
 */
export const useUpdateCreditRiskLevel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCreditRiskLevel>>, TError,{creditRiskLevelId: string;data: FormsCreditRiskLevelInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateCreditRiskLevelMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete CreditRisk Level
 */
export const deleteCreditRiskLevel = (
    creditRiskLevelId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteCreditRiskLevelMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCreditRiskLevel>>, TError,{creditRiskLevelId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCreditRiskLevel>>, TError,{creditRiskLevelId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCreditRiskLevel>>, {creditRiskLevelId: string}> = (props) => {
          const {creditRiskLevelId} = props ?? {};

          return  deleteCreditRiskLevel(creditRiskLevelId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteCreditRiskLevelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCreditRiskLevel>>>
    
    export type DeleteCreditRiskLevelMutationError = unknown

    /**
 * @summary Delete CreditRisk Level
 */
export const useDeleteCreditRiskLevel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCreditRiskLevel>>, TError,{creditRiskLevelId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteCreditRiskLevelMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Credit Type Tree
 */
export const getUnitTypeTree = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitFilterNode>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/unit_filters`, method: 'get'
    },
      options);
    }
  

export const getGetUnitTypeTreeQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/unit_filters`] as const;
  

    
export const getGetUnitTypeTreeQueryOptions = <TData = Awaited<ReturnType<typeof getUnitTypeTree>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUnitTypeTree>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getUnitTypeTree>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUnitTypeTreeQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnitTypeTree>>> = () => getUnitTypeTree(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetUnitTypeTreeQueryResult = NonNullable<Awaited<ReturnType<typeof getUnitTypeTree>>>
export type GetUnitTypeTreeQueryError = unknown

/**
 * @summary Get Credit Type Tree
 */
export const useGetUnitTypeTree = <TData = Awaited<ReturnType<typeof getUnitTypeTree>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUnitTypeTree>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUnitTypeTreeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Unit Filter Quick Selection
 */
export const getUnitFilterQuickSelection = (
    target: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitFilterSlim[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/unit_filters/quick_selections/${target}`, method: 'get'
    },
      options);
    }
  

export const getGetUnitFilterQuickSelectionQueryKey = (target: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/unit_filters/quick_selections/${target}`] as const;
  

    
export const getGetUnitFilterQuickSelectionQueryOptions = <TData = Awaited<ReturnType<typeof getUnitFilterQuickSelection>>, TError = unknown>(target: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUnitFilterQuickSelection>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getUnitFilterQuickSelection>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUnitFilterQuickSelectionQueryKey(target);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnitFilterQuickSelection>>> = () => getUnitFilterQuickSelection(target, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(target), ...queryOptions}}

export type GetUnitFilterQuickSelectionQueryResult = NonNullable<Awaited<ReturnType<typeof getUnitFilterQuickSelection>>>
export type GetUnitFilterQuickSelectionQueryError = unknown

/**
 * @summary Get Unit Filter Quick Selection
 */
export const useGetUnitFilterQuickSelection = <TData = Awaited<ReturnType<typeof getUnitFilterQuickSelection>>, TError = unknown>(
 target: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUnitFilterQuickSelection>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetUnitFilterQuickSelectionQueryOptions(target,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Tenant Cost Quota
 */
export const getTenantCostQuota = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostQuota>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/cost_quota`, method: 'get'
    },
      options);
    }
  

export const getGetTenantCostQuotaQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/cost_quota`] as const;
  

    
export const getGetTenantCostQuotaQueryOptions = <TData = Awaited<ReturnType<typeof getTenantCostQuota>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCostQuota>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantCostQuota>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCostQuotaQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCostQuota>>> = () => getTenantCostQuota(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantCostQuotaQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCostQuota>>>
export type GetTenantCostQuotaQueryError = unknown

/**
 * @summary Get Tenant Cost Quota
 */
export const useGetTenantCostQuota = <TData = Awaited<ReturnType<typeof getTenantCostQuota>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCostQuota>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantCostQuotaQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Set Tenant Cost Quota
 */
export const setTenantCostQuota = (
    tenantId: string,
    formsCostQuotaBody: FormsCostQuotaBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostQuota>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/cost_quota`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsCostQuotaBody
    },
      options);
    }
  


export const getSetTenantCostQuotaMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setTenantCostQuota>>, TError,{tenantId: string;data: FormsCostQuotaBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof setTenantCostQuota>>, TError,{tenantId: string;data: FormsCostQuotaBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setTenantCostQuota>>, {tenantId: string;data: FormsCostQuotaBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  setTenantCostQuota(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SetTenantCostQuotaMutationResult = NonNullable<Awaited<ReturnType<typeof setTenantCostQuota>>>
    export type SetTenantCostQuotaMutationBody = FormsCostQuotaBody
    export type SetTenantCostQuotaMutationError = unknown

    /**
 * @summary Set Tenant Cost Quota
 */
export const useSetTenantCostQuota = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setTenantCostQuota>>, TError,{tenantId: string;data: FormsCostQuotaBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSetTenantCostQuotaMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Credit Risk Close Policy
 */
export const getCreditRiskClosePolicy = (
    creditRiskLevelId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantClosePolicy>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}/risk_close_policy`, method: 'get'
    },
      options);
    }
  

export const getGetCreditRiskClosePolicyQueryKey = (creditRiskLevelId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}/risk_close_policy`] as const;
  

    
export const getGetCreditRiskClosePolicyQueryOptions = <TData = Awaited<ReturnType<typeof getCreditRiskClosePolicy>>, TError = unknown>(creditRiskLevelId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskClosePolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskClosePolicy>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCreditRiskClosePolicyQueryKey(creditRiskLevelId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditRiskClosePolicy>>> = () => getCreditRiskClosePolicy(creditRiskLevelId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(creditRiskLevelId), ...queryOptions}}

export type GetCreditRiskClosePolicyQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditRiskClosePolicy>>>
export type GetCreditRiskClosePolicyQueryError = unknown

/**
 * @summary Get Credit Risk Close Policy
 */
export const useGetCreditRiskClosePolicy = <TData = Awaited<ReturnType<typeof getCreditRiskClosePolicy>>, TError = unknown>(
 creditRiskLevelId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskClosePolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCreditRiskClosePolicyQueryOptions(creditRiskLevelId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Or Update Credit Risk Close Policy
 */
export const createOrUpdateCreditRiskClosePolicy = (
    creditRiskLevelId: string,
    formsTenantClosePolicyInput: FormsTenantClosePolicyInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}/risk_close_policy`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsTenantClosePolicyInput
    },
      options);
    }
  


export const getCreateOrUpdateCreditRiskClosePolicyMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrUpdateCreditRiskClosePolicy>>, TError,{creditRiskLevelId: string;data: FormsTenantClosePolicyInput}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrUpdateCreditRiskClosePolicy>>, TError,{creditRiskLevelId: string;data: FormsTenantClosePolicyInput}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrUpdateCreditRiskClosePolicy>>, {creditRiskLevelId: string;data: FormsTenantClosePolicyInput}> = (props) => {
          const {creditRiskLevelId,data} = props ?? {};

          return  createOrUpdateCreditRiskClosePolicy(creditRiskLevelId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateOrUpdateCreditRiskClosePolicyMutationResult = NonNullable<Awaited<ReturnType<typeof createOrUpdateCreditRiskClosePolicy>>>
    export type CreateOrUpdateCreditRiskClosePolicyMutationBody = FormsTenantClosePolicyInput
    export type CreateOrUpdateCreditRiskClosePolicyMutationError = unknown

    /**
 * @summary Create Or Update Credit Risk Close Policy
 */
export const useCreateOrUpdateCreditRiskClosePolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrUpdateCreditRiskClosePolicy>>, TError,{creditRiskLevelId: string;data: FormsTenantClosePolicyInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateOrUpdateCreditRiskClosePolicyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Credit Risk Close Policy
 */
export const deleteCreditRiskClosePolicy = (
    creditRiskLevelId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}/risk_close_policy`, method: 'delete'
    },
      options);
    }
  


export const getDeleteCreditRiskClosePolicyMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCreditRiskClosePolicy>>, TError,{creditRiskLevelId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCreditRiskClosePolicy>>, TError,{creditRiskLevelId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCreditRiskClosePolicy>>, {creditRiskLevelId: string}> = (props) => {
          const {creditRiskLevelId} = props ?? {};

          return  deleteCreditRiskClosePolicy(creditRiskLevelId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteCreditRiskClosePolicyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCreditRiskClosePolicy>>>
    
    export type DeleteCreditRiskClosePolicyMutationError = unknown

    /**
 * @summary Delete Credit Risk Close Policy
 */
export const useDeleteCreditRiskClosePolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCreditRiskClosePolicy>>, TError,{creditRiskLevelId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteCreditRiskClosePolicyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Credit Type Tree
 */
export const getCreditTypeTree = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditTypeNode>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_types`, method: 'get'
    },
      options);
    }
  

export const getGetCreditTypeTreeQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/credit_types`] as const;
  

    
export const getGetCreditTypeTreeQueryOptions = <TData = Awaited<ReturnType<typeof getCreditTypeTree>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditTypeTree>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCreditTypeTree>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCreditTypeTreeQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditTypeTree>>> = () => getCreditTypeTree(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetCreditTypeTreeQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditTypeTree>>>
export type GetCreditTypeTreeQueryError = unknown

/**
 * @summary Get Credit Type Tree
 */
export const useGetCreditTypeTree = <TData = Awaited<ReturnType<typeof getCreditTypeTree>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditTypeTree>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCreditTypeTreeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Credit Type
 */
export const createCreditType = (
    formsCreditTypeBody: FormsCreditTypeBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditTypeSlim>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_types`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreditTypeBody
    },
      options);
    }
  


export const getCreateCreditTypeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCreditType>>, TError,{data: FormsCreditTypeBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createCreditType>>, TError,{data: FormsCreditTypeBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCreditType>>, {data: FormsCreditTypeBody}> = (props) => {
          const {data} = props ?? {};

          return  createCreditType(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateCreditTypeMutationResult = NonNullable<Awaited<ReturnType<typeof createCreditType>>>
    export type CreateCreditTypeMutationBody = FormsCreditTypeBody
    export type CreateCreditTypeMutationError = unknown

    /**
 * @summary Create Credit Type
 */
export const useCreateCreditType = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCreditType>>, TError,{data: FormsCreditTypeBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateCreditTypeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Credit Type
 */
export const getCreditType = (
    creditTypeId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_types/${creditTypeId}`, method: 'get'
    },
      options);
    }
  

export const getGetCreditTypeQueryKey = (creditTypeId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/credit_types/${creditTypeId}`] as const;
  

    
export const getGetCreditTypeQueryOptions = <TData = Awaited<ReturnType<typeof getCreditType>>, TError = unknown>(creditTypeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditType>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCreditType>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCreditTypeQueryKey(creditTypeId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditType>>> = () => getCreditType(creditTypeId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(creditTypeId), ...queryOptions}}

export type GetCreditTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditType>>>
export type GetCreditTypeQueryError = unknown

/**
 * @summary Get Credit Type
 */
export const useGetCreditType = <TData = Awaited<ReturnType<typeof getCreditType>>, TError = unknown>(
 creditTypeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditType>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCreditTypeQueryOptions(creditTypeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Credit Type
 */
export const updateCreditType = (
    creditTypeId: string,
    formsCreditTypeBody: FormsCreditTypeBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditTypeSlim>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_types/${creditTypeId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsCreditTypeBody
    },
      options);
    }
  


export const getUpdateCreditTypeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCreditType>>, TError,{creditTypeId: string;data: FormsCreditTypeBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCreditType>>, TError,{creditTypeId: string;data: FormsCreditTypeBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCreditType>>, {creditTypeId: string;data: FormsCreditTypeBody}> = (props) => {
          const {creditTypeId,data} = props ?? {};

          return  updateCreditType(creditTypeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateCreditTypeMutationResult = NonNullable<Awaited<ReturnType<typeof updateCreditType>>>
    export type UpdateCreditTypeMutationBody = FormsCreditTypeBody
    export type UpdateCreditTypeMutationError = unknown

    /**
 * @summary Update Credit Type
 */
export const useUpdateCreditType = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCreditType>>, TError,{creditTypeId: string;data: FormsCreditTypeBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateCreditTypeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Tenant Credit Closure Process Info
 */
export const getTenantCreditClosureProcessInfo = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantCloseInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/closing`, method: 'get'
    },
      options);
    }
  

export const getGetTenantCreditClosureProcessInfoQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/closing`] as const;
  

    
export const getGetTenantCreditClosureProcessInfoQueryOptions = <TData = Awaited<ReturnType<typeof getTenantCreditClosureProcessInfo>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditClosureProcessInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditClosureProcessInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCreditClosureProcessInfoQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCreditClosureProcessInfo>>> = () => getTenantCreditClosureProcessInfo(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantCreditClosureProcessInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCreditClosureProcessInfo>>>
export type GetTenantCreditClosureProcessInfoQueryError = unknown

/**
 * @summary Get Tenant Credit Closure Process Info
 */
export const useGetTenantCreditClosureProcessInfo = <TData = Awaited<ReturnType<typeof getTenantCreditClosureProcessInfo>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditClosureProcessInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantCreditClosureProcessInfoQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Cancel Tenant Closure Process
 */
export const cancelTenantClosureProcess = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/closing/actions/cancel`, method: 'post'
    },
      options);
    }
  


export const getCancelTenantClosureProcessMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTenantClosureProcess>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelTenantClosureProcess>>, TError,{tenantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelTenantClosureProcess>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  cancelTenantClosureProcess(tenantId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelTenantClosureProcessMutationResult = NonNullable<Awaited<ReturnType<typeof cancelTenantClosureProcess>>>
    
    export type CancelTenantClosureProcessMutationError = unknown

    /**
 * @summary Cancel Tenant Closure Process
 */
export const useCancelTenantClosureProcess = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTenantClosureProcess>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelTenantClosureProcessMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Tenant Closure Reason
 */
export const getTenantClosureReason = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/closing/reasons`, method: 'get'
    },
      options);
    }
  

export const getGetTenantClosureReasonQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/closing/reasons`] as const;
  

    
export const getGetTenantClosureReasonQueryOptions = <TData = Awaited<ReturnType<typeof getTenantClosureReason>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantClosureReason>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantClosureReason>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantClosureReasonQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantClosureReason>>> = () => getTenantClosureReason(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantClosureReasonQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantClosureReason>>>
export type GetTenantClosureReasonQueryError = unknown

/**
 * @summary Get Tenant Closure Reason
 */
export const useGetTenantClosureReason = <TData = Awaited<ReturnType<typeof getTenantClosureReason>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantClosureReason>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantClosureReasonQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Run Tenant Closure Process
 */
export const runTenantClosureProcess = (
    tenantId: string,
    reason: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/closing/reasons/${reason}/actions/run`, method: 'post'
    },
      options);
    }
  


export const getRunTenantClosureProcessMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runTenantClosureProcess>>, TError,{tenantId: string;reason: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof runTenantClosureProcess>>, TError,{tenantId: string;reason: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runTenantClosureProcess>>, {tenantId: string;reason: string}> = (props) => {
          const {tenantId,reason} = props ?? {};

          return  runTenantClosureProcess(tenantId,reason,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RunTenantClosureProcessMutationResult = NonNullable<Awaited<ReturnType<typeof runTenantClosureProcess>>>
    
    export type RunTenantClosureProcessMutationError = unknown

    /**
 * @summary Run Tenant Closure Process
 */
export const useRunTenantClosureProcess = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runTenantClosureProcess>>, TError,{tenantId: string;reason: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRunTenantClosureProcessMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Tenant Closure Policy
 */
export const getTenantClosurePolicy = (
    tenantId: string,
    reason: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantClosePolicy>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/closing/reasons/${reason}/policies`, method: 'get'
    },
      options);
    }
  

export const getGetTenantClosurePolicyQueryKey = (tenantId: string,
    reason: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/closing/reasons/${reason}/policies`] as const;
  

    
export const getGetTenantClosurePolicyQueryOptions = <TData = Awaited<ReturnType<typeof getTenantClosurePolicy>>, TError = unknown>(tenantId: string,
    reason: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantClosurePolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantClosurePolicy>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantClosurePolicyQueryKey(tenantId,reason);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantClosurePolicy>>> = () => getTenantClosurePolicy(tenantId,reason, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId && reason), ...queryOptions}}

export type GetTenantClosurePolicyQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantClosurePolicy>>>
export type GetTenantClosurePolicyQueryError = unknown

/**
 * @summary Get Tenant Closure Policy
 */
export const useGetTenantClosurePolicy = <TData = Awaited<ReturnType<typeof getTenantClosurePolicy>>, TError = unknown>(
 tenantId: string,
    reason: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantClosurePolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantClosurePolicyQueryOptions(tenantId,reason,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Tenant Credit Risk Close Policy
 */
export const getTenantCreditRiskClosePolicy = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantClosePolicy>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/risk_close_policy`, method: 'get'
    },
      options);
    }
  

export const getGetTenantCreditRiskClosePolicyQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/risk_close_policy`] as const;
  

    
export const getGetTenantCreditRiskClosePolicyQueryOptions = <TData = Awaited<ReturnType<typeof getTenantCreditRiskClosePolicy>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskClosePolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskClosePolicy>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCreditRiskClosePolicyQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCreditRiskClosePolicy>>> = () => getTenantCreditRiskClosePolicy(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantCreditRiskClosePolicyQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCreditRiskClosePolicy>>>
export type GetTenantCreditRiskClosePolicyQueryError = unknown

/**
 * @summary Get Tenant Credit Risk Close Policy
 */
export const useGetTenantCreditRiskClosePolicy = <TData = Awaited<ReturnType<typeof getTenantCreditRiskClosePolicy>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskClosePolicy>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantCreditRiskClosePolicyQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Tenant Credit Risk Close Info
 */
export const getTenantCreditRiskCloseInfo = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantCloseInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/tenant_risk_close`, method: 'get'
    },
      options);
    }
  

export const getGetTenantCreditRiskCloseInfoQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/tenant_risk_close`] as const;
  

    
export const getGetTenantCreditRiskCloseInfoQueryOptions = <TData = Awaited<ReturnType<typeof getTenantCreditRiskCloseInfo>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskCloseInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskCloseInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCreditRiskCloseInfoQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCreditRiskCloseInfo>>> = () => getTenantCreditRiskCloseInfo(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantCreditRiskCloseInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCreditRiskCloseInfo>>>
export type GetTenantCreditRiskCloseInfoQueryError = unknown

/**
 * @summary Get Tenant Credit Risk Close Info
 */
export const useGetTenantCreditRiskCloseInfo = <TData = Awaited<ReturnType<typeof getTenantCreditRiskCloseInfo>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskCloseInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantCreditRiskCloseInfoQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary CancelTenant Credit Risk Close
 */
export const cancelTenantCreditRiskClose = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/tenant_risk_close/actions/cancel`, method: 'post'
    },
      options);
    }
  


export const getCancelTenantCreditRiskCloseMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTenantCreditRiskClose>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelTenantCreditRiskClose>>, TError,{tenantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelTenantCreditRiskClose>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  cancelTenantCreditRiskClose(tenantId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelTenantCreditRiskCloseMutationResult = NonNullable<Awaited<ReturnType<typeof cancelTenantCreditRiskClose>>>
    
    export type CancelTenantCreditRiskCloseMutationError = unknown

    /**
 * @summary CancelTenant Credit Risk Close
 */
export const useCancelTenantCreditRiskClose = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTenantCreditRiskClose>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelTenantCreditRiskCloseMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Run Tenant Credit Risk Close
 */
export const runTenantCreditRiskClose = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/tenant_risk_close/actions/run`, method: 'post'
    },
      options);
    }
  


export const getRunTenantCreditRiskCloseMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runTenantCreditRiskClose>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof runTenantCreditRiskClose>>, TError,{tenantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runTenantCreditRiskClose>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  runTenantCreditRiskClose(tenantId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RunTenantCreditRiskCloseMutationResult = NonNullable<Awaited<ReturnType<typeof runTenantCreditRiskClose>>>
    
    export type RunTenantCreditRiskCloseMutationError = unknown

    /**
 * @summary Run Tenant Credit Risk Close
 */
export const useRunTenantCreditRiskClose = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runTenantCreditRiskClose>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRunTenantCreditRiskCloseMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Tenant CreditRisk Level
 */
export const getTenantCreditRiskLevel = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreditRiskLevel>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/credit_risk_level`, method: 'get'
    },
      options);
    }
  

export const getGetTenantCreditRiskLevelQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/credit_risk_level`] as const;
  

    
export const getGetTenantCreditRiskLevelQueryOptions = <TData = Awaited<ReturnType<typeof getTenantCreditRiskLevel>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskLevel>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskLevel>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCreditRiskLevelQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCreditRiskLevel>>> = () => getTenantCreditRiskLevel(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantCreditRiskLevelQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCreditRiskLevel>>>
export type GetTenantCreditRiskLevelQueryError = unknown

/**
 * @summary Get Tenant CreditRisk Level
 */
export const useGetTenantCreditRiskLevel = <TData = Awaited<ReturnType<typeof getTenantCreditRiskLevel>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCreditRiskLevel>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantCreditRiskLevelQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Tenant CreditRisk Level
 */
export const updateTenantCreditRiskLevel = (
    tenantId: string,
    formsCreditRiskLevelIDInput: FormsCreditRiskLevelIDInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/credit_risk_level`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsCreditRiskLevelIDInput
    },
      options);
    }
  


export const getUpdateTenantCreditRiskLevelMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenantCreditRiskLevel>>, TError,{tenantId: string;data: FormsCreditRiskLevelIDInput}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTenantCreditRiskLevel>>, TError,{tenantId: string;data: FormsCreditRiskLevelIDInput}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTenantCreditRiskLevel>>, {tenantId: string;data: FormsCreditRiskLevelIDInput}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  updateTenantCreditRiskLevel(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateTenantCreditRiskLevelMutationResult = NonNullable<Awaited<ReturnType<typeof updateTenantCreditRiskLevel>>>
    export type UpdateTenantCreditRiskLevelMutationBody = FormsCreditRiskLevelIDInput
    export type UpdateTenantCreditRiskLevelMutationError = unknown

    /**
 * @summary Update Tenant CreditRisk Level
 */
export const useUpdateTenantCreditRiskLevel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenantCreditRiskLevel>>, TError,{tenantId: string;data: FormsCreditRiskLevelIDInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateTenantCreditRiskLevelMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetCreditRiskLevelCostQuota
 */
export const getCreditRiskLevelCostQuota = (
    creditRiskLevelId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostQuota>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}/cost_quota`, method: 'get'
    },
      options);
    }
  

export const getGetCreditRiskLevelCostQuotaQueryKey = (creditRiskLevelId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}/cost_quota`] as const;
  

    
export const getGetCreditRiskLevelCostQuotaQueryOptions = <TData = Awaited<ReturnType<typeof getCreditRiskLevelCostQuota>>, TError = unknown>(creditRiskLevelId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskLevelCostQuota>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskLevelCostQuota>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCreditRiskLevelCostQuotaQueryKey(creditRiskLevelId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCreditRiskLevelCostQuota>>> = () => getCreditRiskLevelCostQuota(creditRiskLevelId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(creditRiskLevelId), ...queryOptions}}

export type GetCreditRiskLevelCostQuotaQueryResult = NonNullable<Awaited<ReturnType<typeof getCreditRiskLevelCostQuota>>>
export type GetCreditRiskLevelCostQuotaQueryError = unknown

/**
 * @summary GetCreditRiskLevelCostQuota
 */
export const useGetCreditRiskLevelCostQuota = <TData = Awaited<ReturnType<typeof getCreditRiskLevelCostQuota>>, TError = unknown>(
 creditRiskLevelId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCreditRiskLevelCostQuota>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCreditRiskLevelCostQuotaQueryOptions(creditRiskLevelId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary UpdateCreditRiskLevelCostQuota
 */
export const updateCreditRiskLevelCostQuota = (
    creditRiskLevelId: string,
    formsCostQuotaBody: FormsCostQuotaBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostQuota>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/credit_risk_levels/${creditRiskLevelId}/cost_quota`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsCostQuotaBody
    },
      options);
    }
  


export const getUpdateCreditRiskLevelCostQuotaMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCreditRiskLevelCostQuota>>, TError,{creditRiskLevelId: string;data: FormsCostQuotaBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCreditRiskLevelCostQuota>>, TError,{creditRiskLevelId: string;data: FormsCostQuotaBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCreditRiskLevelCostQuota>>, {creditRiskLevelId: string;data: FormsCostQuotaBody}> = (props) => {
          const {creditRiskLevelId,data} = props ?? {};

          return  updateCreditRiskLevelCostQuota(creditRiskLevelId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateCreditRiskLevelCostQuotaMutationResult = NonNullable<Awaited<ReturnType<typeof updateCreditRiskLevelCostQuota>>>
    export type UpdateCreditRiskLevelCostQuotaMutationBody = FormsCostQuotaBody
    export type UpdateCreditRiskLevelCostQuotaMutationError = unknown

    /**
 * @summary UpdateCreditRiskLevelCostQuota
 */
export const useUpdateCreditRiskLevelCostQuota = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCreditRiskLevelCostQuota>>, TError,{creditRiskLevelId: string;data: FormsCostQuotaBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateCreditRiskLevelCostQuotaMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * List tenant discount
 * @summary List Tenant Discount
 */
export const listTenantDiscount = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/discounts`, method: 'get'
    },
      options);
    }
  

export const getListTenantDiscountQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/discounts`] as const;
  

    
export const getListTenantDiscountQueryOptions = <TData = Awaited<ReturnType<typeof listTenantDiscount>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenantDiscount>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantDiscountQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantDiscount>>> = () => listTenantDiscount(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type ListTenantDiscountQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantDiscount>>>
export type ListTenantDiscountQueryError = unknown

/**
 * @summary List Tenant Discount
 */
export const useListTenantDiscount = <TData = Awaited<ReturnType<typeof listTenantDiscount>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantDiscountQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Create tenant discount
 * @summary Create Tenant Discount
 */
export const createTenantDiscount = (
    tenantId: string,
    formsDiscountBody: FormsDiscountBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/discounts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountBody
    },
      options);
    }
  


export const getCreateTenantDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTenantDiscount>>, TError,{tenantId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createTenantDiscount>>, TError,{tenantId: string;data: FormsDiscountBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTenantDiscount>>, {tenantId: string;data: FormsDiscountBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  createTenantDiscount(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateTenantDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof createTenantDiscount>>>
    export type CreateTenantDiscountMutationBody = FormsDiscountBody
    export type CreateTenantDiscountMutationError = unknown

    /**
 * @summary Create Tenant Discount
 */
export const useCreateTenantDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTenantDiscount>>, TError,{tenantId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateTenantDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Update tenant discount
 * @summary Update Tenant Discount
 */
export const updateTenantDiscount = (
    tenantId: string,
    discountId: string,
    formsDiscountBody: FormsDiscountBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/discounts/${discountId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountBody
    },
      options);
    }
  


export const getUpdateTenantDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenantDiscount>>, TError,{tenantId: string;discountId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTenantDiscount>>, TError,{tenantId: string;discountId: string;data: FormsDiscountBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTenantDiscount>>, {tenantId: string;discountId: string;data: FormsDiscountBody}> = (props) => {
          const {tenantId,discountId,data} = props ?? {};

          return  updateTenantDiscount(tenantId,discountId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateTenantDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof updateTenantDiscount>>>
    export type UpdateTenantDiscountMutationBody = FormsDiscountBody
    export type UpdateTenantDiscountMutationError = unknown

    /**
 * @summary Update Tenant Discount
 */
export const useUpdateTenantDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTenantDiscount>>, TError,{tenantId: string;discountId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateTenantDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Reclaim tenant discount
 * @summary Reclaim Tenant Discount
 */
export const reclaimTenantDiscount = (
    tenantId: string,
    discountId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/discounts/${discountId}`, method: 'delete'
    },
      options);
    }
  


export const getReclaimTenantDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimTenantDiscount>>, TError,{tenantId: string;discountId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof reclaimTenantDiscount>>, TError,{tenantId: string;discountId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reclaimTenantDiscount>>, {tenantId: string;discountId: string}> = (props) => {
          const {tenantId,discountId} = props ?? {};

          return  reclaimTenantDiscount(tenantId,discountId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReclaimTenantDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof reclaimTenantDiscount>>>
    
    export type ReclaimTenantDiscountMutationError = unknown

    /**
 * @summary Reclaim Tenant Discount
 */
export const useReclaimTenantDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimTenantDiscount>>, TError,{tenantId: string;discountId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReclaimTenantDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Confirm Invoice
 */
export const confirmInvoice = (
    tenantId: string,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/invoices/${invoiceId}/confirm`, method: 'put'
    },
      options);
    }
  


export const getConfirmInvoiceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof confirmInvoice>>, TError,{tenantId: string;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof confirmInvoice>>, TError,{tenantId: string;invoiceId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof confirmInvoice>>, {tenantId: string;invoiceId: string}> = (props) => {
          const {tenantId,invoiceId} = props ?? {};

          return  confirmInvoice(tenantId,invoiceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ConfirmInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof confirmInvoice>>>
    
    export type ConfirmInvoiceMutationError = unknown

    /**
 * @summary Confirm Invoice
 */
export const useConfirmInvoice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof confirmInvoice>>, TError,{tenantId: string;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getConfirmInvoiceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Send Invoice
 */
export const sendInvoice = (
    tenantId: string,
    invoiceId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/invoices/${invoiceId}/send`, method: 'put'
    },
      options);
    }
  


export const getSendInvoiceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendInvoice>>, TError,{tenantId: string;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof sendInvoice>>, TError,{tenantId: string;invoiceId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendInvoice>>, {tenantId: string;invoiceId: string}> = (props) => {
          const {tenantId,invoiceId} = props ?? {};

          return  sendInvoice(tenantId,invoiceId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SendInvoiceMutationResult = NonNullable<Awaited<ReturnType<typeof sendInvoice>>>
    
    export type SendInvoiceMutationError = unknown

    /**
 * @summary Send Invoice
 */
export const useSendInvoice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendInvoice>>, TError,{tenantId: string;invoiceId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSendInvoiceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cost Units
 */
export const postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasures = (
    tenantId: string,
    formsUnitCostsInputBody: FormsUnitCostsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitCosts>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/measures`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsUnitCostsInputBody
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasures>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasures>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasures>>, {tenantId: string;data: FormsUnitCostsInputBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasures(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasures>>>
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresMutationBody = FormsUnitCostsInputBody
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresMutationError = unknown

    /**
 * @summary Cost Units
 */
export const usePostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasures = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasures>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Cost Units Dryrun
 */
export const postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrun = (
    tenantId: string,
    formsUnitCostsInputBody: FormsUnitCostsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitCosts>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/measures/dryrun`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsUnitCostsInputBody
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrunMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrun>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrun>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrun>>, {tenantId: string;data: FormsUnitCostsInputBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrun(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrunMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrun>>>
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrunMutationBody = FormsUnitCostsInputBody
    export type PostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrunMutationError = unknown

    /**
 * @summary Cost Units Dryrun
 */
export const usePostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrun = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrun>>, TError,{tenantId: string;data: FormsUnitCostsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdMeasuresDryrunMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Units
 */
export const getApiV1DevopsBillingProxyApiV1BillingUnits = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitInfo[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/units`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1DevopsBillingProxyApiV1BillingUnitsQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/units`] as const;
  

    
export const getGetApiV1DevopsBillingProxyApiV1BillingUnitsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnits>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnits>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1DevopsBillingProxyApiV1BillingUnitsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnits>>> = () => getApiV1DevopsBillingProxyApiV1BillingUnits(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiV1DevopsBillingProxyApiV1BillingUnitsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnits>>>
export type GetApiV1DevopsBillingProxyApiV1BillingUnitsQueryError = unknown

/**
 * @summary List Units
 */
export const useGetApiV1DevopsBillingProxyApiV1BillingUnits = <TData = Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnits>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1DevopsBillingProxyApiV1BillingUnitsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create Unit
 */
export const postApiV1DevopsBillingProxyApiV1BillingUnits = (
    formsUnitInfo: FormsUnitInfo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/units`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsUnitInfo
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingUnitsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnits>>, TError,{data: FormsUnitInfo}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnits>>, TError,{data: FormsUnitInfo}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnits>>, {data: FormsUnitInfo}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingUnits(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingUnitsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnits>>>
    export type PostApiV1DevopsBillingProxyApiV1BillingUnitsMutationBody = FormsUnitInfo
    export type PostApiV1DevopsBillingProxyApiV1BillingUnitsMutationError = unknown

    /**
 * @summary Create Unit
 */
export const usePostApiV1DevopsBillingProxyApiV1BillingUnits = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnits>>, TError,{data: FormsUnitInfo}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingUnitsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Bulk CreateOrUpdate Unit
 */
export const postApiV1DevopsBillingProxyApiV1BillingUnitsBulk = (
    formsUnitInfo: FormsUnitInfo[],
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/units/_bulk`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsUnitInfo
    },
      options);
    }
  


export const getPostApiV1DevopsBillingProxyApiV1BillingUnitsBulkMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnitsBulk>>, TError,{data: FormsUnitInfo[]}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnitsBulk>>, TError,{data: FormsUnitInfo[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnitsBulk>>, {data: FormsUnitInfo[]}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1DevopsBillingProxyApiV1BillingUnitsBulk(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1DevopsBillingProxyApiV1BillingUnitsBulkMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnitsBulk>>>
    export type PostApiV1DevopsBillingProxyApiV1BillingUnitsBulkMutationBody = FormsUnitInfo[]
    export type PostApiV1DevopsBillingProxyApiV1BillingUnitsBulkMutationError = unknown

    /**
 * @summary Bulk CreateOrUpdate Unit
 */
export const usePostApiV1DevopsBillingProxyApiV1BillingUnitsBulk = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1DevopsBillingProxyApiV1BillingUnitsBulk>>, TError,{data: FormsUnitInfo[]}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1DevopsBillingProxyApiV1BillingUnitsBulkMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get Unit
 */
export const getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey = (
    unitKey: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/units/${unitKey}`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyQueryKey = (unitKey: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/units/${unitKey}`] as const;
  

    
export const getGetApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError = unknown>(unitKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyQueryKey(unitKey);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>> = () => getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey(unitKey, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(unitKey), ...queryOptions}}

export type GetApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>>
export type GetApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyQueryError = unknown

/**
 * @summary Get Unit
 */
export const useGetApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey = <TData = Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError = unknown>(
 unitKey: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyQueryOptions(unitKey,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Unit
 */
export const putApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey = (
    unitKey: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUnitInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/units/${unitKey}`, method: 'put'
    },
      options);
    }
  


export const getPutApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError,{unitKey: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError,{unitKey: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, {unitKey: string}> = (props) => {
          const {unitKey} = props ?? {};

          return  putApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey(unitKey,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>>
    
    export type PutApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyMutationError = unknown

    /**
 * @summary Update Unit
 */
export const usePutApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError,{unitKey: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPutApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Unit
 */
export const deleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey = (
    unitKey: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/units/${unitKey}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError,{unitKey: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError,{unitKey: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, {unitKey: string}> = (props) => {
          const {unitKey} = props ?? {};

          return  deleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey(unitKey,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>>
    
    export type DeleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyMutationError = unknown

    /**
 * @summary Delete Unit
 */
export const useDeleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKey>>, TError,{unitKey: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1DevopsBillingProxyApiV1BillingUnitsUnitKeyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List Billing Company
 */
export const listBillingCompany = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBillingCompany[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/billing_company`, method: 'get'
    },
      options);
    }
  

export const getListBillingCompanyQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/billing_company`] as const;
  

    
export const getListBillingCompanyQueryOptions = <TData = Awaited<ReturnType<typeof listBillingCompany>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listBillingCompany>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listBillingCompany>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListBillingCompanyQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listBillingCompany>>> = () => listBillingCompany(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListBillingCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof listBillingCompany>>>
export type ListBillingCompanyQueryError = unknown

/**
 * @summary List Billing Company
 */
export const useListBillingCompany = <TData = Awaited<ReturnType<typeof listBillingCompany>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listBillingCompany>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListBillingCompanyQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Tenant BillingCompany
 */
export const getBillingCompany = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantBillingCompany>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_company`, method: 'get'
    },
      options);
    }
  

export const getGetBillingCompanyQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_company`] as const;
  

    
export const getGetBillingCompanyQueryOptions = <TData = Awaited<ReturnType<typeof getBillingCompany>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillingCompany>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getBillingCompany>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillingCompanyQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillingCompany>>> = () => getBillingCompany(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetBillingCompanyQueryResult = NonNullable<Awaited<ReturnType<typeof getBillingCompany>>>
export type GetBillingCompanyQueryError = unknown

/**
 * @summary Get Tenant BillingCompany
 */
export const useGetBillingCompany = <TData = Awaited<ReturnType<typeof getBillingCompany>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillingCompany>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetBillingCompanyQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Tenant BillingCompany
 */
export const updateBillingCompany = (
    tenantId: string,
    formsTenantBillingCompanyInput: FormsTenantBillingCompanyInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantBillingCompany>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/billing_company`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsTenantBillingCompanyInput
    },
      options);
    }
  


export const getUpdateBillingCompanyMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBillingCompany>>, TError,{tenantId: string;data: FormsTenantBillingCompanyInput}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateBillingCompany>>, TError,{tenantId: string;data: FormsTenantBillingCompanyInput}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBillingCompany>>, {tenantId: string;data: FormsTenantBillingCompanyInput}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  updateBillingCompany(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateBillingCompanyMutationResult = NonNullable<Awaited<ReturnType<typeof updateBillingCompany>>>
    export type UpdateBillingCompanyMutationBody = FormsTenantBillingCompanyInput
    export type UpdateBillingCompanyMutationError = unknown

    /**
 * @summary Update Tenant BillingCompany
 */
export const useUpdateBillingCompany = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBillingCompany>>, TError,{tenantId: string;data: FormsTenantBillingCompanyInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateBillingCompanyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * List global discount
 * @summary List Global Discount
 */
export const listGlobalDiscount = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount[]>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/global-discounts`, method: 'get'
    },
      options);
    }
  

export const getListGlobalDiscountQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/global-discounts`] as const;
  

    
export const getListGlobalDiscountQueryOptions = <TData = Awaited<ReturnType<typeof listGlobalDiscount>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listGlobalDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listGlobalDiscount>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListGlobalDiscountQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listGlobalDiscount>>> = () => listGlobalDiscount(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListGlobalDiscountQueryResult = NonNullable<Awaited<ReturnType<typeof listGlobalDiscount>>>
export type ListGlobalDiscountQueryError = unknown

/**
 * @summary List Global Discount
 */
export const useListGlobalDiscount = <TData = Awaited<ReturnType<typeof listGlobalDiscount>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listGlobalDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListGlobalDiscountQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Create global discount
 * @summary Create Global Discount
 */
export const createGloablDiscount = (
    formsDiscountBody: FormsDiscountBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/global-discounts`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountBody
    },
      options);
    }
  


export const getCreateGloablDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGloablDiscount>>, TError,{data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createGloablDiscount>>, TError,{data: FormsDiscountBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGloablDiscount>>, {data: FormsDiscountBody}> = (props) => {
          const {data} = props ?? {};

          return  createGloablDiscount(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateGloablDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof createGloablDiscount>>>
    export type CreateGloablDiscountMutationBody = FormsDiscountBody
    export type CreateGloablDiscountMutationError = unknown

    /**
 * @summary Create Global Discount
 */
export const useCreateGloablDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGloablDiscount>>, TError,{data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateGloablDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Update global discount
 * @summary Update Global Discount
 */
export const updateGlobalDiscount = (
    discountId: string,
    formsDiscountBody: FormsDiscountBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscount>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/global-discounts/${discountId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountBody
    },
      options);
    }
  


export const getUpdateGlobalDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGlobalDiscount>>, TError,{discountId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateGlobalDiscount>>, TError,{discountId: string;data: FormsDiscountBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGlobalDiscount>>, {discountId: string;data: FormsDiscountBody}> = (props) => {
          const {discountId,data} = props ?? {};

          return  updateGlobalDiscount(discountId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateGlobalDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof updateGlobalDiscount>>>
    export type UpdateGlobalDiscountMutationBody = FormsDiscountBody
    export type UpdateGlobalDiscountMutationError = unknown

    /**
 * @summary Update Global Discount
 */
export const useUpdateGlobalDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGlobalDiscount>>, TError,{discountId: string;data: FormsDiscountBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateGlobalDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Reclaim global discount
 * @summary Reclaim Global Discount
 */
export const reclaimGlobalDiscount = (
    discountId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/global-discounts/${discountId}`, method: 'delete'
    },
      options);
    }
  


export const getReclaimGlobalDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimGlobalDiscount>>, TError,{discountId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof reclaimGlobalDiscount>>, TError,{discountId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reclaimGlobalDiscount>>, {discountId: string}> = (props) => {
          const {discountId} = props ?? {};

          return  reclaimGlobalDiscount(discountId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ReclaimGlobalDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof reclaimGlobalDiscount>>>
    
    export type ReclaimGlobalDiscountMutationError = unknown

    /**
 * @summary Reclaim Global Discount
 */
export const useReclaimGlobalDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reclaimGlobalDiscount>>, TError,{discountId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getReclaimGlobalDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get Global Discount
 * @summary Get Global Discount(deprecated)
 */
export const getOnDemandGlobalDiscount = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscountInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/plans/ondemand/global-discounts`, method: 'get'
    },
      options);
    }
  

export const getGetOnDemandGlobalDiscountQueryKey = () => [`/api/v1/devops/billing-proxy/api/v1/billing/plans/ondemand/global-discounts`] as const;
  

    
export const getGetOnDemandGlobalDiscountQueryOptions = <TData = Awaited<ReturnType<typeof getOnDemandGlobalDiscount>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOnDemandGlobalDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getOnDemandGlobalDiscount>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOnDemandGlobalDiscountQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOnDemandGlobalDiscount>>> = () => getOnDemandGlobalDiscount(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetOnDemandGlobalDiscountQueryResult = NonNullable<Awaited<ReturnType<typeof getOnDemandGlobalDiscount>>>
export type GetOnDemandGlobalDiscountQueryError = unknown

/**
 * @summary Get Global Discount(deprecated)
 */
export const useGetOnDemandGlobalDiscount = <TData = Awaited<ReturnType<typeof getOnDemandGlobalDiscount>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOnDemandGlobalDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOnDemandGlobalDiscountQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Update global discount
 * @summary Update Global Discount
 */
export const updateOnDemandGlobalDiscount = (
    formsDiscountInfoBody: FormsDiscountInfoBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscountInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/plans/ondemand/global-discounts`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountInfoBody
    },
      options);
    }
  


export const getUpdateOnDemandGlobalDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOnDemandGlobalDiscount>>, TError,{data: FormsDiscountInfoBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOnDemandGlobalDiscount>>, TError,{data: FormsDiscountInfoBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOnDemandGlobalDiscount>>, {data: FormsDiscountInfoBody}> = (props) => {
          const {data} = props ?? {};

          return  updateOnDemandGlobalDiscount(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateOnDemandGlobalDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof updateOnDemandGlobalDiscount>>>
    export type UpdateOnDemandGlobalDiscountMutationBody = FormsDiscountInfoBody
    export type UpdateOnDemandGlobalDiscountMutationError = unknown

    /**
 * @summary Update Global Discount
 */
export const useUpdateOnDemandGlobalDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOnDemandGlobalDiscount>>, TError,{data: FormsDiscountInfoBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateOnDemandGlobalDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get Tenant Plan Info
 * @summary Get Tenant Plan Info
 */
export const getTenantPlanInfo = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantPlanInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/plans`, method: 'get'
    },
      options);
    }
  

export const getGetTenantPlanInfoQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/plans`] as const;
  

    
export const getGetTenantPlanInfoQueryOptions = <TData = Awaited<ReturnType<typeof getTenantPlanInfo>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantPlanInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantPlanInfo>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantPlanInfoQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantPlanInfo>>> = () => getTenantPlanInfo(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetTenantPlanInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantPlanInfo>>>
export type GetTenantPlanInfoQueryError = unknown

/**
 * @summary Get Tenant Plan Info
 */
export const useGetTenantPlanInfo = <TData = Awaited<ReturnType<typeof getTenantPlanInfo>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantPlanInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantPlanInfoQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Get discount
 * @summary Get Tenant DiscountInfo
 */
export const getOndemandTenantDiscount = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscountInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/plans/ondemand/discounts`, method: 'get'
    },
      options);
    }
  

export const getGetOndemandTenantDiscountQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/plans/ondemand/discounts`] as const;
  

    
export const getGetOndemandTenantDiscountQueryOptions = <TData = Awaited<ReturnType<typeof getOndemandTenantDiscount>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOndemandTenantDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getOndemandTenantDiscount>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOndemandTenantDiscountQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOndemandTenantDiscount>>> = () => getOndemandTenantDiscount(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetOndemandTenantDiscountQueryResult = NonNullable<Awaited<ReturnType<typeof getOndemandTenantDiscount>>>
export type GetOndemandTenantDiscountQueryError = unknown

/**
 * @summary Get Tenant DiscountInfo
 */
export const useGetOndemandTenantDiscount = <TData = Awaited<ReturnType<typeof getOndemandTenantDiscount>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOndemandTenantDiscount>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOndemandTenantDiscountQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update discount
 * @summary Update Tenant DiscountInfo
 */
export const updateOndemandTenantDiscount = (
    tenantId: string,
    formsDiscountInfoBody: FormsDiscountInfoBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscountInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/plans/ondemand/discounts`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsDiscountInfoBody
    },
      options);
    }
  


export const getUpdateOndemandTenantDiscountMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOndemandTenantDiscount>>, TError,{tenantId: string;data: FormsDiscountInfoBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOndemandTenantDiscount>>, TError,{tenantId: string;data: FormsDiscountInfoBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOndemandTenantDiscount>>, {tenantId: string;data: FormsDiscountInfoBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  updateOndemandTenantDiscount(tenantId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateOndemandTenantDiscountMutationResult = NonNullable<Awaited<ReturnType<typeof updateOndemandTenantDiscount>>>
    export type UpdateOndemandTenantDiscountMutationBody = FormsDiscountInfoBody
    export type UpdateOndemandTenantDiscountMutationError = unknown

    /**
 * @summary Update Tenant DiscountInfo
 */
export const useUpdateOndemandTenantDiscount = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOndemandTenantDiscount>>, TError,{tenantId: string;data: FormsDiscountInfoBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateOndemandTenantDiscountMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * GetTenantEffectiveDiscounts
 * @summary Get Tenant Effective DiscountInfo
 */
export const getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiscountInfo>(
      {url: `/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/plans/ondemand/effective-discounts`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscountsQueryKey = (tenantId: string,) => [`/api/v1/devops/billing-proxy/api/v1/billing/tenants/${tenantId}/plans/ondemand/effective-discounts`] as const;
  

    
export const getGetApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscountsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts>>, TError = unknown>(tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscountsQueryKey(tenantId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts>>> = () => getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts(tenantId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}}

export type GetApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscountsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts>>>
export type GetApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscountsQueryError = unknown

/**
 * @summary Get Tenant Effective DiscountInfo
 */
export const useGetApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts = <TData = Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscounts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1DevopsBillingProxyApiV1BillingTenantsTenantIdPlansOndemandEffectiveDiscountsQueryOptions(tenantId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Run PD/TiKV Ctl
 */
export const runCtl = (
    clusterId: string,
    formsCtlReq: FormsCtlReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCtlResp>(
      {url: `/api/v1/devops/clusters/${clusterId}/ctl`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCtlReq
    },
      options);
    }
  


export const getRunCtlMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runCtl>>, TError,{clusterId: string;data: FormsCtlReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof runCtl>>, TError,{clusterId: string;data: FormsCtlReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof runCtl>>, {clusterId: string;data: FormsCtlReq}> = (props) => {
          const {clusterId,data} = props ?? {};

          return  runCtl(clusterId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type RunCtlMutationResult = NonNullable<Awaited<ReturnType<typeof runCtl>>>
    export type RunCtlMutationBody = FormsCtlReq
    export type RunCtlMutationError = unknown

    /**
 * @summary Run PD/TiKV Ctl
 */
export const useRunCtl = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof runCtl>>, TError,{clusterId: string;data: FormsCtlReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getRunCtlMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get TiDB Network Topology
 */
export const getNetworkTopology = (
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetNetworkTopologyResp>(
      {url: `/api/v1/devops/clusters/${clusterId}/topology`, method: 'get'
    },
      options);
    }
  

export const getGetNetworkTopologyQueryKey = (clusterId: string,) => [`/api/v1/devops/clusters/${clusterId}/topology`] as const;
  

    
export const getGetNetworkTopologyQueryOptions = <TData = Awaited<ReturnType<typeof getNetworkTopology>>, TError = unknown>(clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNetworkTopology>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getNetworkTopology>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetNetworkTopologyQueryKey(clusterId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNetworkTopology>>> = () => getNetworkTopology(clusterId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(clusterId), ...queryOptions}}

export type GetNetworkTopologyQueryResult = NonNullable<Awaited<ReturnType<typeof getNetworkTopology>>>
export type GetNetworkTopologyQueryError = unknown

/**
 * @summary Get TiDB Network Topology
 */
export const useGetNetworkTopology = <TData = Awaited<ReturnType<typeof getNetworkTopology>>, TError = unknown>(
 clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNetworkTopology>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNetworkTopologyQueryOptions(clusterId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Config Change for a Specific PR
 */
export const getConfigChangeDiff = (
    params: GetConfigChangeDiffParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDiffConfigChangeResp>(
      {url: `/api/v1/devops/secondaryconfirm/diff`, method: 'get',
        params
    },
      options);
    }
  

export const getGetConfigChangeDiffQueryKey = (params: GetConfigChangeDiffParams,) => [`/api/v1/devops/secondaryconfirm/diff`, ...(params ? [params]: [])] as const;
  

    
export const getGetConfigChangeDiffQueryOptions = <TData = Awaited<ReturnType<typeof getConfigChangeDiff>>, TError = unknown>(params: GetConfigChangeDiffParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConfigChangeDiff>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getConfigChangeDiff>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetConfigChangeDiffQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfigChangeDiff>>> = () => getConfigChangeDiff(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetConfigChangeDiffQueryResult = NonNullable<Awaited<ReturnType<typeof getConfigChangeDiff>>>
export type GetConfigChangeDiffQueryError = unknown

/**
 * @summary Get Config Change for a Specific PR
 */
export const useGetConfigChangeDiff = <TData = Awaited<ReturnType<typeof getConfigChangeDiff>>, TError = unknown>(
 params: GetConfigChangeDiffParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConfigChangeDiff>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetConfigChangeDiffQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Config Changes for a cluster
 */
export const listConfigChanges = (
    params: ListConfigChangesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListConfigChangeResp>(
      {url: `/api/v1/devops/secondaryconfirm/list`, method: 'get',
        params
    },
      options);
    }
  

export const getListConfigChangesQueryKey = (params: ListConfigChangesParams,) => [`/api/v1/devops/secondaryconfirm/list`, ...(params ? [params]: [])] as const;
  

    
export const getListConfigChangesQueryOptions = <TData = Awaited<ReturnType<typeof listConfigChanges>>, TError = unknown>(params: ListConfigChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listConfigChanges>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listConfigChanges>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListConfigChangesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listConfigChanges>>> = () => listConfigChanges(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListConfigChangesQueryResult = NonNullable<Awaited<ReturnType<typeof listConfigChanges>>>
export type ListConfigChangesQueryError = unknown

/**
 * @summary Get Config Changes for a cluster
 */
export const useListConfigChanges = <TData = Awaited<ReturnType<typeof listConfigChanges>>, TError = unknown>(
 params: ListConfigChangesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listConfigChanges>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListConfigChangesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * ApplyConfigChange
 * @summary ApplyConfigChange
 */
export const applyConfigChange = (
    formsApplyConfigChangeReq: FormsApplyConfigChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/v1/devops/secondaryconfirm/apply`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsApplyConfigChangeReq
    },
      options);
    }
  


export const getApplyConfigChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof applyConfigChange>>, TError,{data: FormsApplyConfigChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof applyConfigChange>>, TError,{data: FormsApplyConfigChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof applyConfigChange>>, {data: FormsApplyConfigChangeReq}> = (props) => {
          const {data} = props ?? {};

          return  applyConfigChange(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type ApplyConfigChangeMutationResult = NonNullable<Awaited<ReturnType<typeof applyConfigChange>>>
    export type ApplyConfigChangeMutationBody = FormsApplyConfigChangeReq
    export type ApplyConfigChangeMutationError = unknown

    /**
 * @summary ApplyConfigChange
 */
export const useApplyConfigChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof applyConfigChange>>, TError,{data: FormsApplyConfigChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getApplyConfigChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * List cluster maintenances
 * @summary List cluster maintenances
 */
export const listClusterMaintenances = (
    params?: ListClusterMaintenancesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListMaintenancesResp>(
      {url: `/api/v1/devops/cluster/maintenances`, method: 'get',
        params
    },
      options);
    }
  

export const getListClusterMaintenancesQueryKey = (params?: ListClusterMaintenancesParams,) => [`/api/v1/devops/cluster/maintenances`, ...(params ? [params]: [])] as const;
  

    
export const getListClusterMaintenancesQueryOptions = <TData = Awaited<ReturnType<typeof listClusterMaintenances>>, TError = unknown>(params?: ListClusterMaintenancesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listClusterMaintenances>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listClusterMaintenances>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListClusterMaintenancesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listClusterMaintenances>>> = () => listClusterMaintenances(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListClusterMaintenancesQueryResult = NonNullable<Awaited<ReturnType<typeof listClusterMaintenances>>>
export type ListClusterMaintenancesQueryError = unknown

/**
 * @summary List cluster maintenances
 */
export const useListClusterMaintenances = <TData = Awaited<ReturnType<typeof listClusterMaintenances>>, TError = unknown>(
 params?: ListClusterMaintenancesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listClusterMaintenances>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListClusterMaintenancesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Create maintenance
 * @summary Create Maintenance
 */
export const createMaintenance = (
    formsCreateMaintenanceReq: FormsCreateMaintenanceReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreateMaintenanceResp>(
      {url: `/api/v1/devops/cluster/maintenances`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreateMaintenanceReq
    },
      options);
    }
  


export const getCreateMaintenanceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMaintenance>>, TError,{data: FormsCreateMaintenanceReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createMaintenance>>, TError,{data: FormsCreateMaintenanceReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMaintenance>>, {data: FormsCreateMaintenanceReq}> = (props) => {
          const {data} = props ?? {};

          return  createMaintenance(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateMaintenanceMutationResult = NonNullable<Awaited<ReturnType<typeof createMaintenance>>>
    export type CreateMaintenanceMutationBody = FormsCreateMaintenanceReq
    export type CreateMaintenanceMutationError = unknown

    /**
 * @summary Create Maintenance
 */
export const useCreateMaintenance = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMaintenance>>, TError,{data: FormsCreateMaintenanceReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateMaintenanceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get cluster maintenance
 * @summary Get cluster maintenance
 */
export const getClusterMaintenance = (
    id: number,
    params: GetClusterMaintenanceParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetMaintenanceResp>(
      {url: `/api/v1/devops/cluster/maintenances/${id}`, method: 'get',
        params
    },
      options);
    }
  

export const getGetClusterMaintenanceQueryKey = (id: number,
    params: GetClusterMaintenanceParams,) => [`/api/v1/devops/cluster/maintenances/${id}`, ...(params ? [params]: [])] as const;
  

    
export const getGetClusterMaintenanceQueryOptions = <TData = Awaited<ReturnType<typeof getClusterMaintenance>>, TError = unknown>(id: number,
    params: GetClusterMaintenanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterMaintenance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getClusterMaintenance>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterMaintenanceQueryKey(id,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClusterMaintenance>>> = () => getClusterMaintenance(id,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetClusterMaintenanceQueryResult = NonNullable<Awaited<ReturnType<typeof getClusterMaintenance>>>
export type GetClusterMaintenanceQueryError = unknown

/**
 * @summary Get cluster maintenance
 */
export const useGetClusterMaintenance = <TData = Awaited<ReturnType<typeof getClusterMaintenance>>, TError = unknown>(
 id: number,
    params: GetClusterMaintenanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterMaintenance>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClusterMaintenanceQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Close maintenance
 * @summary Close maintenance
 */
export const closeMaintenance = (
    id: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/v1/devops/cluster/maintenances/${id}/close`, method: 'post'
    },
      options);
    }
  


export const getCloseMaintenanceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeMaintenance>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof closeMaintenance>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof closeMaintenance>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  closeMaintenance(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloseMaintenanceMutationResult = NonNullable<Awaited<ReturnType<typeof closeMaintenance>>>
    
    export type CloseMaintenanceMutationError = unknown

    /**
 * @summary Close maintenance
 */
export const useCloseMaintenance = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeMaintenance>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloseMaintenanceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Start maintenance execution
 * @summary Start maintenance execution
 */
export const startMaintenanceExecution = (
    id: number,
    execId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/v1/devops/cluster/maintenances/${id}/executions/${execId}/start`, method: 'post'
    },
      options);
    }
  


export const getStartMaintenanceExecutionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startMaintenanceExecution>>, TError,{id: number;execId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof startMaintenanceExecution>>, TError,{id: number;execId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startMaintenanceExecution>>, {id: number;execId: number}> = (props) => {
          const {id,execId} = props ?? {};

          return  startMaintenanceExecution(id,execId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type StartMaintenanceExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof startMaintenanceExecution>>>
    
    export type StartMaintenanceExecutionMutationError = unknown

    /**
 * @summary Start maintenance execution
 */
export const useStartMaintenanceExecution = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startMaintenanceExecution>>, TError,{id: number;execId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getStartMaintenanceExecutionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Finish maintenanceExecution
 * @summary Finish maintenance execution
 */
export const finishMaintenanceExecution = (
    id: number,
    execId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/v1/devops/cluster/maintenances/${id}/executions/${execId}/finish`, method: 'post'
    },
      options);
    }
  


export const getFinishMaintenanceExecutionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finishMaintenanceExecution>>, TError,{id: number;execId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof finishMaintenanceExecution>>, TError,{id: number;execId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof finishMaintenanceExecution>>, {id: number;execId: number}> = (props) => {
          const {id,execId} = props ?? {};

          return  finishMaintenanceExecution(id,execId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type FinishMaintenanceExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof finishMaintenanceExecution>>>
    
    export type FinishMaintenanceExecutionMutationError = unknown

    /**
 * @summary Finish maintenance execution
 */
export const useFinishMaintenanceExecution = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finishMaintenanceExecution>>, TError,{id: number;execId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getFinishMaintenanceExecutionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Cancel maintenanceExecution
 * @summary Cancel maintenance execution
 */
export const cancelhMaintenanceExecution = (
    id: number,
    execId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/v1/devops/cluster/maintenances/${id}/executions/${execId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getCancelhMaintenanceExecutionMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelhMaintenanceExecution>>, TError,{id: number;execId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelhMaintenanceExecution>>, TError,{id: number;execId: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelhMaintenanceExecution>>, {id: number;execId: number}> = (props) => {
          const {id,execId} = props ?? {};

          return  cancelhMaintenanceExecution(id,execId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelhMaintenanceExecutionMutationResult = NonNullable<Awaited<ReturnType<typeof cancelhMaintenanceExecution>>>
    
    export type CancelhMaintenanceExecutionMutationError = unknown

    /**
 * @summary Cancel maintenance execution
 */
export const useCancelhMaintenanceExecution = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelhMaintenanceExecution>>, TError,{id: number;execId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelhMaintenanceExecutionMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * List release
 * @summary List release
 */
export const listReleases = (
    params: ListReleasesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListReleaseResp>(
      {url: `/api/v1/devops/release/releases`, method: 'get',
        params
    },
      options);
    }
  

export const getListReleasesQueryKey = (params: ListReleasesParams,) => [`/api/v1/devops/release/releases`, ...(params ? [params]: [])] as const;
  

    
export const getListReleasesQueryOptions = <TData = Awaited<ReturnType<typeof listReleases>>, TError = unknown>(params: ListReleasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listReleases>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listReleases>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListReleasesQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listReleases>>> = () => listReleases(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListReleasesQueryResult = NonNullable<Awaited<ReturnType<typeof listReleases>>>
export type ListReleasesQueryError = unknown

/**
 * @summary List release
 */
export const useListReleases = <TData = Awaited<ReturnType<typeof listReleases>>, TError = unknown>(
 params: ListReleasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listReleases>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListReleasesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * List Deployments
 * @summary List Deployment
 */
export const listDeployments = (
    releaseId: string,
    params?: ListDeploymentsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ListDeployments200>(
      {url: `/api/v1/devops/release/releases/${releaseId}/changes`, method: 'get',
        params
    },
      options);
    }
  

export const getListDeploymentsQueryKey = (releaseId: string,
    params?: ListDeploymentsParams,) => [`/api/v1/devops/release/releases/${releaseId}/changes`, ...(params ? [params]: [])] as const;
  

    
export const getListDeploymentsQueryOptions = <TData = Awaited<ReturnType<typeof listDeployments>>, TError = unknown>(releaseId: string,
    params?: ListDeploymentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listDeployments>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listDeployments>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeploymentsQueryKey(releaseId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeployments>>> = () => listDeployments(releaseId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(releaseId), ...queryOptions}}

export type ListDeploymentsQueryResult = NonNullable<Awaited<ReturnType<typeof listDeployments>>>
export type ListDeploymentsQueryError = unknown

/**
 * @summary List Deployment
 */
export const useListDeployments = <TData = Awaited<ReturnType<typeof listDeployments>>, TError = unknown>(
 releaseId: string,
    params?: ListDeploymentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listDeployments>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDeploymentsQueryOptions(releaseId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Get Release
 * @summary Get Release
 */
export const getRelease = (
    releaseId: string,
    params: GetReleaseParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetReleaseResp>(
      {url: `/api/v1/devops/release/releases/${releaseId}`, method: 'get',
        params
    },
      options);
    }
  

export const getGetReleaseQueryKey = (releaseId: string,
    params: GetReleaseParams,) => [`/api/v1/devops/release/releases/${releaseId}`, ...(params ? [params]: [])] as const;
  

    
export const getGetReleaseQueryOptions = <TData = Awaited<ReturnType<typeof getRelease>>, TError = unknown>(releaseId: string,
    params: GetReleaseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getRelease>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetReleaseQueryKey(releaseId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRelease>>> = () => getRelease(releaseId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(releaseId), ...queryOptions}}

export type GetReleaseQueryResult = NonNullable<Awaited<ReturnType<typeof getRelease>>>
export type GetReleaseQueryError = unknown

/**
 * @summary Get Release
 */
export const useGetRelease = <TData = Awaited<ReturnType<typeof getRelease>>, TError = unknown>(
 releaseId: string,
    params: GetReleaseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRelease>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetReleaseQueryOptions(releaseId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Init Change
 * @summary Init Change
 */
export const initChange = (
    params: InitChangeParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInitChangeResp>(
      {url: `/api/v1/devops/release/changes/default`, method: 'get',
        params
    },
      options);
    }
  

export const getInitChangeQueryKey = (params: InitChangeParams,) => [`/api/v1/devops/release/changes/default`, ...(params ? [params]: [])] as const;
  

    
export const getInitChangeQueryOptions = <TData = Awaited<ReturnType<typeof initChange>>, TError = unknown>(params: InitChangeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof initChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof initChange>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getInitChangeQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof initChange>>> = () => initChange(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type InitChangeQueryResult = NonNullable<Awaited<ReturnType<typeof initChange>>>
export type InitChangeQueryError = unknown

/**
 * @summary Init Change
 */
export const useInitChange = <TData = Awaited<ReturnType<typeof initChange>>, TError = unknown>(
 params: InitChangeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof initChange>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getInitChangeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Get Deployment Detail
 * @summary Get Deployment Detail
 */
export const getDeployment = (
    changeId: string,
    params: GetDeploymentParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetDeploymentResp>(
      {url: `/api/v1/devops/release/changes/${changeId}`, method: 'get',
        params
    },
      options);
    }
  

export const getGetDeploymentQueryKey = (changeId: string,
    params: GetDeploymentParams,) => [`/api/v1/devops/release/changes/${changeId}`, ...(params ? [params]: [])] as const;
  

    
export const getGetDeploymentQueryOptions = <TData = Awaited<ReturnType<typeof getDeployment>>, TError = unknown>(changeId: string,
    params: GetDeploymentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDeployment>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getDeployment>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDeploymentQueryKey(changeId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeployment>>> = () => getDeployment(changeId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(changeId), ...queryOptions}}

export type GetDeploymentQueryResult = NonNullable<Awaited<ReturnType<typeof getDeployment>>>
export type GetDeploymentQueryError = unknown

/**
 * @summary Get Deployment Detail
 */
export const useGetDeployment = <TData = Awaited<ReturnType<typeof getDeployment>>, TError = unknown>(
 changeId: string,
    params: GetDeploymentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDeployment>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDeploymentQueryOptions(changeId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Update Deployment
 * @summary Update Deployment
 */
export const updateDeployment = (
    changeId: string,
    formsPostDeploymentReqBody: FormsPostDeploymentReqBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUpdateDeploymentResp>(
      {url: `/api/v1/devops/release/changes/${changeId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsPostDeploymentReqBody
    },
      options);
    }
  


export const getUpdateDeploymentMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDeployment>>, TError,{changeId: string;data: FormsPostDeploymentReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDeployment>>, TError,{changeId: string;data: FormsPostDeploymentReqBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDeployment>>, {changeId: string;data: FormsPostDeploymentReqBody}> = (props) => {
          const {changeId,data} = props ?? {};

          return  updateDeployment(changeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateDeploymentMutationResult = NonNullable<Awaited<ReturnType<typeof updateDeployment>>>
    export type UpdateDeploymentMutationBody = FormsPostDeploymentReqBody
    export type UpdateDeploymentMutationError = unknown

    /**
 * @summary Update Deployment
 */
export const useUpdateDeployment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDeployment>>, TError,{changeId: string;data: FormsPostDeploymentReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateDeploymentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Post Deployment
 * @summary Post Deployment
 */
export const postDeployment = (
    formsPostDeploymentReqBody: FormsPostDeploymentReqBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PostDeployment200>(
      {url: `/api/v1/devops/release/changes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsPostDeploymentReqBody
    },
      options);
    }
  


export const getPostDeploymentMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDeployment>>, TError,{data: FormsPostDeploymentReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postDeployment>>, TError,{data: FormsPostDeploymentReqBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postDeployment>>, {data: FormsPostDeploymentReqBody}> = (props) => {
          const {data} = props ?? {};

          return  postDeployment(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostDeploymentMutationResult = NonNullable<Awaited<ReturnType<typeof postDeployment>>>
    export type PostDeploymentMutationBody = FormsPostDeploymentReqBody
    export type PostDeploymentMutationError = unknown

    /**
 * @summary Post Deployment
 */
export const usePostDeployment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postDeployment>>, TError,{data: FormsPostDeploymentReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostDeploymentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Deploy PR
 * @summary Deploy Pr
 */
export const deployPr = (
    changeId: string,
    order: number,
    formsDeployPrReq: FormsDeployPrReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsDeployPrResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/deployments/${order}/deploy`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsDeployPrReq
    },
      options);
    }
  


export const getDeployPrMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployPr>>, TError,{changeId: string;order: number;data: FormsDeployPrReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deployPr>>, TError,{changeId: string;order: number;data: FormsDeployPrReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deployPr>>, {changeId: string;order: number;data: FormsDeployPrReq}> = (props) => {
          const {changeId,order,data} = props ?? {};

          return  deployPr(changeId,order,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeployPrMutationResult = NonNullable<Awaited<ReturnType<typeof deployPr>>>
    export type DeployPrMutationBody = FormsDeployPrReq
    export type DeployPrMutationError = unknown

    /**
 * @summary Deploy Pr
 */
export const useDeployPr = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deployPr>>, TError,{changeId: string;order: number;data: FormsDeployPrReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeployPrMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Determine if order is ready
 * @summary if order is ready
 */
export const isOrderReady = (
    changeId: string,
    order: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsIsOrderReadyResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/deployments/${order}/ready`, method: 'get'
    },
      options);
    }
  

export const getIsOrderReadyQueryKey = (changeId: string,
    order: number,) => [`/api/v1/devops/release/changes/${changeId}/deployments/${order}/ready`] as const;
  

    
export const getIsOrderReadyQueryOptions = <TData = Awaited<ReturnType<typeof isOrderReady>>, TError = unknown>(changeId: string,
    order: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isOrderReady>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof isOrderReady>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getIsOrderReadyQueryKey(changeId,order);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof isOrderReady>>> = () => isOrderReady(changeId,order, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(changeId && order), ...queryOptions}}

export type IsOrderReadyQueryResult = NonNullable<Awaited<ReturnType<typeof isOrderReady>>>
export type IsOrderReadyQueryError = unknown

/**
 * @summary if order is ready
 */
export const useIsOrderReady = <TData = Awaited<ReturnType<typeof isOrderReady>>, TError = unknown>(
 changeId: string,
    order: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof isOrderReady>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getIsOrderReadyQueryOptions(changeId,order,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Verify Deploy result
 * @summary Deploy
 */
export const verifyDeploy = (
    changeId: string,
    order: number,
    formsVerifyDeployReq: FormsVerifyDeployReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsVerifyDeployResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/deployments/${order}/verify`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsVerifyDeployReq
    },
      options);
    }
  


export const getVerifyDeployMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyDeploy>>, TError,{changeId: string;order: number;data: FormsVerifyDeployReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof verifyDeploy>>, TError,{changeId: string;order: number;data: FormsVerifyDeployReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof verifyDeploy>>, {changeId: string;order: number;data: FormsVerifyDeployReq}> = (props) => {
          const {changeId,order,data} = props ?? {};

          return  verifyDeploy(changeId,order,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type VerifyDeployMutationResult = NonNullable<Awaited<ReturnType<typeof verifyDeploy>>>
    export type VerifyDeployMutationBody = FormsVerifyDeployReq
    export type VerifyDeployMutationError = unknown

    /**
 * @summary Deploy
 */
export const useVerifyDeploy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof verifyDeploy>>, TError,{changeId: string;order: number;data: FormsVerifyDeployReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getVerifyDeployMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Cancel Change
 * @summary Cancel
 */
export const cancelChange = (
    changeId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCancelChangeResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/cancel`, method: 'post'
    },
      options);
    }
  


export const getCancelChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelChange>>, TError,{changeId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof cancelChange>>, TError,{changeId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelChange>>, {changeId: string}> = (props) => {
          const {changeId} = props ?? {};

          return  cancelChange(changeId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CancelChangeMutationResult = NonNullable<Awaited<ReturnType<typeof cancelChange>>>
    
    export type CancelChangeMutationError = unknown

    /**
 * @summary Cancel
 */
export const useCancelChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelChange>>, TError,{changeId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCancelChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Submit Change
 * @summary Submit
 */
export const submitChange = (
    changeId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSubmitChangeResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/submit`, method: 'post'
    },
      options);
    }
  


export const getSubmitChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitChange>>, TError,{changeId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof submitChange>>, TError,{changeId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitChange>>, {changeId: string}> = (props) => {
          const {changeId} = props ?? {};

          return  submitChange(changeId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type SubmitChangeMutationResult = NonNullable<Awaited<ReturnType<typeof submitChange>>>
    
    export type SubmitChangeMutationError = unknown

    /**
 * @summary Submit
 */
export const useSubmitChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitChange>>, TError,{changeId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getSubmitChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Close Change
 * @summary Close
 */
export const closeChange = (
    changeId: string,
    formsCloseChangeReq: FormsCloseChangeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCloseChangeResp>(
      {url: `/api/v1/devops/release/changes/${changeId}/close`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCloseChangeReq
    },
      options);
    }
  


export const getCloseChangeMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeChange>>, TError,{changeId: string;data: FormsCloseChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof closeChange>>, TError,{changeId: string;data: FormsCloseChangeReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof closeChange>>, {changeId: string;data: FormsCloseChangeReq}> = (props) => {
          const {changeId,data} = props ?? {};

          return  closeChange(changeId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CloseChangeMutationResult = NonNullable<Awaited<ReturnType<typeof closeChange>>>
    export type CloseChangeMutationBody = FormsCloseChangeReq
    export type CloseChangeMutationError = unknown

    /**
 * @summary Close
 */
export const useCloseChange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeChange>>, TError,{changeId: string;data: FormsCloseChangeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCloseChangeMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * Get Release Applications
 * @summary Get Release Applications
 */
export const getApplications = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetApplicationsResp>(
      {url: `/api/v1/devops/applications`, method: 'get'
    },
      options);
    }
  

export const getGetApplicationsQueryKey = () => [`/api/v1/devops/applications`] as const;
  

    
export const getGetApplicationsQueryOptions = <TData = Awaited<ReturnType<typeof getApplications>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApplicationsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApplications>>> = () => getApplications(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApplicationsQueryResult = NonNullable<Awaited<ReturnType<typeof getApplications>>>
export type GetApplicationsQueryError = unknown

/**
 * @summary Get Release Applications
 */
export const useGetApplications = <TData = Awaited<ReturnType<typeof getApplications>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApplications>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApplicationsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Get Audit Logs
 * @summary Deploy Change
 */
export const getAuditLogs = (
    params: GetAuditLogsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetAuditLogResp>(
      {url: `/api/v1/devops/audit/changes`, method: 'get',
        params
    },
      options);
    }
  

export const getGetAuditLogsQueryKey = (params: GetAuditLogsParams,) => [`/api/v1/devops/audit/changes`, ...(params ? [params]: [])] as const;
  

    
export const getGetAuditLogsQueryOptions = <TData = Awaited<ReturnType<typeof getAuditLogs>>, TError = unknown>(params: GetAuditLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAuditLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getAuditLogs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAuditLogsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuditLogs>>> = () => getAuditLogs(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetAuditLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getAuditLogs>>>
export type GetAuditLogsQueryError = unknown

/**
 * @summary Deploy Change
 */
export const useGetAuditLogs = <TData = Awaited<ReturnType<typeof getAuditLogs>>, TError = unknown>(
 params: GetAuditLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAuditLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAuditLogsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * Get Audit Logs for some Maintenance
 */
export const getAuditMaintenanceLogs = (
    params: GetAuditMaintenanceLogsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetAuditLogResp>(
      {url: `/api/v1/devops/audit/cluster-maintenance`, method: 'get',
        params
    },
      options);
    }
  

export const getGetAuditMaintenanceLogsQueryKey = (params: GetAuditMaintenanceLogsParams,) => [`/api/v1/devops/audit/cluster-maintenance`, ...(params ? [params]: [])] as const;
  

    
export const getGetAuditMaintenanceLogsQueryOptions = <TData = Awaited<ReturnType<typeof getAuditMaintenanceLogs>>, TError = unknown>(params: GetAuditMaintenanceLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAuditMaintenanceLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getAuditMaintenanceLogs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAuditMaintenanceLogsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuditMaintenanceLogs>>> = () => getAuditMaintenanceLogs(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetAuditMaintenanceLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getAuditMaintenanceLogs>>>
export type GetAuditMaintenanceLogsQueryError = unknown

export const useGetAuditMaintenanceLogs = <TData = Awaited<ReturnType<typeof getAuditMaintenanceLogs>>, TError = unknown>(
 params: GetAuditMaintenanceLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAuditMaintenanceLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAuditMaintenanceLogsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * List certificate
 * @summary List Certificate
 */
export const listCertificates = (
    region: string,
    params?: ListCertificatesParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCertificate[]>(
      {url: `/api/v1/devops/monitoring/certificate/regions/${region}`, method: 'get',
        params
    },
      options);
    }
  

export const getListCertificatesQueryKey = (region: string,
    params?: ListCertificatesParams,) => [`/api/v1/devops/monitoring/certificate/regions/${region}`, ...(params ? [params]: [])] as const;
  

    
export const getListCertificatesQueryOptions = <TData = Awaited<ReturnType<typeof listCertificates>>, TError = unknown>(region: string,
    params?: ListCertificatesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCertificates>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listCertificates>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCertificatesQueryKey(region,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCertificates>>> = () => listCertificates(region,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(region), ...queryOptions}}

export type ListCertificatesQueryResult = NonNullable<Awaited<ReturnType<typeof listCertificates>>>
export type ListCertificatesQueryError = unknown

/**
 * @summary List Certificate
 */
export const useListCertificates = <TData = Awaited<ReturnType<typeof listCertificates>>, TError = unknown>(
 region: string,
    params?: ListCertificatesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCertificates>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCertificatesQueryOptions(region,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List All Incidents
 */
export const listIncidents = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListIncidents>(
      {url: `/api/v1/devops/incidents`, method: 'get'
    },
      options);
    }
  

export const getListIncidentsQueryKey = () => [`/api/v1/devops/incidents`] as const;
  

    
export const getListIncidentsQueryOptions = <TData = Awaited<ReturnType<typeof listIncidents>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidents>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listIncidents>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIncidentsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listIncidents>>> = () => listIncidents(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListIncidentsQueryResult = NonNullable<Awaited<ReturnType<typeof listIncidents>>>
export type ListIncidentsQueryError = unknown

/**
 * @summary List All Incidents
 */
export const useListIncidents = <TData = Awaited<ReturnType<typeof listIncidents>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidents>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListIncidentsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Create a new incident
 */
export const createIncident = (
    incidentCreateIncidentReq: IncidentCreateIncidentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/incidents`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: incidentCreateIncidentReq
    },
      options);
    }
  


export const getCreateIncidentMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncident>>, TError,{data: IncidentCreateIncidentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createIncident>>, TError,{data: IncidentCreateIncidentReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIncident>>, {data: IncidentCreateIncidentReq}> = (props) => {
          const {data} = props ?? {};

          return  createIncident(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateIncidentMutationResult = NonNullable<Awaited<ReturnType<typeof createIncident>>>
    export type CreateIncidentMutationBody = IncidentCreateIncidentReq
    export type CreateIncidentMutationError = unknown

    /**
 * @summary Create a new incident
 */
export const useCreateIncident = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncident>>, TError,{data: IncidentCreateIncidentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateIncidentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get an single Incident
 */
export const getIncident = (
    incidentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsIncident>(
      {url: `/api/v1/devops/incidents/${incidentId}`, method: 'get'
    },
      options);
    }
  

export const getGetIncidentQueryKey = (incidentId: string,) => [`/api/v1/devops/incidents/${incidentId}`] as const;
  

    
export const getGetIncidentQueryOptions = <TData = Awaited<ReturnType<typeof getIncident>>, TError = unknown>(incidentId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncident>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncident>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentQueryKey(incidentId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncident>>> = () => getIncident(incidentId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId), ...queryOptions}}

export type GetIncidentQueryResult = NonNullable<Awaited<ReturnType<typeof getIncident>>>
export type GetIncidentQueryError = unknown

/**
 * @summary Get an single Incident
 */
export const useGetIncident = <TData = Awaited<ReturnType<typeof getIncident>>, TError = unknown>(
 incidentId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncident>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentQueryOptions(incidentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update an existing incident
 */
export const updateIncident = (
    incidentId: string,
    incidentUpdateIncidentReq: IncidentUpdateIncidentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/incidents/${incidentId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: incidentUpdateIncidentReq
    },
      options);
    }
  


export const getUpdateIncidentMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncident>>, TError,{incidentId: string;data: IncidentUpdateIncidentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateIncident>>, TError,{incidentId: string;data: IncidentUpdateIncidentReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateIncident>>, {incidentId: string;data: IncidentUpdateIncidentReq}> = (props) => {
          const {incidentId,data} = props ?? {};

          return  updateIncident(incidentId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateIncidentMutationResult = NonNullable<Awaited<ReturnType<typeof updateIncident>>>
    export type UpdateIncidentMutationBody = IncidentUpdateIncidentReq
    export type UpdateIncidentMutationError = unknown

    /**
 * @summary Update an existing incident
 */
export const useUpdateIncident = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncident>>, TError,{incidentId: string;data: IncidentUpdateIncidentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateIncidentMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List log items for an incident
 */
export const listIncidentLogItems = (
    incidentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListIncidentLogItems>(
      {url: `/api/v1/devops/incidents/${incidentId}/logs`, method: 'get'
    },
      options);
    }
  

export const getListIncidentLogItemsQueryKey = (incidentId: string,) => [`/api/v1/devops/incidents/${incidentId}/logs`] as const;
  

    
export const getListIncidentLogItemsQueryOptions = <TData = Awaited<ReturnType<typeof listIncidentLogItems>>, TError = unknown>(incidentId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentLogItems>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listIncidentLogItems>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIncidentLogItemsQueryKey(incidentId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listIncidentLogItems>>> = () => listIncidentLogItems(incidentId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId), ...queryOptions}}

export type ListIncidentLogItemsQueryResult = NonNullable<Awaited<ReturnType<typeof listIncidentLogItems>>>
export type ListIncidentLogItemsQueryError = unknown

/**
 * @summary List log items for an incident
 */
export const useListIncidentLogItems = <TData = Awaited<ReturnType<typeof listIncidentLogItems>>, TError = unknown>(
 incidentId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentLogItems>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListIncidentLogItemsQueryOptions(incidentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * create Incident log item
 */
export const createIncidentLogItem = (
    incidentId: number,
    formsIncidentLogItemBody: FormsIncidentLogItemBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/logs`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsIncidentLogItemBody
    },
      options);
    }
  


export const getCreateIncidentLogItemMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentLogItem>>, TError,{incidentId: number;data: FormsIncidentLogItemBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createIncidentLogItem>>, TError,{incidentId: number;data: FormsIncidentLogItemBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIncidentLogItem>>, {incidentId: number;data: FormsIncidentLogItemBody}> = (props) => {
          const {incidentId,data} = props ?? {};

          return  createIncidentLogItem(incidentId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateIncidentLogItemMutationResult = NonNullable<Awaited<ReturnType<typeof createIncidentLogItem>>>
    export type CreateIncidentLogItemMutationBody = FormsIncidentLogItemBody
    export type CreateIncidentLogItemMutationError = ApiBaseResp

    export const useCreateIncidentLogItem = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIncidentLogItem>>, TError,{incidentId: number;data: FormsIncidentLogItemBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateIncidentLogItemMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Get a single incident log item
 */
export const getIncidentLogItem = (
    incidentId: string,
    logitemId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsIncidentLogItem>(
      {url: `/api/v1/devops/incidents/${incidentId}/logs/${logitemId}`, method: 'get'
    },
      options);
    }
  

export const getGetIncidentLogItemQueryKey = (incidentId: string,
    logitemId: string,) => [`/api/v1/devops/incidents/${incidentId}/logs/${logitemId}`] as const;
  

    
export const getGetIncidentLogItemQueryOptions = <TData = Awaited<ReturnType<typeof getIncidentLogItem>>, TError = unknown>(incidentId: string,
    logitemId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentLogItem>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncidentLogItem>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentLogItemQueryKey(incidentId,logitemId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidentLogItem>>> = () => getIncidentLogItem(incidentId,logitemId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId && logitemId), ...queryOptions}}

export type GetIncidentLogItemQueryResult = NonNullable<Awaited<ReturnType<typeof getIncidentLogItem>>>
export type GetIncidentLogItemQueryError = unknown

/**
 * @summary Get a single incident log item
 */
export const useGetIncidentLogItem = <TData = Awaited<ReturnType<typeof getIncidentLogItem>>, TError = unknown>(
 incidentId: string,
    logitemId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentLogItem>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentLogItemQueryOptions(incidentId,logitemId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * update incident log item
 */
export const updateIncidentLogItem = (
    incidentId: number,
    logitemId: number,
    formsIncidentLogItemBody: FormsIncidentLogItemBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiEmptyResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/logs/${logitemId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsIncidentLogItemBody
    },
      options);
    }
  


export const getUpdateIncidentLogItemMutationOptions = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentLogItem>>, TError,{incidentId: number;logitemId: number;data: FormsIncidentLogItemBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateIncidentLogItem>>, TError,{incidentId: number;logitemId: number;data: FormsIncidentLogItemBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateIncidentLogItem>>, {incidentId: number;logitemId: number;data: FormsIncidentLogItemBody}> = (props) => {
          const {incidentId,logitemId,data} = props ?? {};

          return  updateIncidentLogItem(incidentId,logitemId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateIncidentLogItemMutationResult = NonNullable<Awaited<ReturnType<typeof updateIncidentLogItem>>>
    export type UpdateIncidentLogItemMutationBody = FormsIncidentLogItemBody
    export type UpdateIncidentLogItemMutationError = ApiBaseResp

    export const useUpdateIncidentLogItem = <TError = ApiBaseResp,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateIncidentLogItem>>, TError,{incidentId: number;logitemId: number;data: FormsIncidentLogItemBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateIncidentLogItemMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Generate feishu message for an incident
 */
export const generateFeishuMessage = (
    incidentId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiBaseResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/feishu_message`, method: 'post'
    },
      options);
    }
  


export const getGenerateFeishuMessageMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateFeishuMessage>>, TError,{incidentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof generateFeishuMessage>>, TError,{incidentId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof generateFeishuMessage>>, {incidentId: string}> = (props) => {
          const {incidentId} = props ?? {};

          return  generateFeishuMessage(incidentId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type GenerateFeishuMessageMutationResult = NonNullable<Awaited<ReturnType<typeof generateFeishuMessage>>>
    
    export type GenerateFeishuMessageMutationError = unknown

    /**
 * @summary Generate feishu message for an incident
 */
export const useGenerateFeishuMessage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateFeishuMessage>>, TError,{incidentId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getGenerateFeishuMessageMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary List postmortem todo actions for an incident
 */
export const listIncidentPostMortemToDoAction = (
    incidentId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentListIncidentPostMortemToDoActionResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortem/todoaction`, method: 'get'
    },
      options);
    }
  

export const getListIncidentPostMortemToDoActionQueryKey = (incidentId: number,) => [`/api/v1/devops/incidents/${incidentId}/postmortem/todoaction`] as const;
  

    
export const getListIncidentPostMortemToDoActionQueryOptions = <TData = Awaited<ReturnType<typeof listIncidentPostMortemToDoAction>>, TError = unknown>(incidentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemToDoAction>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemToDoAction>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIncidentPostMortemToDoActionQueryKey(incidentId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listIncidentPostMortemToDoAction>>> = () => listIncidentPostMortemToDoAction(incidentId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId), ...queryOptions}}

export type ListIncidentPostMortemToDoActionQueryResult = NonNullable<Awaited<ReturnType<typeof listIncidentPostMortemToDoAction>>>
export type ListIncidentPostMortemToDoActionQueryError = unknown

/**
 * @summary List postmortem todo actions for an incident
 */
export const useListIncidentPostMortemToDoAction = <TData = Awaited<ReturnType<typeof listIncidentPostMortemToDoAction>>, TError = unknown>(
 incidentId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIncidentPostMortemToDoAction>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListIncidentPostMortemToDoActionQueryOptions(incidentId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get a postmortem todo action for an incident
 */
export const getIncidentPostMortemToDoAction = (
    incidentId: string,
    actionId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<IncidentGetIncidentPostMortemToDoActionResp>(
      {url: `/api/v1/devops/incidents/${incidentId}/postmortem/todoaction/${actionId}`, method: 'get'
    },
      options);
    }
  

export const getGetIncidentPostMortemToDoActionQueryKey = (incidentId: string,
    actionId: string,) => [`/api/v1/devops/incidents/${incidentId}/postmortem/todoaction/${actionId}`] as const;
  

    
export const getGetIncidentPostMortemToDoActionQueryOptions = <TData = Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError = unknown>(incidentId: string,
    actionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIncidentPostMortemToDoActionQueryKey(incidentId,actionId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>> = () => getIncidentPostMortemToDoAction(incidentId,actionId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(incidentId && actionId), ...queryOptions}}

export type GetIncidentPostMortemToDoActionQueryResult = NonNullable<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>>
export type GetIncidentPostMortemToDoActionQueryError = unknown

/**
 * @summary Get a postmortem todo action for an incident
 */
export const useGetIncidentPostMortemToDoAction = <TData = Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError = unknown>(
 incidentId: string,
    actionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIncidentPostMortemToDoAction>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetIncidentPostMortemToDoActionQueryOptions(incidentId,actionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


