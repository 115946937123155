/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type EntitySLAStatus = typeof EntitySLAStatus[keyof typeof EntitySLAStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntitySLAStatus = {
  perfect: 'perfect',
  warning: 'warning',
  breach: 'breach',
} as const;
