/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ListAlertsType = typeof ListAlertsType[keyof typeof ListAlertsType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListAlertsType = {
  internal: 'internal',
  external: 'external',
} as const;
