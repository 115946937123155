/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ListK8sUpgradeTasksStatusItem = typeof ListK8sUpgradeTasksStatusItem[keyof typeof ListK8sUpgradeTasksStatusItem];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListK8sUpgradeTasksStatusItem = {
  pending: 'pending',
  reviewing: 'reviewing',
  running: 'running',
  canceled: 'canceled',
  completed: 'completed',
} as const;
