import React, { Suspense, useMemo } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import auth from 'apiClient/auth'

import Layout from './components/layout'
import { AuthRedirect, AuthCallback } from './pages/Auth'
import { getAllowedRoutes } from './routes'
import useProfileContext, {
  UserProfileContextProvider
} from './useProfileContext'

import './App.less'

const AllowedViews = () => {
  const {
    profile: { roles = [] }
  } = useProfileContext()

  const allowedRoutes = useMemo(() => getAllowedRoutes(roles), [roles])

  return (
    <Layout>
      <Suspense fallback={null}>
        <Switch>
          {allowedRoutes.map((route) => (
            <Route key={route.path! as string} {...route} />
          ))}
        </Switch>
      </Suspense>
    </Layout>
  )
}

const ProtectedRoutes = () => {
  return auth.isAuthenticated() ? (
    <UserProfileContextProvider>
      <AllowedViews />
    </UserProfileContextProvider>
  ) : (
    <AuthRedirect />
  )
}

function App() {
  return (
    <Suspense fallback={<div />}>
      <Router basename="/">
        <Switch>
          <Redirect exact path="/" to="/overview" />
          <Route exact path="/auth_redirect" component={AuthCallback} />
          <Route path="/" component={ProtectedRoutes} />
        </Switch>
      </Router>
    </Suspense>
  )
}

export default App
