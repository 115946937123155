/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ApiErrCode = typeof ApiErrCode[keyof typeof ApiErrCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApiErrCode = {
  OK: 'OK',
  INVALID_ARGUMENT: 'INVALID_ARGUMENT',
  FAILED_PRECONDITION: 'FAILED_PRECONDITION',
  OUT_OF_RANGE: 'OUT_OF_RANGE',
  UNAUTHENTICATED: 'UNAUTHENTICATED',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  NOT_FOUND: 'NOT_FOUND',
  ABORTED: 'ABORTED',
  ALREADY_EXISTS: 'ALREADY_EXISTS',
  RESOURCE_EXHAUSTED: 'RESOURCE_EXHAUSTED',
  CANCELLED: 'CANCELLED',
  DATA_LOSS: 'DATA_LOSS',
  UNKNOWN: 'UNKNOWN',
  INTERNAL: 'INTERNAL',
  NOT_IMPLEMENTED: 'NOT_IMPLEMENTED',
  UNAVAILABLE: 'UNAVAILABLE',
  DEADLINE_EXCEEDED: 'DEADLINE_EXCEEDED',
} as const;
