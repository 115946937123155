import { lazy } from 'react'

export const Access = lazy(() => import('./Access'))
export const Audits = lazy(() => import('./Audits'))
export const Overview = lazy(() => import('./Overview'))
export const Providers = lazy(() => import('./Providers'))
export { SettingsPanel } from './Settings'
export const DBaaSBRAC = lazy(() => import('./DBaaSRBAC'))
export const Incidents = lazy(() => import('./Incidents'))
export const Devops = lazy(() => import('./Devops/Release'))

export const Deployment = lazy(
  () => import('./Devops/Release/ReleaseDetail/ReleaseOverview/ChangePortal')
)

export { MSP } from './MSP'

export const CPPO = lazy(() => import('./CPPO'))
