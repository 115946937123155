import { Button } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { Link } from 'react-router-dom'

// import { cloudConfigControllerListTemplateChanges } from 'apiClient/services/devops'
// import { CloudconfigTemplateChange } from 'apiClient/services/devops/interface'
import {
  cloudConfigControllerGetDefaultConfigurationChangeRelease,
  cloudConfigControllerListDefaultConfigurationChangeItems,
  cloudConfigControllerListDefaultConfigurationChangeReleases,
  cloudConfigControllerListDefaultConfigurationChanges
} from 'apiClient/services/devops'
import {
  CloudConfigControllerListDefaultConfigurationChangesContainItemType,
  CloudconfigDefaultConfigurationChange
} from 'apiClient/services/devops/interface'
import { useTable } from 'components/Table'
import { toLocal } from 'pages/Devops/Maintenance/Detail'

function ChangesTable({
  type
}: {
  type: CloudConfigControllerListDefaultConfigurationChangesContainItemType
}) {
  const getData = (params?: Partial<PageInfo>) =>
    cloudConfigControllerListDefaultConfigurationChanges({
      contain_item_type: type,
      page: params?.page,
      per_page: params?.pageSize
    })
  const { dataSource, loading, reload, paginationProps, onTableChange } =
    useTable<ListData<CloudconfigDefaultConfigurationChange>>(getData, [], {
      pagination: true,
      pageSize: 10
    })
  const columns: ColumnsType<CloudconfigDefaultConfigurationChange> = [
    {
      title: 'ID',
      render: (_: any, record) => {
        return record.id
      }
    },
    {
      title: 'Created At',
      render: (_: any, record) => {
        return toLocal(record.created_at)
      }
    },
    {
      title: 'Closed At',
      render: (_: any, record) => {
        return toLocal(record.closed_at)
      }
    },
    {
      title: 'Phase',
      render: (_: any, record) => {
        return record.phase
      }
    },
    {
      title: 'Reason',
      render: (_: any, record) => {
        return record.reason
      }
    },
    {
      title: 'Action',
      render: (_: any, record) => {
        return (
          <div>
            <Button type="link">
              <Link target="_blank" to={`/defaultconfig/change/${record.id}`}>
                Detail
              </Link>
            </Button>
          </div>
        )
      }
    }
  ]
  return (
    <Table<CloudconfigDefaultConfigurationChange>
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={paginationProps}
      onChange={onTableChange}
      size="small"
    />
  )
}

export default ChangesTable
