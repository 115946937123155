import { Popconfirm, Typography } from 'antd'
import { useState } from 'react'

const { Text } = Typography

interface ConfirmCellLabels {
  trigger?: string
  confirming?: string
  confirmed?: string
}

const DEFAULT_LABELS: ConfirmCellLabels = {
  trigger: 'Confirm',
  confirming: 'Confirming...',
  confirmed: 'Confirmed'
}

type Status = 'NOT_CONFIRMED' | 'CONFIRMING' | 'CONFIRMED'
export const ConfirmCell = ({
  key,
  onConfirm,
  popconfirmTitle,
  labels = DEFAULT_LABELS
}: {
  key: string
  popconfirmTitle: string
  labels?: ConfirmCellLabels
  onConfirm: () => Promise<void> | void
}) => {
  const displayLabels = { ...DEFAULT_LABELS, ...labels }
  const [status, setStatus] = useState<Status>('NOT_CONFIRMED')

  const handleConfirm = async () => {
    setStatus('CONFIRMING')
    try {
      await onConfirm()
      setStatus('CONFIRMED')
    } catch (e) {
      setStatus('NOT_CONFIRMED')
    }
  }

  return status === 'CONFIRMED' ? (
    <Text>{displayLabels.confirmed}</Text>
  ) : (
    <Popconfirm
      key={key}
      disabled={status !== 'NOT_CONFIRMED'}
      title={popconfirmTitle}
      onConfirm={handleConfirm}
      okText="Yes"
      cancelText="No"
    >
      {status === 'CONFIRMING' ? (
        <Text>{displayLabels.confirming}</Text>
      ) : (
        <a>{displayLabels.trigger}</a>
      )}
    </Popconfirm>
  )
}
