/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type AuditAuditLogType = typeof AuditAuditLogType[keyof typeof AuditAuditLogType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuditAuditLogType = {
  change: 'change',
  ctl: 'ctl',
  gitops_secondary_confirm: 'gitops_secondary_confirm',
  cluster_maintenance: 'cluster_maintenance',
  operation: 'operation',
  jump: 'jump',
  cloud_config_change: 'cloud_config_change',
  cloud_config_default_config_change: 'cloud_config_default_config_change',
  k8s_upgrade_task: 'k8s_upgrade_task',
  tenant: 'tenant',
} as const;
