import { Modal, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { useEffect, useState } from 'react'

import { cloudConfigControllerDiffTemplate } from 'apiClient/services/devops'
import { CloudconfigChangedParameter } from 'apiClient/services/devops/interface'
import { CloudConfigControllerDiffTemplateClusterType } from 'apiClient/services/devops/interface/cloudConfigControllerDiffTemplateClusterType'
import { convertToString } from 'pages/Tenants/ClusterDetail/Configs/Forms/PreviewForm'

function DiffTemplate({
  source,
  current
}: {
  source?: number
  current?: number
}) {
  const [diff, setDiffA] = useState<CloudconfigChangedParameter[]>()
  const [configType, setConfigType] = useState<string>()
  useEffect(() => {
    fetch()
  }, [source, current])
  async function fetch() {
    try {
      const diffResp = (
        await cloudConfigControllerDiffTemplate(String(source), {
          cluster_type:
            CloudConfigControllerDiffTemplateClusterType.DEDICATED_TIDB,
          template_id: current!
        })
      ).data
      setDiffA(diffResp.changed_parameters)
      setConfigType(diffResp.config_type)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }

  const columns: ColumnsType<CloudconfigChangedParameter> = [
    {
      title: 'Name',
      render: (_: any, record) => {
        return record.name
      }
    },
    {
      title: 'Config Type',
      render: (_: any, record) => {
        return configType
      }
    },
    {
      title: 'Source',
      render: (_: any, record) => {
        return `${record.from_source} --> ${record.to_source}`
      }
    },
    {
      title: 'Previous Value',
      render: (_: any, record) => {
        return convertToString(record.from)
      }
    },
    {
      title: 'New Value',
      render: (_: any, record) => {
        return convertToString(record.to)
      }
    }
  ]

  return <Table columns={columns} dataSource={diff}></Table>
}

export default DiffTemplate
