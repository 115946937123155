import {
  ExportOutlined,
  FolderOutlined,
  UserOutlined,
  MailOutlined,
  TeamOutlined
} from '@ant-design/icons'
import { Button, Card, Divider, Layout, Menu, Modal, Space } from 'antd'
import MenuItem from 'antd/lib/menu/MenuItem'
import SubMenu from 'antd/lib/menu/SubMenu'
import Title from 'antd/lib/typography/Title'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  cloudConfigControllerCloseDefaultConfigurationChange,
  cloudConfigControllerGetDefaultConfigurationChange,
  cloudConfigControllerListDefaultConfigurationChangeItems
} from 'apiClient/services/devops'
import {
  CloudconfigDefaultConfigurationChange,
  CloudconfigDefaultConfigurationChangeItemType,
  CloudconfigDefaultConfigurationChangePhase,
  CloudconfigTemplateDefaultCaseBindingChange
} from 'apiClient/services/devops/interface'
import ModalForm from 'components/ModalForm'

import TemplateChangeOverview from './DefaultChangeOverview'
import DefaultImageModify from './DefaultImageModify'
import TemplateChangeModify from './DefaultTemplateModify'
import EnvironmentRelease from './EnvironmentRelease'
import CloseTemplateForm, { CloseTemplateType } from './Form/CloseTemplateForm'
import VersionReleaseInfoShow from './VersionReleaseInfo'

const { Header, Footer, Sider, Content } = Layout

function VersionReleaseChange() {
  const { change_id } = useParams<{ change_id: string }>()
  const [change, setChange] = useState<CloudconfigDefaultConfigurationChange>()
  const [selected, setSelected] = useState('overview')
  const [modalClose, setModalClose] = useState(false)
  const [reload, setReload] = useState(true)
  useEffect(() => {
    fetch()
  }, [reload])
  async function fetch() {
    try {
      const chg = (
        await cloudConfigControllerGetDefaultConfigurationChange(change_id)
      ).data.change
      setChange(chg)
    } catch (error) {
      Modal.error({
        title: error.response.data.errcode,
        content: error.response.data.errmsg
      })
    }
  }
  const dict = {
    overview: <TemplateChangeOverview change={change}></TemplateChangeOverview>,
    modifyTemplate: (
      <TemplateChangeModify
        reload={reload}
        setReload={setReload}
        phase={change?.phase}
      ></TemplateChangeModify>
    ),
    modifyImage: (
      <DefaultImageModify
        reload={reload}
        setReload={setReload}
        phase={change?.phase}
      ></DefaultImageModify>
    ),
    showVersionReleaseInfo: (
      <VersionReleaseInfoShow
        reload={reload}
        setReload={setReload}
        phase={change?.phase}
      ></VersionReleaseInfoShow>
    )
  }

  const renderFormFields = useCallback(
    (form) => {
      return <CloseTemplateForm form={form} />
    },
    [modalClose]
  )

  const handleClose = async (payload: CloseTemplateType) => {
    Modal.info({
      title: 'Info',
      closable: true,
      content: (
        <div>
          <p>
            Are you sure to <b>Close</b> this template change?
            <br></br>
            NOTE: You can't make any changes after it's closed.
          </p>
        </div>
      ),
      okText: 'Confirm',
      async onOk() {
        try {
          await cloudConfigControllerCloseDefaultConfigurationChange(
            change_id,
            payload
          )
          Modal.success({
            title: 'info',
            content: (
              <div>
                <p>Successfully Close</p>
              </div>
            ),
            onOk() {}
          })
          setModalClose(false)
          setReload((pre) => !pre)
        } catch (error) {
          Modal.error({
            title: error.response.data.errcode,
            content: error.response.data.errmsg
          })
          setModalClose(false)
          setReload((pre) => !pre)
        }
      }
    })
  }

  return (
    <div>
      <Card
        title={<Title level={4}>Default Configuration Change</Title>}
        bordered={false}
        extra={
          <Space>
            <Button
              type="primary"
              onClick={() => {
                setModalClose(true)
              }}
              disabled={
                !(
                  change?.phase ===
                  CloudconfigDefaultConfigurationChangePhase.in_progress
                )
              }
            >
              Close
            </Button>
          </Space>
        }
      >
        <Layout>
          <Sider theme="light">
            <Menu
              inlineCollapsed={false}
              defaultOpenKeys={['jobs']}
              mode="inline"
              defaultSelectedKeys={['overview']}
              onSelect={(e) => {
                setSelected(e.key)
                setReload((pre) => !pre)
              }}
            >
              <Menu.Item icon={<ExportOutlined />} key={'overview'}>
                Overview
              </Menu.Item>
              <Divider></Divider>
              <SubMenu key={'jobs'} icon={<FolderOutlined />} title={'Jobs'}>
                {change?.item_types?.includes(
                  CloudconfigDefaultConfigurationChangeItemType.version_release
                ) ? (
                  <Menu.Item key="showVersionReleaseInfo">
                    Version Information
                  </Menu.Item>
                ) : (
                  <></>
                )}
                {change?.item_types?.includes(
                  CloudconfigDefaultConfigurationChangeItemType.template_default_case_binding
                ) ? (
                  <Menu.Item key="modifyTemplate">Modify Templates</Menu.Item>
                ) : (
                  <></>
                )}
                {change?.item_types?.includes(
                  CloudconfigDefaultConfigurationChangeItemType.default_component_config
                ) ? (
                  <Menu.Item key="modifyImage">Modify Images</Menu.Item>
                ) : (
                  <></>
                )}
                {change?.environments?.map((env) => (
                  <Menu.Item key={env + ' release'}>
                    {env + ' Release'}
                  </Menu.Item>
                ))}
              </SubMenu>
            </Menu>
          </Sider>
          <Layout>
            <Content className="app-content">
              {selected.includes('release') ? (
                <div>
                  <EnvironmentRelease
                    environment={selected.split(' ')[0]}
                    phase={change?.phase}
                    change_items={change?.item_types}
                  ></EnvironmentRelease>
                </div>
              ) : (
                dict[selected]
              )}
            </Content>
          </Layout>
        </Layout>
      </Card>

      <ModalForm<CloseTemplateType>
        visible={!!modalClose}
        name="close"
        title={'Close Default Configuration Change'}
        getFields={renderFormFields}
        onSubmit={handleClose}
        onCancel={() => {
          setModalClose(false)
        }}
      />
    </div>
  )
}

export default VersionReleaseChange
