import { Form, Select } from 'antd'

export type DefaultVersionFormType = {
  default_version: string
}

function DefaultVersionForm({ versions }: { versions: string[] }) {
  return (
    <>
      <Form.Item label={'Default Version'} name={'default_version'}>
        <Select
          showSearch
          optionFilterProp="label"
          options={versions.map((v) => {
            return { label: v, value: v }
          })}
        ></Select>
      </Form.Item>
    </>
  )
}

export default DefaultVersionForm
