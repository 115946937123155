/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type EntityRemediationTicketClusterType = typeof EntityRemediationTicketClusterType[keyof typeof EntityRemediationTicketClusterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntityRemediationTicketClusterType = {
  dedicated_multi_tidb: 'dedicated_multi_tidb',
  dedicated_single_tidb: 'dedicated_single_tidb',
  serverless: 'serverless',
} as const;
