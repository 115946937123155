/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Management Portal API
 * This is a management portal server
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  FormsListAccessLogResp,
  GetApiV1AdminAccessLogsParams,
  FormsCreateAPIKeyResp,
  FormsCreateAPIKey,
  FormsGetDashboardStatsResq,
  FormsCentralUserProfile,
  FormsUploadMspLogoResp,
  FormsUploadMspLogo,
  FormsCppoOrgResp,
  FormsCppoOrgReq,
  FormsCreateMspOrgResp,
  FormsMspOrg,
  FormsEmptyResponse,
  FormsPartnerCustomer,
  CreateMspAPIKeyBody,
  GetCustomersByPartnerIdParams,
  FormsListTenantResp,
  ListTenantsParams,
  FormsTenant,
  FormsPatchTenantReq,
  GetApiV1AdminOrgsOrgIdAccessLogsParams,
  FormsResourceResult,
  FormsGetOrgProjectsResp,
  GetOrgProjectsParams,
  FormsListUsersResp,
  GetProjectUsersParams,
  GetOrgUsersParams,
  FormsTenantUser,
  FormsRbacPrivilege,
  FormsListRbacPrivilegeResp,
  FormsGrantRolePrivilege,
  FormsRbacRolePrivilege,
  FormsRbacRole,
  FormsRbacRoleResp,
  FormsListRolesResp,
  FormsRole,
  FormsSystemSettings,
  ListUsersParams
} from './interface'
import { customInstance } from '../../axios';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary Search Management Portal access logs
 */
export const getApiV1AdminAccessLogs = (
    params?: GetApiV1AdminAccessLogsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListAccessLogResp>(
      {url: `/api/v1/admin/access-logs`, method: 'get',
        params
    },
      options);
    }
  

export const getGetApiV1AdminAccessLogsQueryKey = (params?: GetApiV1AdminAccessLogsParams,) => [`/api/v1/admin/access-logs`, ...(params ? [params]: [])] as const;
  

    
export const getGetApiV1AdminAccessLogsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminAccessLogs>>, TError = unknown>(params?: GetApiV1AdminAccessLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminAccessLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminAccessLogs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminAccessLogsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminAccessLogs>>> = () => getApiV1AdminAccessLogs(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiV1AdminAccessLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminAccessLogs>>>
export type GetApiV1AdminAccessLogsQueryError = unknown

/**
 * @summary Search Management Portal access logs
 */
export const useGetApiV1AdminAccessLogs = <TData = Awaited<ReturnType<typeof getApiV1AdminAccessLogs>>, TError = unknown>(
 params?: GetApiV1AdminAccessLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminAccessLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminAccessLogsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Add API Key
 */
export const postApiV1AdminApiKey = (
    formsCreateAPIKey: FormsCreateAPIKey,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreateAPIKeyResp>(
      {url: `/api/v1/admin/api_key`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreateAPIKey
    },
      options);
    }
  


export const getPostApiV1AdminApiKeyMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminApiKey>>, TError,{data: FormsCreateAPIKey}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminApiKey>>, TError,{data: FormsCreateAPIKey}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminApiKey>>, {data: FormsCreateAPIKey}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminApiKey(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminApiKeyMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminApiKey>>>
    export type PostApiV1AdminApiKeyMutationBody = FormsCreateAPIKey
    export type PostApiV1AdminApiKeyMutationError = unknown

    /**
 * @summary Add API Key
 */
export const usePostApiV1AdminApiKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminApiKey>>, TError,{data: FormsCreateAPIKey}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminApiKeyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetDashboardStats
 */
export const getApiV1AdminDashboardStats = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetDashboardStatsResq>(
      {url: `/api/v1/admin/dashboard/stats`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminDashboardStatsQueryKey = () => [`/api/v1/admin/dashboard/stats`] as const;
  

    
export const getGetApiV1AdminDashboardStatsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminDashboardStats>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminDashboardStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminDashboardStats>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminDashboardStatsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminDashboardStats>>> = () => getApiV1AdminDashboardStats(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiV1AdminDashboardStatsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminDashboardStats>>>
export type GetApiV1AdminDashboardStatsQueryError = unknown

/**
 * @summary GetDashboardStats
 */
export const useGetApiV1AdminDashboardStats = <TData = Awaited<ReturnType<typeof getApiV1AdminDashboardStats>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminDashboardStats>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminDashboardStatsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary GetCentralUserProfile
 */
export const getApiV1AdminMe = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCentralUserProfile>(
      {url: `/api/v1/admin/me`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminMeQueryKey = () => [`/api/v1/admin/me`] as const;
  

    
export const getGetApiV1AdminMeQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminMe>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminMe>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminMe>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminMeQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminMe>>> = () => getApiV1AdminMe(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiV1AdminMeQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminMe>>>
export type GetApiV1AdminMeQueryError = unknown

/**
 * @summary GetCentralUserProfile
 */
export const useGetApiV1AdminMe = <TData = Awaited<ReturnType<typeof getApiV1AdminMe>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminMe>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminMeQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary UploadMspLogo
 */
export const postApiV1AdminPartnerMspUploadLogo = (
    formsUploadMspLogo: FormsUploadMspLogo,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsUploadMspLogoResp>(
      {url: `/api/v1/admin/partner/msp/upload-logo`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsUploadMspLogo
    },
      options);
    }
  


export const getPostApiV1AdminPartnerMspUploadLogoMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminPartnerMspUploadLogo>>, TError,{data: FormsUploadMspLogo}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminPartnerMspUploadLogo>>, TError,{data: FormsUploadMspLogo}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminPartnerMspUploadLogo>>, {data: FormsUploadMspLogo}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminPartnerMspUploadLogo(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminPartnerMspUploadLogoMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminPartnerMspUploadLogo>>>
    export type PostApiV1AdminPartnerMspUploadLogoMutationBody = FormsUploadMspLogo
    export type PostApiV1AdminPartnerMspUploadLogoMutationError = unknown

    /**
 * @summary UploadMspLogo
 */
export const usePostApiV1AdminPartnerMspUploadLogo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminPartnerMspUploadLogo>>, TError,{data: FormsUploadMspLogo}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminPartnerMspUploadLogoMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary CreateCppoOrg
 */
export const createCppoOrg = (
    formsCppoOrgReq: FormsCppoOrgReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCppoOrgResp>(
      {url: `/api/v1/admin/partner/cppo/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCppoOrgReq
    },
      options);
    }
  


export const getCreateCppoOrgMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCppoOrg>>, TError,{data: FormsCppoOrgReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createCppoOrg>>, TError,{data: FormsCppoOrgReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCppoOrg>>, {data: FormsCppoOrgReq}> = (props) => {
          const {data} = props ?? {};

          return  createCppoOrg(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateCppoOrgMutationResult = NonNullable<Awaited<ReturnType<typeof createCppoOrg>>>
    export type CreateCppoOrgMutationBody = FormsCppoOrgReq
    export type CreateCppoOrgMutationError = unknown

    /**
 * @summary CreateCppoOrg
 */
export const useCreateCppoOrg = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCppoOrg>>, TError,{data: FormsCppoOrgReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateCppoOrgMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary CreateMspOrg
 */
export const createMspOrg = (
    formsMspOrg: FormsMspOrg,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCreateMspOrgResp>(
      {url: `/api/v1/admin/partner/msp/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsMspOrg
    },
      options);
    }
  


export const getCreateMspOrgMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMspOrg>>, TError,{data: FormsMspOrg}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createMspOrg>>, TError,{data: FormsMspOrg}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMspOrg>>, {data: FormsMspOrg}> = (props) => {
          const {data} = props ?? {};

          return  createMspOrg(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateMspOrgMutationResult = NonNullable<Awaited<ReturnType<typeof createMspOrg>>>
    export type CreateMspOrgMutationBody = FormsMspOrg
    export type CreateMspOrgMutationError = unknown

    /**
 * @summary CreateMspOrg
 */
export const useCreateMspOrg = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMspOrg>>, TError,{data: FormsMspOrg}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateMspOrgMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetMspOrg
 */
export const getMspOrg = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsMspOrg>(
      {url: `/api/v1/admin/partner/msp/get/${orgId}`, method: 'get'
    },
      options);
    }
  

export const getGetMspOrgQueryKey = (orgId: string,) => [`/api/v1/admin/partner/msp/get/${orgId}`] as const;
  

    
export const getGetMspOrgQueryOptions = <TData = Awaited<ReturnType<typeof getMspOrg>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspOrg>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getMspOrg>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMspOrgQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspOrg>>> = () => getMspOrg(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetMspOrgQueryResult = NonNullable<Awaited<ReturnType<typeof getMspOrg>>>
export type GetMspOrgQueryError = unknown

/**
 * @summary GetMspOrg
 */
export const useGetMspOrg = <TData = Awaited<ReturnType<typeof getMspOrg>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspOrg>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMspOrgQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary CreateCustomer
 */
export const createCustomer = (
    formsPartnerCustomer: FormsPartnerCustomer,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/partner/partner-customer/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsPartnerCustomer
    },
      options);
    }
  


export const getCreateCustomerMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCustomer>>, TError,{data: FormsPartnerCustomer}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createCustomer>>, TError,{data: FormsPartnerCustomer}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCustomer>>, {data: FormsPartnerCustomer}> = (props) => {
          const {data} = props ?? {};

          return  createCustomer(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof createCustomer>>>
    export type CreateCustomerMutationBody = FormsPartnerCustomer
    export type CreateCustomerMutationError = unknown

    /**
 * @summary CreateCustomer
 */
export const useCreateCustomer = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCustomer>>, TError,{data: FormsPartnerCustomer}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateCustomerMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary DeleteCustomer
 */
export const deleteCustomer = (
    formsPartnerCustomer: FormsPartnerCustomer,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/partner/partner-customer/delete`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: formsPartnerCustomer
    },
      options);
    }
  


export const getDeleteCustomerMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError,{data: FormsPartnerCustomer}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError,{data: FormsPartnerCustomer}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCustomer>>, {data: FormsPartnerCustomer}> = (props) => {
          const {data} = props ?? {};

          return  deleteCustomer(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteCustomerMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCustomer>>>
    export type DeleteCustomerMutationBody = FormsPartnerCustomer
    export type DeleteCustomerMutationError = unknown

    /**
 * @summary DeleteCustomer
 */
export const useDeleteCustomer = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCustomer>>, TError,{data: FormsPartnerCustomer}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteCustomerMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary UpdateMspOrg
 */
export const updateMspOrg = (
    formsMspOrg: FormsMspOrg,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/partner/msp/update`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsMspOrg
    },
      options);
    }
  


export const getUpdateMspOrgMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMspOrg>>, TError,{data: FormsMspOrg}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateMspOrg>>, TError,{data: FormsMspOrg}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMspOrg>>, {data: FormsMspOrg}> = (props) => {
          const {data} = props ?? {};

          return  updateMspOrg(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type UpdateMspOrgMutationResult = NonNullable<Awaited<ReturnType<typeof updateMspOrg>>>
    export type UpdateMspOrgMutationBody = FormsMspOrg
    export type UpdateMspOrgMutationError = unknown

    /**
 * @summary UpdateMspOrg
 */
export const useUpdateMspOrg = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMspOrg>>, TError,{data: FormsMspOrg}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getUpdateMspOrgMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetMspByOrgId
 */
export const getMspByOrgId = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsMspOrg>(
      {url: `/api/v1/admin/partner/msp/${orgId}`, method: 'get'
    },
      options);
    }
  

export const getGetMspByOrgIdQueryKey = (orgId: string,) => [`/api/v1/admin/partner/msp/${orgId}`] as const;
  

    
export const getGetMspByOrgIdQueryOptions = <TData = Awaited<ReturnType<typeof getMspByOrgId>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspByOrgId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getMspByOrgId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMspByOrgIdQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspByOrgId>>> = () => getMspByOrgId(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetMspByOrgIdQueryResult = NonNullable<Awaited<ReturnType<typeof getMspByOrgId>>>
export type GetMspByOrgIdQueryError = unknown

/**
 * @summary GetMspByOrgId
 */
export const useGetMspByOrgId = <TData = Awaited<ReturnType<typeof getMspByOrgId>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspByOrgId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMspByOrgIdQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary CreateMspAPIKey
 */
export const createMspAPIKey = (
    orgId: string,
    createMspAPIKeyBody: CreateMspAPIKeyBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/partner/msp/${orgId}/create-apikey`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createMspAPIKeyBody
    },
      options);
    }
  


export const getCreateMspAPIKeyMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMspAPIKey>>, TError,{orgId: string;data: CreateMspAPIKeyBody}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createMspAPIKey>>, TError,{orgId: string;data: CreateMspAPIKeyBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMspAPIKey>>, {orgId: string;data: CreateMspAPIKeyBody}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createMspAPIKey(orgId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type CreateMspAPIKeyMutationResult = NonNullable<Awaited<ReturnType<typeof createMspAPIKey>>>
    export type CreateMspAPIKeyMutationBody = CreateMspAPIKeyBody
    export type CreateMspAPIKeyMutationError = unknown

    /**
 * @summary CreateMspAPIKey
 */
export const useCreateMspAPIKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMspAPIKey>>, TError,{orgId: string;data: CreateMspAPIKeyBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getCreateMspAPIKeyMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetCustomerByOrgId
 */
export const getCustomerByOrgId = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsPartnerCustomer>(
      {url: `/api/v1/admin/partner/partner-customer/${orgId}`, method: 'get'
    },
      options);
    }
  

export const getGetCustomerByOrgIdQueryKey = (orgId: string,) => [`/api/v1/admin/partner/partner-customer/${orgId}`] as const;
  

    
export const getGetCustomerByOrgIdQueryOptions = <TData = Awaited<ReturnType<typeof getCustomerByOrgId>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCustomerByOrgId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCustomerByOrgId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomerByOrgIdQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerByOrgId>>> = () => getCustomerByOrgId(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetCustomerByOrgIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomerByOrgId>>>
export type GetCustomerByOrgIdQueryError = unknown

/**
 * @summary GetCustomerByOrgId
 */
export const useGetCustomerByOrgId = <TData = Awaited<ReturnType<typeof getCustomerByOrgId>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCustomerByOrgId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCustomerByOrgIdQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary GetCustomersByPartnerId
 */
export const getCustomersByPartnerId = (
    orgId: string,
    params?: GetCustomersByPartnerIdParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsPartnerCustomer[]>(
      {url: `/api/v1/admin/partner/${orgId}/customers`, method: 'get',
        params
    },
      options);
    }
  

export const getGetCustomersByPartnerIdQueryKey = (orgId: string,
    params?: GetCustomersByPartnerIdParams,) => [`/api/v1/admin/partner/${orgId}/customers`, ...(params ? [params]: [])] as const;
  

    
export const getGetCustomersByPartnerIdQueryOptions = <TData = Awaited<ReturnType<typeof getCustomersByPartnerId>>, TError = unknown>(orgId: string,
    params?: GetCustomersByPartnerIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCustomersByPartnerId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getCustomersByPartnerId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomersByPartnerIdQueryKey(orgId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomersByPartnerId>>> = () => getCustomersByPartnerId(orgId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetCustomersByPartnerIdQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomersByPartnerId>>>
export type GetCustomersByPartnerIdQueryError = unknown

/**
 * @summary GetCustomersByPartnerId
 */
export const useGetCustomersByPartnerId = <TData = Awaited<ReturnType<typeof getCustomersByPartnerId>>, TError = unknown>(
 orgId: string,
    params?: GetCustomersByPartnerIdParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCustomersByPartnerId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCustomersByPartnerIdQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary GetSignupUrl
 */
export const getSignupUrl = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string>(
      {url: `/api/v1/admin/partner/msp/${orgId}/signup-url`, method: 'get'
    },
      options);
    }
  

export const getGetSignupUrlQueryKey = (orgId: string,) => [`/api/v1/admin/partner/msp/${orgId}/signup-url`] as const;
  

    
export const getGetSignupUrlQueryOptions = <TData = Awaited<ReturnType<typeof getSignupUrl>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSignupUrl>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getSignupUrl>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSignupUrlQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getSignupUrl>>> = () => getSignupUrl(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetSignupUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getSignupUrl>>>
export type GetSignupUrlQueryError = unknown

/**
 * @summary GetSignupUrl
 */
export const useGetSignupUrl = <TData = Awaited<ReturnType<typeof getSignupUrl>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSignupUrl>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetSignupUrlQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary List Tenant
 */
export const listTenants = (
    params?: ListTenantsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListTenantResp>(
      {url: `/api/v1/admin/orgs`, method: 'get',
        params
    },
      options);
    }
  

export const getListTenantsQueryKey = (params?: ListTenantsParams,) => [`/api/v1/admin/orgs`, ...(params ? [params]: [])] as const;
  

    
export const getListTenantsQueryOptions = <TData = Awaited<ReturnType<typeof listTenants>>, TError = unknown>(params?: ListTenantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantsQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenants>>> = () => listTenants(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListTenantsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenants>>>
export type ListTenantsQueryError = unknown

/**
 * @summary List Tenant
 */
export const useListTenants = <TData = Awaited<ReturnType<typeof listTenants>>, TError = unknown>(
 params?: ListTenantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenants>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTenantsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Tenant By ID
 */
export const getTenantByID = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenant>(
      {url: `/api/v1/admin/orgs/${orgId}`, method: 'get'
    },
      options);
    }
  

export const getGetTenantByIDQueryKey = (orgId: string,) => [`/api/v1/admin/orgs/${orgId}`] as const;
  

    
export const getGetTenantByIDQueryOptions = <TData = Awaited<ReturnType<typeof getTenantByID>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantByID>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantByID>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantByIDQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantByID>>> = () => getTenantByID(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetTenantByIDQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantByID>>>
export type GetTenantByIDQueryError = unknown

/**
 * @summary Get Tenant By ID
 */
export const useGetTenantByID = <TData = Awaited<ReturnType<typeof getTenantByID>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantByID>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantByIDQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete Tenant By ID
 */
export const deleteApiV1AdminOrgsOrgId = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1AdminOrgsOrgIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgId>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgId>>, TError,{orgId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgId>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  deleteApiV1AdminOrgsOrgId(orgId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1AdminOrgsOrgIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgId>>>
    
    export type DeleteApiV1AdminOrgsOrgIdMutationError = unknown

    /**
 * @summary Delete Tenant By ID
 */
export const useDeleteApiV1AdminOrgsOrgId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgId>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1AdminOrgsOrgIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary PatchTenant
 */
export const patchApiV1AdminOrgsOrgId = (
    orgId: string,
    formsPatchTenantReq: FormsPatchTenantReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: formsPatchTenantReq
    },
      options);
    }
  


export const getPatchApiV1AdminOrgsOrgIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV1AdminOrgsOrgId>>, TError,{orgId: string;data: FormsPatchTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchApiV1AdminOrgsOrgId>>, TError,{orgId: string;data: FormsPatchTenantReq}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchApiV1AdminOrgsOrgId>>, {orgId: string;data: FormsPatchTenantReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  patchApiV1AdminOrgsOrgId(orgId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PatchApiV1AdminOrgsOrgIdMutationResult = NonNullable<Awaited<ReturnType<typeof patchApiV1AdminOrgsOrgId>>>
    export type PatchApiV1AdminOrgsOrgIdMutationBody = FormsPatchTenantReq
    export type PatchApiV1AdminOrgsOrgIdMutationError = unknown

    /**
 * @summary PatchTenant
 */
export const usePatchApiV1AdminOrgsOrgId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchApiV1AdminOrgsOrgId>>, TError,{orgId: string;data: FormsPatchTenantReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPatchApiV1AdminOrgsOrgIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Serach Tenant access logs
 */
export const getApiV1AdminOrgsOrgIdAccessLogs = (
    orgId: string,
    params?: GetApiV1AdminOrgsOrgIdAccessLogsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListAccessLogResp>(
      {url: `/api/v1/admin/orgs/${orgId}/access-logs`, method: 'get',
        params
    },
      options);
    }
  

export const getGetApiV1AdminOrgsOrgIdAccessLogsQueryKey = (orgId: string,
    params?: GetApiV1AdminOrgsOrgIdAccessLogsParams,) => [`/api/v1/admin/orgs/${orgId}/access-logs`, ...(params ? [params]: [])] as const;
  

    
export const getGetApiV1AdminOrgsOrgIdAccessLogsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError = unknown>(orgId: string,
    params?: GetApiV1AdminOrgsOrgIdAccessLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminOrgsOrgIdAccessLogsQueryKey(orgId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>> = () => getApiV1AdminOrgsOrgIdAccessLogs(orgId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetApiV1AdminOrgsOrgIdAccessLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>>
export type GetApiV1AdminOrgsOrgIdAccessLogsQueryError = unknown

/**
 * @summary Serach Tenant access logs
 */
export const useGetApiV1AdminOrgsOrgIdAccessLogs = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError = unknown>(
 orgId: string,
    params?: GetApiV1AdminOrgsOrgIdAccessLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdAccessLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminOrgsOrgIdAccessLogsQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary GetTenantResource
 */
export const getTenantResource = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsResourceResult>(
      {url: `/api/v1/admin/orgs/${orgId}/data-resource`, method: 'get'
    },
      options);
    }
  

export const getGetTenantResourceQueryKey = (orgId: string,) => [`/api/v1/admin/orgs/${orgId}/data-resource`] as const;
  

    
export const getGetTenantResourceQueryOptions = <TData = Awaited<ReturnType<typeof getTenantResource>>, TError = unknown>(orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantResource>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getTenantResource>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantResourceQueryKey(orgId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantResource>>> = () => getTenantResource(orgId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetTenantResourceQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantResource>>>
export type GetTenantResourceQueryError = unknown

/**
 * @summary GetTenantResource
 */
export const useGetTenantResource = <TData = Awaited<ReturnType<typeof getTenantResource>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantResource>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTenantResourceQueryOptions(orgId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary AddImpersonation
 */
export const postApiV1AdminOrgsOrgIdImpersonation = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/impersonation`, method: 'post'
    },
      options);
    }
  


export const getPostApiV1AdminOrgsOrgIdImpersonationMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdImpersonation>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdImpersonation>>, TError,{orgId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdImpersonation>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  postApiV1AdminOrgsOrgIdImpersonation(orgId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminOrgsOrgIdImpersonationMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdImpersonation>>>
    
    export type PostApiV1AdminOrgsOrgIdImpersonationMutationError = unknown

    /**
 * @summary AddImpersonation
 */
export const usePostApiV1AdminOrgsOrgIdImpersonation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdImpersonation>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminOrgsOrgIdImpersonationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Impersonation
 */
export const deleteApiV1AdminOrgsOrgIdImpersonation = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/impersonation`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1AdminOrgsOrgIdImpersonationMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdImpersonation>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdImpersonation>>, TError,{orgId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdImpersonation>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  deleteApiV1AdminOrgsOrgIdImpersonation(orgId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1AdminOrgsOrgIdImpersonationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdImpersonation>>>
    
    export type DeleteApiV1AdminOrgsOrgIdImpersonationMutationError = unknown

    /**
 * @summary Delete Impersonation
 */
export const useDeleteApiV1AdminOrgsOrgIdImpersonation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdImpersonation>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1AdminOrgsOrgIdImpersonationMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetOrgProjects
 */
export const getOrgProjects = (
    orgId: string,
    params?: GetOrgProjectsParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsGetOrgProjectsResp>(
      {url: `/api/v1/admin/orgs/${orgId}/projects`, method: 'get',
        params
    },
      options);
    }
  

export const getGetOrgProjectsQueryKey = (orgId: string,
    params?: GetOrgProjectsParams,) => [`/api/v1/admin/orgs/${orgId}/projects`, ...(params ? [params]: [])] as const;
  

    
export const getGetOrgProjectsQueryOptions = <TData = Awaited<ReturnType<typeof getOrgProjects>>, TError = unknown>(orgId: string,
    params?: GetOrgProjectsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrgProjects>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getOrgProjects>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrgProjectsQueryKey(orgId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrgProjects>>> = () => getOrgProjects(orgId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetOrgProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getOrgProjects>>>
export type GetOrgProjectsQueryError = unknown

/**
 * @summary GetOrgProjects
 */
export const useGetOrgProjects = <TData = Awaited<ReturnType<typeof getOrgProjects>>, TError = unknown>(
 orgId: string,
    params?: GetOrgProjectsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrgProjects>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrgProjectsQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary GetProjectResource
 */
export const getProjectResource = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsResourceResult>(
      {url: `/api/v1/admin/orgs/${orgId}/projects/${projectId}/data-resource`, method: 'get'
    },
      options);
    }
  

export const getGetProjectResourceQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/admin/orgs/${orgId}/projects/${projectId}/data-resource`] as const;
  

    
export const getGetProjectResourceQueryOptions = <TData = Awaited<ReturnType<typeof getProjectResource>>, TError = unknown>(orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectResource>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getProjectResource>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectResourceQueryKey(orgId,projectId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectResource>>> = () => getProjectResource(orgId,projectId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}}

export type GetProjectResourceQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectResource>>>
export type GetProjectResourceQueryError = unknown

/**
 * @summary GetProjectResource
 */
export const useGetProjectResource = <TData = Awaited<ReturnType<typeof getProjectResource>>, TError = unknown>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectResource>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectResourceQueryOptions(orgId,projectId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Delete Tenant Project
 */
export const deleteApiV1AdminOrgsOrgIdProjectsProjectId = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/projects/${projectId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1AdminOrgsOrgIdProjectsProjectIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>, TError,{orgId: string;projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>, TError,{orgId: string;projectId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>, {orgId: string;projectId: string}> = (props) => {
          const {orgId,projectId} = props ?? {};

          return  deleteApiV1AdminOrgsOrgIdProjectsProjectId(orgId,projectId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1AdminOrgsOrgIdProjectsProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>>
    
    export type DeleteApiV1AdminOrgsOrgIdProjectsProjectIdMutationError = unknown

    /**
 * @summary Delete Tenant Project
 */
export const useDeleteApiV1AdminOrgsOrgIdProjectsProjectId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdProjectsProjectId>>, TError,{orgId: string;projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1AdminOrgsOrgIdProjectsProjectIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Sync Salesforce Leads
 */
export const postApiV1AdminOrgsOrgIdSalesforce = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/salesforce`, method: 'post'
    },
      options);
    }
  


export const getPostApiV1AdminOrgsOrgIdSalesforceMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>, TError,{orgId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>, {orgId: string}> = (props) => {
          const {orgId} = props ?? {};

          return  postApiV1AdminOrgsOrgIdSalesforce(orgId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminOrgsOrgIdSalesforceMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>>
    
    export type PostApiV1AdminOrgsOrgIdSalesforceMutationError = unknown

    /**
 * @summary Sync Salesforce Leads
 */
export const usePostApiV1AdminOrgsOrgIdSalesforce = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminOrgsOrgIdSalesforce>>, TError,{orgId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminOrgsOrgIdSalesforceMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetProjectUsers
 */
export const getProjectUsers = (
    orgId: string,
    projectId: string,
    params?: GetProjectUsersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListUsersResp>(
      {url: `/api/v1/admin/orgs/${orgId}/projects/${projectId}/users`, method: 'get',
        params
    },
      options);
    }
  

export const getGetProjectUsersQueryKey = (orgId: string,
    projectId: string,
    params?: GetProjectUsersParams,) => [`/api/v1/admin/orgs/${orgId}/projects/${projectId}/users`, ...(params ? [params]: [])] as const;
  

    
export const getGetProjectUsersQueryOptions = <TData = Awaited<ReturnType<typeof getProjectUsers>>, TError = unknown>(orgId: string,
    projectId: string,
    params?: GetProjectUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getProjectUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectUsersQueryKey(orgId,projectId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectUsers>>> = () => getProjectUsers(orgId,projectId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}}

export type GetProjectUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectUsers>>>
export type GetProjectUsersQueryError = unknown

/**
 * @summary GetProjectUsers
 */
export const useGetProjectUsers = <TData = Awaited<ReturnType<typeof getProjectUsers>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params?: GetProjectUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectUsersQueryOptions(orgId,projectId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary GetOrgUsers
 */
export const getOrgUsers = (
    orgId: string,
    params?: GetOrgUsersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListUsersResp>(
      {url: `/api/v1/admin/orgs/${orgId}/users`, method: 'get',
        params
    },
      options);
    }
  

export const getGetOrgUsersQueryKey = (orgId: string,
    params?: GetOrgUsersParams,) => [`/api/v1/admin/orgs/${orgId}/users`, ...(params ? [params]: [])] as const;
  

    
export const getGetOrgUsersQueryOptions = <TData = Awaited<ReturnType<typeof getOrgUsers>>, TError = unknown>(orgId: string,
    params?: GetOrgUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrgUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getOrgUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrgUsersQueryKey(orgId,params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrgUsers>>> = () => getOrgUsers(orgId,params, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId), ...queryOptions}}

export type GetOrgUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getOrgUsers>>>
export type GetOrgUsersQueryError = unknown

/**
 * @summary GetOrgUsers
 */
export const useGetOrgUsers = <TData = Awaited<ReturnType<typeof getOrgUsers>>, TError = unknown>(
 orgId: string,
    params?: GetOrgUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrgUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetOrgUsersQueryOptions(orgId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Get Tenant User
 */
export const getApiV1AdminOrgsOrgIdUsersUserId = (
    orgId: string,
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantUser>(
      {url: `/api/v1/admin/orgs/${orgId}/users/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminOrgsOrgIdUsersUserIdQueryKey = (orgId: string,
    userId: string,) => [`/api/v1/admin/orgs/${orgId}/users/${userId}`] as const;
  

    
export const getGetApiV1AdminOrgsOrgIdUsersUserIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError = unknown>(orgId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminOrgsOrgIdUsersUserIdQueryKey(orgId,userId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>> = () => getApiV1AdminOrgsOrgIdUsersUserId(orgId,userId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(orgId && userId), ...queryOptions}}

export type GetApiV1AdminOrgsOrgIdUsersUserIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>>
export type GetApiV1AdminOrgsOrgIdUsersUserIdQueryError = unknown

/**
 * @summary Get Tenant User
 */
export const useGetApiV1AdminOrgsOrgIdUsersUserId = <TData = Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError = unknown>(
 orgId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminOrgsOrgIdUsersUserId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminOrgsOrgIdUsersUserIdQueryOptions(orgId,userId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary Update Tenant User
 */
export const putApiV1AdminOrgsOrgIdUsersUserId = (
    orgId: string,
    userId: string,
    formsTenantUser: FormsTenantUser,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsTenantUser>(
      {url: `/api/v1/admin/orgs/${orgId}/users/${userId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsTenantUser
    },
      options);
    }
  


export const getPutApiV1AdminOrgsOrgIdUsersUserIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string;data: FormsTenantUser}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string;data: FormsTenantUser}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>, {orgId: string;userId: string;data: FormsTenantUser}> = (props) => {
          const {orgId,userId,data} = props ?? {};

          return  putApiV1AdminOrgsOrgIdUsersUserId(orgId,userId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AdminOrgsOrgIdUsersUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>>
    export type PutApiV1AdminOrgsOrgIdUsersUserIdMutationBody = FormsTenantUser
    export type PutApiV1AdminOrgsOrgIdUsersUserIdMutationError = unknown

    /**
 * @summary Update Tenant User
 */
export const usePutApiV1AdminOrgsOrgIdUsersUserId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string;data: FormsTenantUser}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPutApiV1AdminOrgsOrgIdUsersUserIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary Delete Tenant User
 */
export const deleteApiV1AdminOrgsOrgIdUsersUserId = (
    orgId: string,
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/orgs/${orgId}/users/${userId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1AdminOrgsOrgIdUsersUserIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>, {orgId: string;userId: string}> = (props) => {
          const {orgId,userId} = props ?? {};

          return  deleteApiV1AdminOrgsOrgIdUsersUserId(orgId,userId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1AdminOrgsOrgIdUsersUserIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>>
    
    export type DeleteApiV1AdminOrgsOrgIdUsersUserIdMutationError = unknown

    /**
 * @summary Delete Tenant User
 */
export const useDeleteApiV1AdminOrgsOrgIdUsersUserId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminOrgsOrgIdUsersUserId>>, TError,{orgId: string;userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1AdminOrgsOrgIdUsersUserIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary CreateRbacPrivilege
 */
export const postApiV1AdminRbacPrivilegeCreate = (
    formsRbacPrivilege: FormsRbacPrivilege,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/privilege/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsRbacPrivilege
    },
      options);
    }
  


export const getPostApiV1AdminRbacPrivilegeCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeCreate>>, TError,{data: FormsRbacPrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeCreate>>, TError,{data: FormsRbacPrivilege}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeCreate>>, {data: FormsRbacPrivilege}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminRbacPrivilegeCreate(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacPrivilegeCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeCreate>>>
    export type PostApiV1AdminRbacPrivilegeCreateMutationBody = FormsRbacPrivilege
    export type PostApiV1AdminRbacPrivilegeCreateMutationError = unknown

    /**
 * @summary CreateRbacPrivilege
 */
export const usePostApiV1AdminRbacPrivilegeCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeCreate>>, TError,{data: FormsRbacPrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacPrivilegeCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary DeleteRbacPrivilege
 */
export const postApiV1AdminRbacPrivilegeDeleteId = (
    id: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/privilege/delete/${id}`, method: 'post'
    },
      options);
    }
  


export const getPostApiV1AdminRbacPrivilegeDeleteIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeDeleteId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeDeleteId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeDeleteId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  postApiV1AdminRbacPrivilegeDeleteId(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacPrivilegeDeleteIdMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeDeleteId>>>
    
    export type PostApiV1AdminRbacPrivilegeDeleteIdMutationError = unknown

    /**
 * @summary DeleteRbacPrivilege
 */
export const usePostApiV1AdminRbacPrivilegeDeleteId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeDeleteId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacPrivilegeDeleteIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary ListRbacPrivileges
 */
export const getApiV1AdminRbacPrivilegeList = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListRbacPrivilegeResp>(
      {url: `/api/v1/admin/rbac/privilege/list`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminRbacPrivilegeListQueryKey = () => [`/api/v1/admin/rbac/privilege/list`] as const;
  

    
export const getGetApiV1AdminRbacPrivilegeListQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminRbacPrivilegeList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacPrivilegeList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacPrivilegeList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminRbacPrivilegeListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminRbacPrivilegeList>>> = () => getApiV1AdminRbacPrivilegeList(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiV1AdminRbacPrivilegeListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminRbacPrivilegeList>>>
export type GetApiV1AdminRbacPrivilegeListQueryError = unknown

/**
 * @summary ListRbacPrivileges
 */
export const useGetApiV1AdminRbacPrivilegeList = <TData = Awaited<ReturnType<typeof getApiV1AdminRbacPrivilegeList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacPrivilegeList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminRbacPrivilegeListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary UpdateRbacPrivilege
 */
export const postApiV1AdminRbacPrivilegeUpdate = (
    formsRbacPrivilege: FormsRbacPrivilege,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/privilege/update`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsRbacPrivilege
    },
      options);
    }
  


export const getPostApiV1AdminRbacPrivilegeUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeUpdate>>, TError,{data: FormsRbacPrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeUpdate>>, TError,{data: FormsRbacPrivilege}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeUpdate>>, {data: FormsRbacPrivilege}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminRbacPrivilegeUpdate(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacPrivilegeUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeUpdate>>>
    export type PostApiV1AdminRbacPrivilegeUpdateMutationBody = FormsRbacPrivilege
    export type PostApiV1AdminRbacPrivilegeUpdateMutationError = unknown

    /**
 * @summary UpdateRbacPrivilege
 */
export const usePostApiV1AdminRbacPrivilegeUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacPrivilegeUpdate>>, TError,{data: FormsRbacPrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacPrivilegeUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary AddRbacRolePrivilege
 */
export const postApiV1AdminRbacRolePrivilegeAdd = (
    formsGrantRolePrivilege: FormsGrantRolePrivilege,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/role-privilege/add`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsGrantRolePrivilege
    },
      options);
    }
  


export const getPostApiV1AdminRbacRolePrivilegeAddMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeAdd>>, TError,{data: FormsGrantRolePrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeAdd>>, TError,{data: FormsGrantRolePrivilege}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeAdd>>, {data: FormsGrantRolePrivilege}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminRbacRolePrivilegeAdd(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacRolePrivilegeAddMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeAdd>>>
    export type PostApiV1AdminRbacRolePrivilegeAddMutationBody = FormsGrantRolePrivilege
    export type PostApiV1AdminRbacRolePrivilegeAddMutationError = unknown

    /**
 * @summary AddRbacRolePrivilege
 */
export const usePostApiV1AdminRbacRolePrivilegeAdd = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeAdd>>, TError,{data: FormsGrantRolePrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacRolePrivilegeAddMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary CreateRbacRolePrivilege
 */
export const postApiV1AdminRbacRolePrivilegeCreate = (
    formsGrantRolePrivilege: FormsGrantRolePrivilege,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/role-privilege/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsGrantRolePrivilege
    },
      options);
    }
  


export const getPostApiV1AdminRbacRolePrivilegeCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeCreate>>, TError,{data: FormsGrantRolePrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeCreate>>, TError,{data: FormsGrantRolePrivilege}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeCreate>>, {data: FormsGrantRolePrivilege}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminRbacRolePrivilegeCreate(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacRolePrivilegeCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeCreate>>>
    export type PostApiV1AdminRbacRolePrivilegeCreateMutationBody = FormsGrantRolePrivilege
    export type PostApiV1AdminRbacRolePrivilegeCreateMutationError = unknown

    /**
 * @summary CreateRbacRolePrivilege
 */
export const usePostApiV1AdminRbacRolePrivilegeCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeCreate>>, TError,{data: FormsGrantRolePrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacRolePrivilegeCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary DeleteRbacRolePrivilege
 */
export const postApiV1AdminRbacRolePrivilegeDelete = (
    formsRbacRolePrivilege: FormsRbacRolePrivilege,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/role-privilege/delete`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsRbacRolePrivilege
    },
      options);
    }
  


export const getPostApiV1AdminRbacRolePrivilegeDeleteMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeDelete>>, TError,{data: FormsRbacRolePrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeDelete>>, TError,{data: FormsRbacRolePrivilege}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeDelete>>, {data: FormsRbacRolePrivilege}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminRbacRolePrivilegeDelete(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacRolePrivilegeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeDelete>>>
    export type PostApiV1AdminRbacRolePrivilegeDeleteMutationBody = FormsRbacRolePrivilege
    export type PostApiV1AdminRbacRolePrivilegeDeleteMutationError = unknown

    /**
 * @summary DeleteRbacRolePrivilege
 */
export const usePostApiV1AdminRbacRolePrivilegeDelete = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRolePrivilegeDelete>>, TError,{data: FormsRbacRolePrivilege}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacRolePrivilegeDeleteMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary ListRbacRoleRelatePrivileges
 */
export const getApiV1AdminRbacRolePrivilegeListRoleId = (
    roleId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRbacRolePrivilege[]>(
      {url: `/api/v1/admin/rbac/role-privilege/list/${roleId}`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminRbacRolePrivilegeListRoleIdQueryKey = (roleId: number,) => [`/api/v1/admin/rbac/role-privilege/list/${roleId}`] as const;
  

    
export const getGetApiV1AdminRbacRolePrivilegeListRoleIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminRbacRolePrivilegeListRoleId>>, TError = unknown>(roleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRolePrivilegeListRoleId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRolePrivilegeListRoleId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminRbacRolePrivilegeListRoleIdQueryKey(roleId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminRbacRolePrivilegeListRoleId>>> = () => getApiV1AdminRbacRolePrivilegeListRoleId(roleId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(roleId), ...queryOptions}}

export type GetApiV1AdminRbacRolePrivilegeListRoleIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminRbacRolePrivilegeListRoleId>>>
export type GetApiV1AdminRbacRolePrivilegeListRoleIdQueryError = unknown

/**
 * @summary ListRbacRoleRelatePrivileges
 */
export const useGetApiV1AdminRbacRolePrivilegeListRoleId = <TData = Awaited<ReturnType<typeof getApiV1AdminRbacRolePrivilegeListRoleId>>, TError = unknown>(
 roleId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRolePrivilegeListRoleId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminRbacRolePrivilegeListRoleIdQueryOptions(roleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary CreateRbacRole
 */
export const postApiV1AdminRbacRoleCreate = (
    formsRbacRole: FormsRbacRole,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/role/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsRbacRole
    },
      options);
    }
  


export const getPostApiV1AdminRbacRoleCreateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleCreate>>, TError,{data: FormsRbacRole}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleCreate>>, TError,{data: FormsRbacRole}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacRoleCreate>>, {data: FormsRbacRole}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminRbacRoleCreate(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacRoleCreateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacRoleCreate>>>
    export type PostApiV1AdminRbacRoleCreateMutationBody = FormsRbacRole
    export type PostApiV1AdminRbacRoleCreateMutationError = unknown

    /**
 * @summary CreateRbacRole
 */
export const usePostApiV1AdminRbacRoleCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleCreate>>, TError,{data: FormsRbacRole}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacRoleCreateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary DeleteRbacRole
 */
export const postApiV1AdminRbacRoleDeleteId = (
    id: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/role/delete/${id}`, method: 'post'
    },
      options);
    }
  


export const getPostApiV1AdminRbacRoleDeleteIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleDeleteId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleDeleteId>>, TError,{id: number}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacRoleDeleteId>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  postApiV1AdminRbacRoleDeleteId(id,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacRoleDeleteIdMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacRoleDeleteId>>>
    
    export type PostApiV1AdminRbacRoleDeleteIdMutationError = unknown

    /**
 * @summary DeleteRbacRole
 */
export const usePostApiV1AdminRbacRoleDeleteId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleDeleteId>>, TError,{id: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacRoleDeleteIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary ListRbacRoles
 */
export const getApiV1AdminRbacRoleList = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRbacRoleResp[]>(
      {url: `/api/v1/admin/rbac/role/list`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminRbacRoleListQueryKey = () => [`/api/v1/admin/rbac/role/list`] as const;
  

    
export const getGetApiV1AdminRbacRoleListQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminRbacRoleList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRoleList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRoleList>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminRbacRoleListQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminRbacRoleList>>> = () => getApiV1AdminRbacRoleList(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiV1AdminRbacRoleListQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminRbacRoleList>>>
export type GetApiV1AdminRbacRoleListQueryError = unknown

/**
 * @summary ListRbacRoles
 */
export const useGetApiV1AdminRbacRoleList = <TData = Awaited<ReturnType<typeof getApiV1AdminRbacRoleList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRoleList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminRbacRoleListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary UpdateRbacRole
 */
export const postApiV1AdminRbacRoleUpdate = (
    formsRbacRole: FormsRbacRole,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/rbac/role/update`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsRbacRole
    },
      options);
    }
  


export const getPostApiV1AdminRbacRoleUpdateMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleUpdate>>, TError,{data: FormsRbacRole}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleUpdate>>, TError,{data: FormsRbacRole}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRbacRoleUpdate>>, {data: FormsRbacRole}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminRbacRoleUpdate(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRbacRoleUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRbacRoleUpdate>>>
    export type PostApiV1AdminRbacRoleUpdateMutationBody = FormsRbacRole
    export type PostApiV1AdminRbacRoleUpdateMutationError = unknown

    /**
 * @summary UpdateRbacRole
 */
export const usePostApiV1AdminRbacRoleUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRbacRoleUpdate>>, TError,{data: FormsRbacRole}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRbacRoleUpdateMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetRbacRoleById
 */
export const getApiV1AdminRbacRoleId = (
    id: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRbacRole>(
      {url: `/api/v1/admin/rbac/role/${id}`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminRbacRoleIdQueryKey = (id: number,) => [`/api/v1/admin/rbac/role/${id}`] as const;
  

    
export const getGetApiV1AdminRbacRoleIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminRbacRoleId>>, TError = unknown>(id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRoleId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRoleId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminRbacRoleIdQueryKey(id);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminRbacRoleId>>> = () => getApiV1AdminRbacRoleId(id, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions}}

export type GetApiV1AdminRbacRoleIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminRbacRoleId>>>
export type GetApiV1AdminRbacRoleIdQueryError = unknown

/**
 * @summary GetRbacRoleById
 */
export const useGetApiV1AdminRbacRoleId = <TData = Awaited<ReturnType<typeof getApiV1AdminRbacRoleId>>, TError = unknown>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRbacRoleId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminRbacRoleIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary ListCentralRoles
 */
export const getApiV1AdminRoles = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListRolesResp>(
      {url: `/api/v1/admin/roles`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminRolesQueryKey = () => [`/api/v1/admin/roles`] as const;
  

    
export const getGetApiV1AdminRolesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminRoles>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRoles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRoles>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminRolesQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminRoles>>> = () => getApiV1AdminRoles(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiV1AdminRolesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminRoles>>>
export type GetApiV1AdminRolesQueryError = unknown

/**
 * @summary ListCentralRoles
 */
export const useGetApiV1AdminRoles = <TData = Awaited<ReturnType<typeof getApiV1AdminRoles>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRoles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminRolesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary AddCentralRole
 */
export const postApiV1AdminRoles = (
    formsRole: FormsRole,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRole>(
      {url: `/api/v1/admin/roles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsRole
    },
      options);
    }
  


export const getPostApiV1AdminRolesMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRoles>>, TError,{data: FormsRole}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRoles>>, TError,{data: FormsRole}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1AdminRoles>>, {data: FormsRole}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1AdminRoles(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PostApiV1AdminRolesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1AdminRoles>>>
    export type PostApiV1AdminRolesMutationBody = FormsRole
    export type PostApiV1AdminRolesMutationError = unknown

    /**
 * @summary AddCentralRole
 */
export const usePostApiV1AdminRoles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1AdminRoles>>, TError,{data: FormsRole}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPostApiV1AdminRolesMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * GetCentralRole
 * @summary GetCentralRole
 */
export const getApiV1AdminRolesRoleId = (
    roleId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRole>(
      {url: `/api/v1/admin/roles/${roleId}`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminRolesRoleIdQueryKey = (roleId: string,) => [`/api/v1/admin/roles/${roleId}`] as const;
  

    
export const getGetApiV1AdminRolesRoleIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminRolesRoleId>>, TError = unknown>(roleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRolesRoleId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRolesRoleId>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminRolesRoleIdQueryKey(roleId);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminRolesRoleId>>> = () => getApiV1AdminRolesRoleId(roleId, requestOptions);
    
      
      
   return  { queryKey, queryFn, enabled: !!(roleId), ...queryOptions}}

export type GetApiV1AdminRolesRoleIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminRolesRoleId>>>
export type GetApiV1AdminRolesRoleIdQueryError = unknown

/**
 * @summary GetCentralRole
 */
export const useGetApiV1AdminRolesRoleId = <TData = Awaited<ReturnType<typeof getApiV1AdminRolesRoleId>>, TError = unknown>(
 roleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminRolesRoleId>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminRolesRoleIdQueryOptions(roleId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * UpdateCentralRole
 * @summary UpdateCentralRole
 */
export const putApiV1AdminRolesRoleId = (
    roleId: string,
    formsRole: FormsRole,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsRole>(
      {url: `/api/v1/admin/roles/${roleId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsRole
    },
      options);
    }
  


export const getPutApiV1AdminRolesRoleIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminRolesRoleId>>, TError,{roleId: string;data: FormsRole}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminRolesRoleId>>, TError,{roleId: string;data: FormsRole}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AdminRolesRoleId>>, {roleId: string;data: FormsRole}> = (props) => {
          const {roleId,data} = props ?? {};

          return  putApiV1AdminRolesRoleId(roleId,data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AdminRolesRoleIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AdminRolesRoleId>>>
    export type PutApiV1AdminRolesRoleIdMutationBody = FormsRole
    export type PutApiV1AdminRolesRoleIdMutationError = unknown

    /**
 * @summary UpdateCentralRole
 */
export const usePutApiV1AdminRolesRoleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminRolesRoleId>>, TError,{roleId: string;data: FormsRole}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPutApiV1AdminRolesRoleIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * DeleteCentralRole
 * @summary DeleteCentralRole
 */
export const deleteApiV1AdminRolesRoleId = (
    roleId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/roles/${roleId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteApiV1AdminRolesRoleIdMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminRolesRoleId>>, TError,{roleId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminRolesRoleId>>, TError,{roleId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1AdminRolesRoleId>>, {roleId: string}> = (props) => {
          const {roleId} = props ?? {};

          return  deleteApiV1AdminRolesRoleId(roleId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1AdminRolesRoleIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1AdminRolesRoleId>>>
    
    export type DeleteApiV1AdminRolesRoleIdMutationError = unknown

    /**
 * @summary DeleteCentralRole
 */
export const useDeleteApiV1AdminRolesRoleId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1AdminRolesRoleId>>, TError,{roleId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteApiV1AdminRolesRoleIdMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary GetSystemSettings
 */
export const getApiV1AdminSystemSettings = (
    
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSystemSettings>(
      {url: `/api/v1/admin/system-settings`, method: 'get'
    },
      options);
    }
  

export const getGetApiV1AdminSystemSettingsQueryKey = () => [`/api/v1/admin/system-settings`] as const;
  

    
export const getGetApiV1AdminSystemSettingsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1AdminSystemSettings>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminSystemSettings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminSystemSettings>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1AdminSystemSettingsQueryKey();

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1AdminSystemSettings>>> = () => getApiV1AdminSystemSettings(requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type GetApiV1AdminSystemSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1AdminSystemSettings>>>
export type GetApiV1AdminSystemSettingsQueryError = unknown

/**
 * @summary GetSystemSettings
 */
export const useGetApiV1AdminSystemSettings = <TData = Awaited<ReturnType<typeof getApiV1AdminSystemSettings>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV1AdminSystemSettings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetApiV1AdminSystemSettingsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary UpdateSystemSettings
 */
export const putApiV1AdminSystemSettings = (
    formsSystemSettings: FormsSystemSettings,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSystemSettings>(
      {url: `/api/v1/admin/system-settings`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsSystemSettings
    },
      options);
    }
  


export const getPutApiV1AdminSystemSettingsMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminSystemSettings>>, TError,{data: FormsSystemSettings}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminSystemSettings>>, TError,{data: FormsSystemSettings}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1AdminSystemSettings>>, {data: FormsSystemSettings}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1AdminSystemSettings(data,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type PutApiV1AdminSystemSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1AdminSystemSettings>>>
    export type PutApiV1AdminSystemSettingsMutationBody = FormsSystemSettings
    export type PutApiV1AdminSystemSettingsMutationError = unknown

    /**
 * @summary UpdateSystemSettings
 */
export const usePutApiV1AdminSystemSettings = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1AdminSystemSettings>>, TError,{data: FormsSystemSettings}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getPutApiV1AdminSystemSettingsMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
/**
 * @summary ListUsers
 */
export const listUsers = (
    params?: ListUsersParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsListUsersResp>(
      {url: `/api/v1/admin/users`, method: 'get',
        params
    },
      options);
    }
  

export const getListUsersQueryKey = (params?: ListUsersParams,) => [`/api/v1/admin/users`, ...(params ? [params]: [])] as const;
  

    
export const getListUsersQueryOptions = <TData = Awaited<ReturnType<typeof listUsers>>, TError = unknown>(params?: ListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}
): UseQueryOptions<Awaited<ReturnType<typeof listUsers>>, TError, TData> & { queryKey: QueryKey } => {
const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUsersQueryKey(params);

  
  
    const queryFn: QueryFunction<Awaited<ReturnType<typeof listUsers>>> = () => listUsers(params, requestOptions);
    
      
      
   return  { queryKey, queryFn, ...queryOptions}}

export type ListUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listUsers>>>
export type ListUsersQueryError = unknown

/**
 * @summary ListUsers
 */
export const useListUsers = <TData = Awaited<ReturnType<typeof listUsers>>, TError = unknown>(
 params?: ListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListUsersQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}


/**
 * @summary DeleteUserFromAllOrg
 */
export const deleteUserFromAllOrg = (
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsEmptyResponse>(
      {url: `/api/v1/admin/users/${userId}`, method: 'delete'
    },
      options);
    }
  


export const getDeleteUserFromAllOrgMutationOptions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserFromAllOrg>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteUserFromAllOrg>>, TError,{userId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserFromAllOrg>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deleteUserFromAllOrg(userId,requestOptions)
        }

        

 
   return  { mutationFn, ...mutationOptions }}

    export type DeleteUserFromAllOrgMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserFromAllOrg>>>
    
    export type DeleteUserFromAllOrgMutationError = unknown

    /**
 * @summary DeleteUserFromAllOrg
 */
export const useDeleteUserFromAllOrg = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserFromAllOrg>>, TError,{userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
    
      const mutationOptions = getDeleteUserFromAllOrgMutationOptions(options);
     
      return useMutation(mutationOptions);
    }
    
