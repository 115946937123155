import { Card, Descriptions, Divider } from 'antd'
import { useState } from 'react'

import {
  AuditControllerListAuditLogType,
  CloudconfigDefaultConfigurationChange
} from 'apiClient/services/devops/interface'
import AuditTable from 'components/Audit'
import { toLocal } from 'pages/Devops/Maintenance/Detail'

function TemplateChangeOverview({
  change
}: {
  change?: CloudconfigDefaultConfigurationChange
}) {
  const [activeTabKey, setActiveTabKey] = useState<string>('audit')
  const onTabChange = (key: string) => {
    setActiveTabKey(key)
  }

  const tabList = [
    {
      key: 'audit',
      tab: 'Audit Logs'
    }
  ]
  const contentList: Record<string, React.ReactNode> = {
    audit:
      change?.id !== undefined ? (
        <AuditTable
          type={
            AuditControllerListAuditLogType.cloud_config_default_config_change
          }
          id={change.id}
        ></AuditTable>
      ) : (
        <></>
      )
  }
  return (
    <div>
      <Descriptions title="Information">
        <Descriptions.Item label="ID" span={1}>
          {change?.id}
        </Descriptions.Item>
        <Descriptions.Item label="Applicant">
          {change?.applicant}
        </Descriptions.Item>
        <Descriptions.Item label="Reason">{change?.reason}</Descriptions.Item>
        <Descriptions.Item label="Phase" span={1}>
          {change?.phase}
        </Descriptions.Item>
        <Descriptions.Item label="Phase Reason" span={1}>
          {change?.phase_reason}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {toLocal(change?.created_at)}
        </Descriptions.Item>
        <Descriptions.Item label="Closed At">
          {toLocal(change?.closed_at)}
        </Descriptions.Item>
      </Descriptions>
      <Divider></Divider>
      <Card
        tabList={tabList}
        tabProps={{ size: 'middle' }}
        bordered={false}
        activeTabKey={activeTabKey}
        onTabChange={(key) => {
          onTabChange(key)
        }}
      >
        {contentList[activeTabKey]}
      </Card>
    </div>
  )
}
export default TemplateChangeOverview
