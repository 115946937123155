import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { CloudconfigConfigurationChange } from 'apiClient/services/devops/interface'

import {
  FlatFiles,
  columnsModified,
  convertToString
} from '../Forms/PreviewForm'

const ExpandTable = ({
  change
}: {
  change?: CloudconfigConfigurationChange
}) => {
  return (
    <Table
      dataSource={FlatFiles(change?.scope?.files || [])}
      columns={columnsModified}
      pagination={false}
    />
  )
}

export default ExpandTable
