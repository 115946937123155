import { Popconfirm, Space } from 'antd'
import React from 'react'

import { capitalize } from 'utils/string'

interface Action {
  name: string
  label?: string
  message?: string
  handler: () => void
}

const ActionCell = ({ actions }: { actions: Action[] }) => {
  return (
    <Space style={{ minWidth: '6em' }}>
      {actions.map((a) => {
        if (a.name.toLowerCase() === 'delete') {
          return (
            <Popconfirm
              key={a.name}
              title={a.message || 'Are you sure to delete this?'}
              onConfirm={a.handler}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#">{a.label || 'Delete'}</a>
            </Popconfirm>
          )
        }

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a key={a.name} onClick={a.handler}>
            {capitalize(a.name)}
          </a>
        )
      })}
    </Space>
  )
}

// pure component (stateless component)
export default React.memo(ActionCell)
