import { Collapse, Divider } from 'antd'

import { CloudconfigConfiguration } from 'apiClient/services/devops/interface'

const ExportInfo = ({
  configuration
}: {
  configuration?: CloudconfigConfiguration
}) => {
  return (
    <div>
      {Object.keys(configuration?.files!).map((v) => {
        return (
          <div>
            <Collapse>
              <Collapse.Panel header={v} key={v}>
                <pre>{configuration?.files![v].text}</pre>
              </Collapse.Panel>
            </Collapse>
            <Divider></Divider>
          </div>
        )
      })}
    </div>
  )
}

export default ExportInfo
