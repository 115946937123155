/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ReleaseChangeStatus = typeof ReleaseChangeStatus[keyof typeof ReleaseChangeStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReleaseChangeStatus = {
  draft: 'draft',
  created: 'created',
  approving: 'approving',
  wait_for_deploy: 'wait_for_deploy',
  in_progress: 'in_progress',
  observing: 'observing',
  closed: 'closed',
  withdrawn: 'withdrawn',
} as const;
