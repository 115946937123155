import React from 'react'
import { Button } from 'antd'

import auth from 'apiClient/auth'

export default function PermissionErrorContent() {
  const email = localStorage.getItem('sign_in_email')

  const handleSwitch = () => {
    auth.logout()
  }

  return (
    <>
      Forbidden: Your current account {email && <strong>{email}</strong>}{' '}
      doesn't have the permission to access this resource, please contact the
      administrator to resolve this problem, or you can
      <Button type="link" size="small" onClick={handleSwitch}>
        switch
      </Button>
      to another account and try again.
    </>
  )
}
