/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type OperationActionStartPolicy = typeof OperationActionStartPolicy[keyof typeof OperationActionStartPolicy];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OperationActionStartPolicy = {
  manual: 'manual',
  immediate: 'immediate',
  schedule: 'schedule',
} as const;
