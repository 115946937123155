/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Devops API
 * devops server
 * OpenAPI spec version: 1.0
 */

export type ListK8SInformationVersion = typeof ListK8SInformationVersion[keyof typeof ListK8SInformationVersion];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ListK8SInformationVersion = {
  '126': '1.26',
  '127': '1.27',
  '128': '1.28',
  '129': '1.29',
  '130': '1.30',
} as const;
