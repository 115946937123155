import { useMantineTheme } from '@tidbcloud/uikit'
import { getVariant } from 'uikit/theme'
import { DynamicEchTheme } from 'uikit/theme/Chart/DynamicEchTheme'

import { UIKitThemeProvider } from './UIKitThemeProvider'

const ThemeAdjustor: React.FC = ({ children }) => {
  const theme = useMantineTheme()

  // Replace `variant` here
  theme.fn.variant = getVariant(theme)

  return <>{children}</>
}

export const UIWrapper: React.FC = ({ children }) => {
  return (
    <UIKitThemeProvider>
      <ThemeAdjustor>
        <DynamicEchTheme>{children}</DynamicEchTheme>
      </ThemeAdjustor>
    </UIKitThemeProvider>
  )
}
