import { Table, Space, Typography, Row, Spin, Col } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'

import {
  confirmInvoice,
  useListStripeInvoices,
  useListAllInvoices,
  useGetInvoice,
  suspendInvoice
} from 'apiClient/services/billing'
import {
  FormsInvoiceForOpsList,
  FormsStripeInvoice
} from 'apiClient/services/billing/interface'
import CurrencyValue from 'components/currency/Value'
import { DatePicker } from 'components/DatePicker'
import { ListToolBar } from 'components/Table'
import { ConfirmCell } from 'pages/Tenants/TenantDetail/BusinessInfo/Billing/Invoices/ConfirmCell'
import { format } from 'utils/time'

import BillCosts from './BillCosts'
import InvoiceDetailsModalForm from './InvoiceDetailsModalForm'

const { Text } = Typography

const StripeInvoices: React.FC<{ orgId: string; billingConfigId: number }> = ({
  orgId,
  billingConfigId
}) => {
  const { data, isLoading, isRefetching, refetch } = useListStripeInvoices(
    orgId,
    billingConfigId,
    {
      query: {
        refetchInterval: 60 * 1000
      }
    }
  )

  const columns = useMemo<ColumnsType<FormsStripeInvoice>>(() => {
    return [
      {
        title: 'Month',
        dataIndex: 'created',
        render(value, record) {
          return format(
            Number.parseInt(record.created || '0') * 1000,
            undefined,
            'YYYY-MM'
          )
        }
      },
      {
        title: 'Invoice ID',
        dataIndex: 'number'
      },
      {
        title: 'Date Due',
        dataIndex: 'date_due',
        render(value, record) {
          if (record.due_date === '0') {
            return '-'
          }
          return format(
            Number.parseInt(record.due_date || '0') * 1000,
            undefined,
            'YYYY-MM-DD'
          )
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render(value, record) {
          return record.status ? capitalize(record.status) : '-'
        }
      },
      {
        title: 'Charge',
        dataIndex: 'total',
        render(value, record) {
          return <CurrencyValue isCent={false} value={record.total} />
        }
      },
      {
        title: 'Credit Used',
        dataIndex: 'credit_used',
        render(value, record) {
          return <CurrencyValue isCent={false} value={record.credit_used} />
        }
      },
      {
        title: 'Amount Due',
        dataIndex: 'amount_due',
        render(value, record) {
          return <CurrencyValue isCent={false} value={record.amount_due} />
        }
      },
      {
        title: 'Paid',
        dataIndex: 'amount_paid',
        render(value, record) {
          return <CurrencyValue isCent={false} value={record.amount_paid} />
        }
      },
      {
        title: 'Action',
        render(value, record) {
          if (!record.invoice_pdf) {
            return ''
          }
          return (
            <a
              download={`invoice-${format(
                Number.parseInt(record.created || '0') * 1000,
                undefined,
                'YYYY-MM'
              )}.pdf`}
              href={record.invoice_pdf}
            >
              Export
            </a>
          )
        }
      }
    ]
  }, [])

  return (
    <div>
      <ListToolBar
        title={'Stripe Invoices'}
        reload={async () => {
          await refetch()
        }}
      />
      <Table
        columns={columns}
        size="small"
        rowKey="number"
        loading={isLoading || isRefetching}
        dataSource={data?.data || []}
      />
    </div>
  )
}

const InvoiceDetails: React.FC<{
  invoiceId: string
  orgId: string
  billingConfigId: string
}> = ({ invoiceId, orgId, billingConfigId }) => {
  const { data } = useGetInvoice(orgId, billingConfigId as any, invoiceId)
  if (!data) {
    return (
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Spin />
      </Row>
    )
  }
  return (
    <BillCosts
      category_unit_costs={data.data.category_unit_costs}
      tax_rate={data.data.tax_rate}
      withTaxRate
    />
  )
}

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'

export const Invoices: React.FC = () => {
  const [invoice, setInvoice] = useState<FormsInvoiceForOpsList | null>(null)
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [date, setDate] = useState(dayjs())
  const dateRange = useMemo(
    () => ({
      from: date.startOf('month').format(DATE_FORMAT),
      to: date.add(1, 'month').startOf('month').format(DATE_FORMAT)
    }),
    [date]
  )

  const {
    data: invoices,
    isLoading,
    isRefetching,
    refetch
  } = useListAllInvoices(
    { brief: true, from: dateRange.from, to: dateRange.to },
    { query: { refetchOnWindowFocus: false } }
  )

  const columns = useMemo(() => {
    return [
      {
        title: 'Tenant',
        width: 210,
        dataIndex: 'id',
        render: (_: any, record: FormsInvoiceForOpsList) => (
          <Row>
            <Col span={24}>
              {record.tenant_name ? (
                <Typography.Text
                  style={{ width: 210 }}
                  ellipsis={{ tooltip: record.tenant_name }}
                >
                  {record.tenant_name}
                </Typography.Text>
              ) : (
                <span style={{ color: '#aaa' }}>-</span>
              )}
            </Col>
            <Col span={24}>
              <Typography.Text type="secondary">
                {record.tenant_id}
              </Typography.Text>
            </Col>
          </Row>
        )
      },
      {
        title: 'Status',
        dataIndex: 'status'
      },
      {
        title: 'Start Date',
        dataIndex: 'start_date',
        render: (text: any, record: FormsInvoiceForOpsList) => (
          <Text>{format(record.start_date, 'en', 'YYYY-MM')}</Text>
        )
      },
      {
        title: 'End Date',
        dataIndex: 'end_date',
        render: (text: any, record: FormsInvoiceForOpsList) => (
          <Text>{format(record.end_date, 'en', 'YYYY-MM')}</Text>
        )
      },
      {
        title: 'Cost Price',
        dataIndex: 'cost_price',
        render: (text: any, record: FormsInvoiceForOpsList) => (
          <CurrencyValue isCent={false} value={record.cost_price} />
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (_: any, record: FormsInvoiceForOpsList) => {
          const { status } = record

          const handleOpen = () => {
            setInvoice(record)
            setDetailsModalVisible(true)
          }

          return (
            <Space>
              <a onClick={handleOpen}>Details</a>
              {status === 'generated' && (
                <>
                  <ConfirmCell
                    key={record.invoice_id!}
                    popconfirmTitle="Are you sure to confirm this bill?"
                    onConfirm={async () => {
                      await confirmInvoice(
                        record.tenant_id!.toString(),
                        record.binded_billing_config_id as any,
                        record.invoice_id!.toString(),
                        {
                          isShowMessage: true
                        }
                      )
                      refetch()
                    }}
                  />
                  <ConfirmCell
                    key={record.invoice_id!}
                    popconfirmTitle="Are you sure to suspend this bill?"
                    labels={{
                      trigger: 'Suspend',
                      confirmed: 'Suspended',
                      confirming: 'Suspending...'
                    }}
                    onConfirm={async () => {
                      await suspendInvoice(
                        record.tenant_id!.toString(),
                        record.binded_billing_config_id as any,
                        record.invoice_id!.toString(),
                        {
                          isShowMessage: true
                        }
                      )
                      refetch()
                    }}
                  />
                </>
              )}
            </Space>
          )
        }
      }
    ]
  }, [refetch])

  const onDetailsModalClose = useCallback(() => {
    setDetailsModalVisible(false)
  }, [])

  return (
    <>
      <ListToolBar
        title={'All Invoices'}
        reload={async () => {
          await refetch()
        }}
        actions={[
          <DatePicker
            picker="month"
            value={date}
            onChange={(val) => setDate(val!)}
            allowClear={false}
          />
          // <CreateCommitmentInvoice
          //   billingConfig={billingConfig}
          //   orgId={org_id}
          //   onComplete={() => getData()}
          // />
        ]}
      />

      <Table<FormsInvoiceForOpsList>
        columns={columns}
        rowKey="invoice_id"
        dataSource={invoices?.data}
        loading={isLoading || isRefetching}
        size="small"
        expandable={{
          expandedRowRender: (record) => (
            <InvoiceDetails
              orgId={record.tenant_id!.toString()}
              billingConfigId={record.binded_billing_config_id!}
              invoiceId={record.invoice_id!.toString()}
              key={record.invoice_id}
            />
          )
        }}
      />

      {/* <StripeInvoices
        orgId={org_id}
        billingConfigId={billingConfig.billing_config_id}
      /> */}

      {/* Bill Details ModalForm */}
      {invoice && (
        <InvoiceDetailsModalForm
          orgId={invoice.tenant_id!.toString()}
          billingConfigId={invoice.binded_billing_config_id!}
          key={invoice.invoice_id}
          open={detailsModalVisible}
          invoice={invoice}
          onCancel={onDetailsModalClose}
        />
      )}
    </>
  )
}

export default Invoices
