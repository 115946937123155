// https://github.com/camsong/blog/issues/9
export const precision = (num: number, precision = 12) => {
  return +parseFloat(num.toPrecision(precision))
}

export const toUSDiscount = (num: number | string) => {
  let discount = 0
  if (typeof num === 'string') {
    discount = Number.parseFloat(num)
  } else {
    discount = num
  }
  return precision(100 - discount)
}

export const toFixed = (num: number | string, prec = 2, rounded = false) => {
  if (typeof num === 'string') {
    num = parseFloat(num)
  }

  const inter = precision(num)
  return rounded ? inter.toPrecision(prec) : inter.toFixed(prec)
}
