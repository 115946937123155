import { Statistic, StatisticProps } from 'antd'
import React from 'react'

import { precision } from 'utils/number'

interface ValueProps extends StatisticProps {
  isCent?: boolean
  align?: 'left' | 'middle' | 'right'
}

const defaultValueStyle = {
  display: 'flex',
  fontSize: '1em'
}

export const formatCurrencyValue = (
  amount: number | string | undefined,
  isCent = true
) => {
  if (amount === null || isNaN(Number(amount))) return '-'
  return isCent ? precision(Number(amount) / 100) : Number(amount)
}

function CurrencyValue({
  isCent = true,
  value,
  valueStyle = defaultValueStyle,
  align = 'left',
  ...rest
}: ValueProps) {
  const format = (amount: number | string | undefined) => {
    return formatCurrencyValue(amount, isCent)
  }

  return (
    <Statistic
      value={format(value)}
      precision={2}
      prefix="$"
      valueStyle={{
        ...valueStyle,
        justifyContent:
          align === 'middle'
            ? 'center'
            : align === 'right'
            ? 'flex-end'
            : 'flex-start'
      }}
      {...rest}
    />
  )
}

export default CurrencyValue
